import React, { useMemo } from 'react'

import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import useRegraphLoaders from 'Features/Graph/useRegraphLoaders'
import { IGraphOrganizationNode } from 'Features/GraphNodes/NodeTypes'
import SectionTitle from 'Features/ProfilePanel/Blocks/Overview/SectionTitle/SectionTitle'

import CreateWorkHistoryModal from 'Components/Blocks/Modals/CreateWorkHistoryModal/CreateWorkHistoryModal'
import DeleteWorkHistoryModal from 'Components/Blocks/Modals/DeleteWorkHistoryModal/DeleteWorkHistoryModal'
import UpdateWorkHistoryModal from 'Components/Blocks/Modals/UpdateWorkHistoryModal/UpdateWorkHistoryModal'
import { Column, Row } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import { useCommunity, useEntityModal } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import WorkHistory from './WorkHistoryItem/WorkHistory'

import ShowMore from '../Buttons/ShowMore'
import Card from '../Card'
import { IconWorkHistories } from '../icons.styles'

export interface WorkHistoryBoxProps {
  communityUser: MainSchema.CommunityUser
}

export default function WorkHistories({ communityUser }: WorkHistoryBoxProps) {
  const { community } = useCommunity()
  const { isFeatureEnabled } = useFeatureFlag(['regraph'])
  const { handleSpawnOrganization } = useRegraphLoaders()
  const { focusNodes } = useRegraphHandlers()

  // Use the single/currently active community to load the propert communityUserId
  // IMPORTANT: When UI allows selecting more than one community, things will behave badly in the current UI implementation
  const activeCommunityUserId = useMemo(
    () =>
      communityUser?.communityUsers?.find(e => e.communityId === community?.id)
        ?.communityUserId || null,
    [communityUser, community],
  )

  const t = useScopedI18n('components.blocks.userProfile.blocks.workHistoryBox')

  const [deleteModal, deleteModalActions] =
    useEntityModal<MainSchema.CommunityUserWorkHistory>()
  const [updateModal, updateModalActions] =
    useEntityModal<MainSchema.CommunityUserWorkHistory>()
  const [createModal, createModalActions] =
    useEntityModal<MainSchema.CommunityUser>()

  const handleOpenDeleteModal = deleteModalActions.openModal
  const handleDeleteModalClose = deleteModalActions.closeModal

  const handleOpenUpdateModal = updateModalActions.openModal
  const handleUpdateModalClose = updateModalActions.closeModal

  const handleOpenCreateModal = createModalActions.openModal
  const handleCreateModalClose = createModalActions.closeModal

  const handleView = (
    communityUserWorkHistory: MainSchema.CommunityUserWorkHistory,
  ) => {
    if (!communityUserWorkHistory.workHistory.organization) {
      return
    }

    if (isFeatureEnabled('regraph')) {
      handleSpawnOrganization([
        communityUserWorkHistory.workHistory
          .organization as IGraphOrganizationNode,
      ])
      focusNodes([communityUserWorkHistory.workHistory.organization.id])
    } else {
      // TODO: refactor events so this is clear about what it's doing
      EventBus.trigger(EventBus.actions.search.community, {
        id: communityUserWorkHistory.workHistory.organization.id,
        type: SEARCH_TYPES.organization,
        label: communityUserWorkHistory.workHistory.organization.name,
        value: communityUserWorkHistory.workHistory.organization,
      })
      EventBus.trigger(
        EventBus.actions.graph.focusNode,
        communityUserWorkHistory.workHistory.organization.id,
      )
    }
  }

  return (
    <>
      <Card>
        <Row>
          <Column>
            <SectionTitle
              icon={<IconWorkHistories color={colors.icon.profile} />}
              showPlusButton
              title={t('title')}
              onPlusClicked={
                activeCommunityUserId
                  ? () => handleOpenCreateModal(communityUser)
                  : undefined
              }
            />
          </Column>
          <Column gap={1} pl={5}>
            <Column gap={2}>
              <ShowMore initialShown={2}>
                {communityUser.communityUserWorkHistory
                  // Temporary filter to scope the education history to the active community
                  ?.filter(
                    communityUserWorkHistory =>
                      communityUserWorkHistory.communityUserId ===
                      activeCommunityUserId,
                  )
                  ?.map(communityUserWorkHistory => (
                    <WorkHistory
                      communityUserWorkHistory={communityUserWorkHistory}
                      key={communityUserWorkHistory.workHistory.id}
                      onDelete={() =>
                        handleOpenDeleteModal(communityUserWorkHistory)
                      }
                      onEdit={() =>
                        handleOpenUpdateModal(communityUserWorkHistory)
                      }
                      onView={handleView}
                    />
                  ))}
              </ShowMore>
            </Column>
          </Column>
        </Row>
      </Card>

      {deleteModal.entity && deleteModal.isOpen && (
        <DeleteWorkHistoryModal
          communityUserWorkHistory={deleteModal.entity}
          onClose={handleDeleteModalClose}
        />
      )}

      {updateModal.entity && updateModal.isOpen && (
        <UpdateWorkHistoryModal
          communityUserWorkHistory={updateModal.entity}
          onClose={handleUpdateModalClose}
        />
      )}

      {createModal.entity && createModal.isOpen && (
        <CreateWorkHistoryModal
          communityUser={createModal.entity}
          onClose={handleCreateModalClose}
        />
      )}
    </>
  )
}
