import React, { useCallback } from 'react'

import { flexRender } from '@tanstack/react-table'

import { Loader, Pagination, Row } from 'Components/UI'

import {
  Sort,
  Table,
  TableData,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from './styles'

interface Props {
  table: any
  handleRowClick: (rowId: number) => void
  handleNameClick: (communityUserId: string) => void
  currentPage: number
  totalPages: number
  isLoading: boolean
  filterText: string
  onPageChange: (page: number) => void
  onLimitChange: (limit: number) => void
  limit: number
  totalCount: number
  searching?: boolean
}

const PeopleTableView: React.FC<Props> = React.memo(
  ({
    table,
    handleRowClick,
    handleNameClick,
    currentPage,
    isLoading,
    searching,
    onPageChange,
    onLimitChange,
    limit,
    totalCount,
  }) => {
    const handleLimitChange = useCallback(
      (newLimit: number) => {
        onLimitChange(newLimit)
      },
      [onLimitChange],
    )

    const handlePageChange = useCallback(
      (page: number) => {
        onPageChange(page + 1)
      },
      [onPageChange],
    )

    if (isLoading) {
      return <Loader />
    }

    return (
      <>
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup: any) => (
              <TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <TableHeader
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {header.column.getCanSort() && <Sort />}
                  </TableHeader>
                ))}
              </TableHeaderRow>
            ))}
          </thead>
          <tbody>
            {searching ? (
              <tr>
                <td
                  colSpan={table.getAllColumns().length}
                  style={{ textAlign: 'center' }}
                >
                  <Loader />
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  onClick={() => handleRowClick(row.index)}
                >
                  {row.getVisibleCells().map((cell: any) => {
                    const cellOnClick =
                      cell.column.id === 'name' && handleNameClick
                        ? (event: React.MouseEvent<HTMLTableCellElement>) => {
                            event.stopPropagation()
                            handleNameClick(row.original.communityUserId)
                          }
                        : undefined

                    return (
                      <TableData key={cell.id} onClick={cellOnClick}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableData>
                    )
                  })}
                </TableRow>
              ))
            )}
          </tbody>
        </Table>
        <Row center fullWidth gap={4}>
          <Pagination
            canChangePageSize
            rowsPerPageText="People per page"
            showTotal
            state={{
              pageIndex: currentPage - 1,
              pageSize: limit,
            }}
            total={totalCount}
            totalText="people"
            onPageIndexChange={handlePageChange}
            onPageSizeChange={handleLimitChange}
          />
        </Row>
      </>
    )
  },
)

export default PeopleTableView
