export enum EditCommunityUserFormField {
  PhotoUrl = 'photoUrl',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  JobTitle = 'jobTitle',
  Organization = 'organization',
  LinkedInUrl = 'linkedInUrl',
  TwitterUrl = 'twitterUrl',
  FacebookUrl = 'facebookUrl',
  InterestsHobbies = 'interestsHobbies',
  About = 'about',
  Skills = 'skills',
  Event = 'event',
  Project = 'project',
  Role = 'role',
  Custom = 'custom',
  Group = 'group',
}
