import { ApolloClient } from '@apollo/client'

import areasOfExperienceQuery from '../Queries/areasOfExperience.graphql'

export const onExpandUserLoadAreasOfExperience = async (
  client: ApolloClient<object>,
  communityId: string,
  communityUserId: string,
): Promise<MainSchema.AreaOfExperience[] | undefined> => {
  const areasOfExperienceResult = await client.query<
    Pick<MainSchema.Query, 'areasOfExperience'>,
    MainSchema.QueryAreasOfExperienceArgs
  >({
    query: areasOfExperienceQuery,
    variables: {
      communityId,
      communityUserId,
      count: 3,
    },
  })

  return areasOfExperienceResult?.data?.areasOfExperience
}
