import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'

interface UsePeopleTableProps {
  communityId?: string | null
  selectedCommunityIds: Set<string>
  filterText: string
  sortBy?: string
  sortDirection?: 'asc' | 'desc'
}

export function usePeopleTable({
  communityId,
  selectedCommunityIds,
  filterText,
  sortBy = 'createdAt',
  sortDirection = 'desc',
}: UsePeopleTableProps) {
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(25)

  const communityIds = useMemo(() => {
    if (selectedCommunityIds.size > 0) {
      return Array.from(selectedCommunityIds)
    }
    return communityId ? [communityId] : []
  }, [communityId, selectedCommunityIds])

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(listCommunityUsersQuery, {
    variables: {
      communityIds,
      search: filterText || undefined,
      limit,
      page: currentPage - 1,
      sortBy: [{ column: sortBy, order: sortDirection }],
    },
    skip: communityIds.length === 0,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    if (filterText !== undefined) {
      setIsSearching(true)
      const timer = setTimeout(() => {
        setIsSearching(false)
      }, 300)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [filterText])

  useEffect(() => {
    setCurrentPage(1)
  }, [filterText, sortBy, sortDirection])

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page)
  }, [])

  const handleLimitChange = useCallback((newLimit: number) => {
    setLimit(newLimit)
    setCurrentPage(1)
  }, [])

  return {
    users: data?.listCommunityUsers?.communityUsers || [],
    loading: queryLoading && !isSearching,
    searching: isSearching,
    currentPage,
    totalPages: Math.ceil((data?.listCommunityUsers?.count || 0) / limit),
    limit,
    totalCount: data?.listCommunityUsers?.count || 0,
    handlePageChange,
    handleLimitChange,
    refetch,
  }
}
