import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { locationGlyph } from 'Assets/Svg'

import { resetButton } from 'Components/Styles'

import colors from 'Theme/_v2/colors'

export const Container = styled.div`
  border-radius: 4px;
  background: ${themeGet('colors.bg.primary')};
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 70px;
  right: ${themeGet('space.3')}px;
  z-index: 999;
  max-width: 350px;
  width: 100%;
`

export const Image = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 100%;
  background-color: ${themeGet('colors.bg.primary')};
  border: 1px solid #dde1e4;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const CloseButton = styled.button`
  ${resetButton}

  color: ${colors.link.secondary.main};
  position: absolute;
  top: 8px;
  left: 8px;

  :enabled {
    cursor: pointer;

    :visited {
      color: ${colors.link.secondary.main};
    }

    :hover {
      color: ${colors.link.secondary.hover};
    }

    :focus,
    :active {
      color: ${colors.link.secondary.focus};
    }
  }
`

export const LocationIcon = styled(locationGlyph)``
