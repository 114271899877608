import React, { MouseEventHandler, PropsWithChildren, useCallback } from 'react'

import Utils from 'Utils'

import { Avatar, Column, Row, Text } from 'Components/UI'

import { DRAG_DROP } from 'Constants/ids'

import ConnectionDropdown from './ConnectionDropdown'
import { StyledButton, Wrapper } from './styles'

export type HandleClick = (
  communityUser: MainSchema.CommunityUser,
  shiftKey?: boolean,
) => void

export interface IConnectionCard extends PropsWithChildren {
  communityUserConnectionId?: string
  communityUser: MainSchema.CommunityUser
  selected?: boolean
  showConnectionActions?: boolean
  onClick?: HandleClick
  onMenuClicked?: (communityUser: MainSchema.CommunityUser) => void
  refetchConnections?: () => void
  onRemove?: (connectionId: string, toCommunityUserId: string) => void
}

function ConnectionCard({
  communityUserConnectionId,
  selected,
  showConnectionActions,
  communityUser,
  onClick,
  onMenuClicked,
  refetchConnections,
  onRemove,
}: IConnectionCard) {
  const jobTitle = React.useMemo(
    () => Utils.User.getCurrentJobTitleLabel(communityUser),
    [communityUser],
  )
  const organization = React.useMemo(
    () => Utils.User.getCurrentOrganizationNameLabel(communityUser),
    [communityUser],
  )
  const name = React.useMemo(
    () => Utils.User.getFullName(communityUser),
    [communityUser],
  )

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      onClick?.(communityUser, event.shiftKey)
    },
    [onClick, communityUser],
  )

  const handleMenuClicked: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      event.stopPropagation()
      onMenuClicked?.(communityUser)
    },
    [onMenuClicked, communityUser],
  )

  return (
    <Wrapper
      data={communityUser.communityUserId}
      id={communityUserConnectionId}
      selected={selected}
      type={DRAG_DROP.USER}
      onClick={handleClick}
    >
      <Row center gap={3}>
        <Avatar src={communityUser.photoUrl} />

        <Column>
          <Text breakWord header header3>
            {name}
          </Text>

          {jobTitle && (
            <Text body bodySmall>
              {jobTitle} at {organization}
            </Text>
          )}
        </Column>
      </Row>

      <Row center>
        {/* TODO: pass one dropdown by prop or quickActions or connectActions */}
        {showConnectionActions && (
          <StyledButton onClick={handleMenuClicked}>
            <ConnectionDropdown
              communityUserConnectionId={communityUserConnectionId!}
              communityUserId={communityUser.communityUserId!}
              refetchConnections={refetchConnections!}
              onRemove={onRemove}
            />
          </StyledButton>
        )}
      </Row>
    </Wrapper>
  )
}

export default ConnectionCard
