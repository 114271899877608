import React, { useEffect, useState } from 'react'

import { IconX } from '@tabler/icons-react'

import { Button, Column, Divider, Loader, Tabs, Text } from 'Components/UI'
import { Link } from 'Components/UI/_v2/'

import {
  useAppContext,
  useCommunityContext,
  useEventBusSubscribe,
  useLocationQueryParams,
} from 'Hooks'

import { useQuery } from 'Services/Apollo'
import EventBus from 'Services/EventBus'

import AskOfferTab from './Blocks/AskOffer/AskOfferTab'
import ConnectionsTab from './Blocks/Connections/Connections'
import NotesTab from './Blocks/Notes'
import OverviewTab from './Blocks/Overview/Overview'
import ProfileCard from './Blocks/ProfileHeader/ProfileHeader'
import ReadOnlyHeader from './Blocks/ReadOnlyHeader/ReadOnlyHeader'
import getCommunityUserQuery from './Queries/getCommunityUser.graphql'
import {
  CloseContainer,
  Content,
  ScrollableContainer,
} from './ProfilePanel.styles'

export const TABS = {
  ASK_OFFER: 'Ask/Offer',
  OVERVIEW: 'Profile',
  CONNECTIONS: 'Connections',
  NOTES: 'Notes',
}
const DEFAULT_TABS = [
  TABS.OVERVIEW,
  TABS.ASK_OFFER,
  TABS.NOTES,
  TABS.CONNECTIONS,
]

export type IUserProfileTabProps = keyof typeof TABS

export interface IUserProfileProps {
  communityUserId: MainSchema.QueryGetCommunityUserArgs['communityUserId']
  tab?: IUserProfileTabProps
  onClose: () => void
}

function PersonProfile({ communityUserId, tab, onClose }: IUserProfileProps) {
  const { community, communityLoading } = useCommunityContext()
  const { me } = useAppContext()
  const queryParams = useLocationQueryParams()
  const [activeTab, setActiveTab] = useState(TABS.OVERVIEW)
  const communityId = community?.id
  const skip = !communityId || !communityUserId

  const communityIds = React.useMemo(
    () => me?.communities?.map(e => e.id) || [],
    [me],
  )

  const {
    data: getCommunityUserData,
    loading: isLoading,
    refetch: refetchCommunityUser,
  } = useQuery<
    Pick<MainSchema.Query, 'getCommunityUser'>,
    MainSchema.QueryGetCommunityUserArgs
  >(getCommunityUserQuery, {
    skip,
    variables: {
      communityUserId,
      communityIds,
    },
    fetchPolicy: 'network-only',
  })

  const communityUser: any = React.useMemo(
    () => getCommunityUserData?.getCommunityUser,
    [getCommunityUserData?.getCommunityUser],
  )

  const handleReload = React.useCallback(async () => {
    if (!communityId || !communityUserId) return
    await refetchCommunityUser({ communityIds, communityUserId })
  }, [communityId, communityIds, refetchCommunityUser, communityUserId])

  useEventBusSubscribe(EventBus.actions.profile.reload, handleReload)

  useEffect(() => {
    if (queryParams?.tab === TABS.NOTES) {
      setActiveTab(TABS.NOTES)
    }
    if (tab) {
      setActiveTab(TABS[tab])
    }
  }, [queryParams, tab])

  if (isLoading || communityLoading) {
    return (
      <Column center fullHeight fullWidth justifyCenter>
        <Loader />
      </Column>
    )
  }

  if (!communityUser) {
    return (
      <>
        <Column center fullHeight fullWidth justifyCenter>
          <Text header2 mb={2}>
            Profile not found
          </Text>
          <Button onClick={onClose}>Close</Button>
        </Column>
      </>
    )
  }

  return (
    <>
      <CloseContainer>
        <Link display="flex" p={3} variant="secondary" onClick={onClose}>
          <IconX size={20} />
        </Link>
      </CloseContainer>
      <ProfileCard communityUser={communityUser} />
      <ReadOnlyHeader communityUser={communityUser} />
      <Content>
        <Tabs
          activeTab={activeTab}
          gap={8}
          tabs={DEFAULT_TABS}
          onChange={setActiveTab}
        />

        <Divider mb={4} />

        {activeTab === TABS.NOTES ? (
          <NotesTab communityUser={communityUser} />
        ) : (
          <ScrollableContainer>
            {activeTab === TABS.OVERVIEW && (
              <OverviewTab communityUser={communityUser} />
            )}

            {activeTab === TABS.CONNECTIONS && (
              <ConnectionsTab communityUser={communityUser} />
            )}
            {activeTab === TABS.ASK_OFFER && (
              <AskOfferTab communityUser={communityUser} />
            )}
          </ScrollableContainer>
        )}
      </Content>
    </>
  )
}

export default PersonProfile
