import React, { useMemo } from 'react'
import { MultiValue } from 'react-select'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import useRegraphLoaders from 'Features/Graph/useRegraphLoaders'
import {
  IGraphCommunityNode,
  IGraphTagNode,
} from 'Features/GraphNodes/NodeTypes'
import SectionTitle from 'Features/ProfilePanel/Blocks/Overview/SectionTitle/SectionTitle'
import connectUsersToTagsMutation from 'Features/ProfilePanel/Mutations/connectUsersToTags.graphql'
import createTagsMutation from 'Features/ProfilePanel/Mutations/createTags.graphql'
import listTagsQuery from 'Features/ProfilePanel/Queries/listTags.graphql'
import { getCommunityUserTagsUpdater } from 'Features/ProfilePanel/Updaters/GetCommunityUserTagsUpdater'
import { setMinSearchLength } from 'Utils/Form'
import { ITagOptionInput, tagsToOptions } from 'Utils/Options'

import capitalize from 'lodash/capitalize'

import { Column, Loader, Row, Tag, Tooltip } from 'Components/UI'
import { ITagMouseEvent } from 'Components/UI/Tag/styles'

import { DEFAULT_MIN_SEARCH_SIZE, DEFAULT_SEARCH_DEBOUNCE } from 'Constants/ids'
import { TAG_BLOCK_KIND, TAG_COLOR_KIND, TagBlockKind } from 'Constants/tags'

import { useAppContext, useCommunity } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import ShowMore from '../Buttons/ShowMore'
import Card from '../Card'
import {
  IconCustomTag,
  IconEventTag,
  IconGroupTag,
  IconProjectTag,
} from '../icons.styles'
import InlineDropdownCreate from '../InlineDropdownCreate/InlineDropdownCreate'

export interface ITagProps {
  tagId?: string
  communityUserId?: string
  tag?: MainSchema.Tag
  community?: MainSchema.Community
}

export interface ITagBlock extends React.PropsWithChildren {
  kind: TagBlockKind
  communityUserTags?: ITagProps[]
  communityUser: MainSchema.CommunityUser
  onRemove?: (tagId: string, communityUserId: string) => void
}

function TagBlock({
  children,
  kind,
  communityUserTags = [],
  communityUser,
  onRemove,
}: ITagBlock) {
  const s = useScopedI18n('features.profilePanel.overview.tags')
  const { community } = useCommunity()
  const { me } = useAppContext()
  const { isFeatureEnabled } = useFeatureFlag(['regraph'])
  const { handleSpawnCommunities, handleSpawnTags } = useRegraphLoaders()
  const { focusNodes } = useRegraphHandlers()
  const client = useApolloClient()

  const [tagsToCreate, setTagsToCreate] = React.useState<
    MainSchema.CreateTagInput[]
  >([])

  const [existingTags, setExistingTags] = React.useState<MainSchema.Tag[]>([])

  const [isAdding, setIsAdding] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)

  // Use the single/currently active community to load the proper communityUserId
  // IMPORTANT: When UI allows selecting more than one community, things will behave badly in the current UI implementation
  const communityUserId = useMemo(
    () =>
      communityUser?.communityUsers?.find(e => e.communityId === community?.id)
        ?.communityUserId || null,
    [communityUser, community],
  )

  const { icon, title } = React.useMemo(() => {
    switch (kind) {
      case TAG_BLOCK_KIND.EVENT:
        return {
          icon: (
            <IconEventTag color={colors.icon.profile} height={12} width={12} />
          ),
          title: 'Events',
          tagKind: TAG_COLOR_KIND.EVENT,
        }
      case TAG_BLOCK_KIND.PROJECT:
        return {
          icon: (
            <IconProjectTag
              color={colors.icon.profile}
              height={12}
              width={12}
            />
          ),
          title: 'Projects',
          tagKind: TAG_COLOR_KIND.PROJECT,
        }
      case TAG_BLOCK_KIND.GROUP:
        return {
          icon: (
            <IconGroupTag color={colors.icon.profile} height={12} width={12} />
          ),
          title: 'Groups',
          tagKind: TAG_COLOR_KIND.GROUP,
        }
      default:
        return {
          icon: (
            <IconCustomTag color={colors.icon.profile} height={12} width={12} />
          ),
          title: 'Custom',
          tagKind: TAG_COLOR_KIND.CUSTOM,
        }
    }
  }, [kind])

  const [connectUsersToTags] = useMutation(connectUsersToTagsMutation)
  const [createTags] = useMutation(createTagsMutation)

  const loadTagsOptions = React.useCallback(
    async (
      inputValue: string,
      callback: (options: ITagOptionInput[]) => void,
    ) => {
      try {
        const result = await client.query({
          query: listTagsQuery,
          variables: {
            communityIds: [community?.id],
            kind,
            search: inputValue,
            limit: 25,
          },
          fetchPolicy: 'network-only',
        })

        const tags = result?.data?.listTags?.rows ?? []
        callback(tagsToOptions(tags))
      } catch (error: any) {
        toast.error({
          title: s('errorTitle'),
          text: error.message,
        })
      }
    },
    [client, community?.id, kind, s],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = React.useCallback(
    setMinSearchLength(
      debounce(loadTagsOptions, DEFAULT_SEARCH_DEBOUNCE),
      DEFAULT_MIN_SEARCH_SIZE,
    ),
    [loadTagsOptions],
  )

  const handleSave = React.useCallback(async () => {
    setIsAdding(false)
    if (!me?.communities?.length) return

    setLoading(true)

    let createdTags: MainSchema.Tag[] = []

    try {
      // If we need to create tags, do so
      if (tagsToCreate.length > 0) {
        const createTagsResponse = await createTags({
          variables: { tags: tagsToCreate },
        })
        createdTags = createTagsResponse?.data?.createTags || []
      }

      // Combine the existing tags with the newly created tags and connect them to the user
      const combinedTags = [...existingTags, ...createdTags]

      // Map to our graphql input type
      const usersToTags = combinedTags.map(tag => ({
        tagId: tag.id!,
        communityUserId,
      })) as MainSchema.ConnectUsersToTagsInputType[]

      // Prepare the community user data for the updater
      const communityUsers = [
        {
          communityUserId: communityUser?.id!,
          communityId: community?.id!,
        },
      ]

      const communityIds = me?.communities?.map(e => e.id) || []

      // Connect the tags to the user, and update the cache
      await connectUsersToTags({
        variables: {
          communityId: community?.id,
          usersToTags,
        },
        update: getCommunityUserTagsUpdater({
          communityIds,
          communityUsers,
          communityUserTags: combinedTags.map(
            tag =>
              ({
                tagId: tag.id,
                tag: {
                  id: tag.id,
                  name: tag.name,
                  kind: tag.kind,
                  __typename: 'Tag' as const,
                } as MainSchema.Tag,
                __typename: 'CommunityUserTag' as const,
              }) as MainSchema.CommunityUserTag,
          ),
        }),
      })

      // Add the tags to the graph and create the edges
      combinedTags.forEach(tag => {
        // Add tag to graph
        EventBus.trigger(EventBus.actions.graph.addSkillTags, {
          id: tag.id,
          name: tag.name,
          kind: tag.kind,
          communityUserId: communityUser?.communityUserId,
        })

        // Connect tag to user on graph
        EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
          fromId: communityUser?.communityUserId,
          toId: tag.id,
          kind: tag.kind,
        })
      })

      toast.success({
        title: s('createTitle', { tagKind: title }),
        text: s('createSuccess', { tagKind: title }),
      })
    } catch (error: any) {
      toast.error({
        title: s('errorTitle'),
        text: error?.message,
      })
    } finally {
      setExistingTags([])
      setTagsToCreate([])
      setLoading(false)
    }
  }, [
    me?.communities,
    tagsToCreate,
    existingTags,
    communityUser?.id,
    communityUser?.communityUserId,
    community?.id,
    connectUsersToTags,
    s,
    title,
    createTags,
    communityUserId,
  ])

  const renderMultiValue = React.useCallback(
    (selectProps: any) => (
      <Tag
        colorKind={kind!}
        removable
        small
        text={selectProps?.children?.props?.text ?? selectProps?.children}
        onRemove={() => selectProps?.removeProps?.onClick()}
      />
    ),
    [kind],
  )

  const handleIsValidNewOption = React.useCallback((inputValue: string) => {
    return inputValue?.length >= 3
  }, [])

  const handleOnChange = React.useCallback(
    (newValue: MultiValue<ITagOptionInput>) => {
      const selectedTags = newValue.map(tag => ({ ...tag }))
      // Filter newly added tags that need to be created
      const tagsToCreate = selectedTags
        .filter(options => !options.id)
        .map(tag => ({
          name: tag?.value?.trim(),
          communityId: community?.id,
          kind,
        })) as MainSchema.CreateTagInput[]
      setTagsToCreate(tagsToCreate)

      // Filter the existing tags in the NOS system that just need to be appended to the user
      const existingTags = selectedTags
        .filter(options => options.id)
        .map(tag => ({
          id: tag?.id,
          name: tag?.name,
          kind,
        })) as MainSchema.Tag[]
      setExistingTags(existingTags)
    },
    [community?.id, kind],
  )

  const handleItemClick = React.useCallback(
    (e: ITagMouseEvent) => {
      if (isFeatureEnabled('regraph')) {
        if (e.entity.community.id !== community?.id) {
          handleSpawnCommunities([e.entity.community as IGraphCommunityNode])
        }
        handleSpawnTags([e.entity as IGraphTagNode])
        focusNodes([e.entity?.id])
      } else {
        EventBus.trigger(EventBus.actions.search.community, {
          id: e.entity?.tagId,
          type: e?.entity?.tag?.kind,
          label: e?.entity?.tag?.name,
          value: e?.entity?.tag?.name,
          communities: [e.entity.community],
        })
        EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
          fromId: communityUser.communityUserId,
          toId: e.entity?.tagId,
          kind,
        })
        EventBus.trigger(EventBus.actions.graph.focusNode, e.entity?.tagId)
      }
    },
    [
      community,
      communityUser,
      focusNodes,
      handleSpawnCommunities,
      handleSpawnTags,
      isFeatureEnabled,
      kind,
    ],
  )

  const handleAddClick = React.useCallback(async () => {
    setIsAdding(true)
  }, [])

  const handleCancel = React.useCallback(async () => {
    setIsAdding(false)
  }, [])

  return (
    <Card>
      <Row>
        <Column>
          <SectionTitle
            icon={icon}
            showPlusButton={!isAdding}
            title={title}
            onPlusClicked={handleAddClick}
          />
        </Column>
        <Column fullWidth pl={5}>
          {isAdding && (
            <Row fullWidth>
              <InlineDropdownCreate
                debouncedLoadOptions={debouncedLoadOptions}
                isCreatable
                isValidNewOption={handleIsValidNewOption}
                placeholder={s('placeholder', { tagKind: title })}
                renderMultiValue={renderMultiValue}
                onCancel={handleCancel}
                onChange={handleOnChange}
                onSave={handleSave}
              />
            </Row>
          )}
          {isLoading && (
            <Row>
              <Loader />
            </Row>
          )}
          {communityUserTags.length > 0 && (
            <Row wrapped>
              <ShowMore flexDirection={'row'} gap={2} initialShown={3} wrapped>
                {communityUserTags.map(communityUserTag => (
                  <Tooltip
                    content={s('tooltip', {
                      tagKind: capitalize(communityUserTag?.tag?.kind),
                      name: communityUserTag?.tag?.name,
                      communityName: communityUserTag.community?.name,
                    })}
                    key={communityUserTag?.tagId}
                    offset={[0, 16]}
                    placement="left"
                  >
                    <div>
                      <Tag
                        colorKind={TAG_COLOR_KIND.TRANSPARENT}
                        entity={communityUserTag}
                        removable={!!onRemove && !!communityUserId}
                        small
                        text={communityUserTag?.tag?.name || 'N/A'}
                        onClick={handleItemClick}
                        onRemove={() =>
                          communityUserId &&
                          onRemove &&
                          communityUserTag?.tagId &&
                          communityUserTag.communityUserId &&
                          onRemove(
                            communityUserTag.tagId,
                            communityUserTag.communityUserId,
                          )
                        }
                      />
                    </div>
                  </Tooltip>
                ))}
              </ShowMore>
            </Row>
          )}
          {children}
        </Column>
      </Row>
    </Card>
  )
}

export default TagBlock
