const avatars = {
  size: {
    large: 100,
    default: 40,
    small: 32,
    extraSmall: 24,
    profileInput: 50,
  },

  circleBorderRadius: {
    large: 50,
    default: 20,
    small: 16,
    extraSmall: 12,
    profileInput: 25,
  },

  squareBorderRadius: { default: 4 },

  borderWidth: { default: 1 },

  iconSize: { large: 16, default: 16, small: 16, extraSmall: 12 },
}

export default avatars
