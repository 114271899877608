import React, { useCallback, useEffect, useState } from 'react'

import find from 'lodash/find'

import { Row, Select } from 'Components/UI'
import DropdownIndicator from 'Components/UI/Form/Select/Components/DropdownIndicator'

import {
  COMMUNITY_USER_RELATIONSHIP_LABELS,
  COMMUNITY_USER_RELATIONSHIP_STRENGTH,
} from 'Constants/ids'
import { CommunityUserRelationship } from 'Constants/mainGraphQL'

import { Circle } from './styles'

export interface IOption {
  label: React.ReactNode
  value: CommunityUserRelationship
}

const OPTIONS: IOption[] = [
  {
    label: (
      <Row center gap={2}>
        <Circle backgroundColor="#2B2B2B">3</Circle>
        {
          COMMUNITY_USER_RELATIONSHIP_LABELS[
            COMMUNITY_USER_RELATIONSHIP_STRENGTH.STRONG
          ]
        }
      </Row>
    ),
    value: CommunityUserRelationship.Strong,
  },
  {
    label: (
      <Row center gap={2}>
        <Circle backgroundColor="#787878">2</Circle>
        {
          COMMUNITY_USER_RELATIONSHIP_LABELS[
            COMMUNITY_USER_RELATIONSHIP_STRENGTH.MODERATE
          ]
        }
      </Row>
    ),
    value: CommunityUserRelationship.Moderate,
  },
  {
    label: (
      <Row center gap={2}>
        <Circle backgroundColor="#A9A9A9">1</Circle>
        {
          COMMUNITY_USER_RELATIONSHIP_LABELS[
            COMMUNITY_USER_RELATIONSHIP_STRENGTH.WEAK
          ]
        }
      </Row>
    ),
    value: CommunityUserRelationship.Weak,
  },
  {
    label: (
      <Row center gap={2}>
        <Circle backgroundColor="#CECECE">0</Circle>
        {
          COMMUNITY_USER_RELATIONSHIP_LABELS[
            COMMUNITY_USER_RELATIONSHIP_STRENGTH.DONT_KNOW
          ]
        }
      </Row>
    ),
    value: CommunityUserRelationship.DontKnow,
  },
]

export interface IRelationshipStrengthSelectProps {
  showLabel?: boolean
  showTooltip?: boolean
  value?: CommunityUserRelationship
  onSelect: (option: IOption | undefined) => void
}

function RelationshipStrengthSelect({
  showLabel = true,
  value,
  showTooltip = true,
  onSelect,
}: IRelationshipStrengthSelectProps) {
  const [innerValue, setInnerValue] = useState<IOption | undefined>(undefined)

  useEffect(() => {
    setInnerValue(find(OPTIONS, item => item.value === value) ?? undefined)
  }, [value])

  const handleChange = useCallback(
    (selected: unknown) => {
      // TODO: replace casting
      onSelect(selected as IOption | undefined)
      // TODO: replace casting
      setInnerValue(selected as IOption | undefined)
    },
    [onSelect],
  )

  return (
    <Select
      blurInputOnSelect
      components={{
        DropdownIndicator,
      }}
      infoText={
        showTooltip ? (
          <>
            Categorizing your relationship <br /> with this person
          </>
        ) : null
      }
      isSearchable={false}
      label={showLabel ? 'Relationship Strength' : null}
      options={OPTIONS}
      placeholder="No relationships"
      value={innerValue}
      withPortal
      onChange={handleChange}
    />
  )
}
export default RelationshipStrengthSelect
