import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  MoreCount,
  MultiValueCellWrapper,
  Tooltip,
  TooltipContent,
} from './styles'

interface MultiValueCellProps {
  firstValue: string
  additionalValues: string[]
  moreText?: string // e.g., "more", "communities", "emails"
}

const MultiValueCell: React.FC<MultiValueCellProps> = ({
  firstValue,
  additionalValues,
  moreText = 'more',
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const triggerRef = useRef<HTMLDivElement>(null)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const [showBelow, setShowBelow] = useState(false)

  const updateTooltipPosition = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect()
      // Check if element is in the top half of the window
      const isInTopHalf = rect.top < window.innerHeight / 2
      setShowBelow(isInTopHalf)

      setTooltipPosition({
        top: isInTopHalf ? rect.bottom + 10 : rect.top - 10,
        left: rect.left + rect.width / 2, // Center horizontally
      })
    }
  }

  useEffect(() => {
    if (showTooltip) {
      // Initial position
      updateTooltipPosition()

      // Add scroll event listener to update position during scrolling
      window.addEventListener('scroll', updateTooltipPosition, true)

      // Also update on resize to handle window size changes
      window.addEventListener('resize', updateTooltipPosition)

      // Clean up
      return () => {
        window.removeEventListener('scroll', updateTooltipPosition, true)
        window.removeEventListener('resize', updateTooltipPosition)
      }
    }

    // Return an empty function when showTooltip is false
    return () => {}
  }, [showTooltip])

  if (additionalValues.length === 0) {
    return <span>{firstValue}</span>
  }

  return (
    <MultiValueCellWrapper>
      <span>{firstValue}</span>
      <Tooltip
        ref={triggerRef}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <MoreCount className="more-count">
          +{additionalValues.length} {moreText}
        </MoreCount>
        {showTooltip &&
          createPortal(
            <TooltipContent
              $showBelow={showBelow}
              className="tooltip-content"
              style={{
                position: 'fixed',
                top: `${tooltipPosition.top}px`,
                left: `${tooltipPosition.left}px`,
                transform: `translateX(-50%) ${showBelow ? '' : 'translateY(-100%)'}`,
              }}
            >
              {additionalValues.map(value => (
                <div key={value}>{value}</div>
              ))}
            </TooltipContent>,
            document.body,
          )}
      </Tooltip>
    </MultiValueCellWrapper>
  )
}

export default MultiValueCell
