import { ApolloCache, MutationUpdaterFunction } from '@apollo/client'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'

import forEach from 'lodash/forEach'

import EventBus from 'Services/EventBus'

interface ICommunityUser {
  communityUserId: string
  communityId: string
}

type IGetCommunityUserSkillsUpdater = MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'connectUsersToSkills'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
>

export function getCommunityUserSkillsUpdater({
  communityUsers,
  communityIds,
  skills,
}: {
  communityUsers: ICommunityUser[]
  communityIds: string[]
  skills: any[]
}): IGetCommunityUserSkillsUpdater {
  return (cache, { data }) => {
    if (!data?.connectUsersToSkills) return

    forEach(communityUsers, communityUser => {
      const getCommunityUserData = cache.readQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        query: getCommunityUserQuery,
        variables: {
          communityUserId: communityUser.communityUserId,
          communityIds,
        },
      })

      // No cache found
      if (!getCommunityUserData) {
        // Failsafe, call reload if available
        EventBus.trigger(EventBus.actions.profile.reload)
        return
      }

      if (!getCommunityUserData?.getCommunityUser) return

      const combinedSkills: MainSchema.CommunityUserSkill[] = [
        ...(getCommunityUserData.getCommunityUser.communityUserSkills || []),
        ...skills.map(skill => ({
          skillId: skill.id,
          communityUserId: communityUser.communityUserId,
          skill: {
            id: skill.id,
            name: skill.name,
            __typename: 'Skill' as const,
          },
          __typename: 'CommunityUserSkill' as const,
        })),
      ]

      const skillsMap = new Map<string, MainSchema.CommunityUserSkill>()

      for (const skill of combinedSkills) {
        if (skill.skillId && !skillsMap.has(skill.skillId)) {
          skillsMap.set(skill.skillId, skill)
        }
      }

      const mergedSkills: MainSchema.CommunityUserSkill[] = Array.from(
        skillsMap.values(),
      )

      cache.writeQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        query: getCommunityUserQuery,
        variables: {
          communityUserId: communityUser.communityUserId,
          communityIds,
        },
        data: {
          ...getCommunityUserData,
          getCommunityUser: {
            ...getCommunityUserData.getCommunityUser,
            communityUserSkills: mergedSkills,
          },
        },
      })
    })
  }
}

export function getCommunityUserSkillRemoveUpdater({
  communityUsers,
  communityIds,
  skillIds,
}: {
  communityUsers: ICommunityUser[]
  communityIds: string[]
  skillIds: any[]
}): MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'disconnectUsersFromSkills'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
> {
  return (cache, { data }) => {
    if (!data?.disconnectUsersFromSkills) return

    forEach(communityUsers, communityUser => {
      const getCommunityUserData = cache.readQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        query: getCommunityUserQuery,
        variables: {
          communityUserId: communityUser.communityUserId,
          communityIds,
        },
      })

      // No cache found
      if (!getCommunityUserData) {
        // Failsafe, call reload if available
        EventBus.trigger(EventBus.actions.profile.reload)
        return
      }

      const filteredSkills =
        getCommunityUserData?.getCommunityUser?.communityUserSkills?.filter(
          communityUserSkill => !skillIds.includes(communityUserSkill.skillId),
        )

      if (
        getCommunityUserData?.getCommunityUser &&
        communityUser.communityUserId &&
        communityIds?.length
      ) {
        cache.writeQuery<
          Pick<MainSchema.Query, 'getCommunityUser'>,
          MainSchema.QueryGetCommunityUserArgs
        >({
          query: getCommunityUserQuery,
          variables: {
            communityUserId: communityUser.communityUserId,
            communityIds,
          },
          data: {
            ...getCommunityUserData,
            getCommunityUser: {
              ...getCommunityUserData.getCommunityUser,
              communityUserSkills: filteredSkills,
            },
          },
        })
      }
    })
  }
}
