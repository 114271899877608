import React from 'react'

import { useMutation } from '@apollo/client'
import deleteCommunityUserConnections from 'Features/ProfilePanel/Mutations/deleteCommunityUserConnections.graphql'
import { DeleteCommunityUserUpdater } from 'Features/ProfilePanel/Updaters/CommunityUserConnectionsUpdater'

import { Popover, Text } from 'Components/UI'

import { useAppContext, useCommunity, useOnClickOutside } from 'Hooks'

import EventBus from 'Services/EventBus'

import {
  Container,
  DisconnectIcon,
  DotsIcon,
  DropdownContainer,
  Option,
  ViewProfileIcon,
} from './styles'

export interface IConnectDropdownProps {
  communityUserId: string
  communityUserConnectionId: string
  refetchConnections: () => void
  onRemove?: (connectionId: string, toCommunityUserId: string) => void
}

function ConnectionDropdown({
  communityUserId,
  communityUserConnectionId,
  refetchConnections,
  onRemove,
}: IConnectDropdownProps) {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const { community } = useCommunity()
  const { me } = useAppContext()

  const [isOpen, setOpen] = React.useState(false)
  const [removeConnection] = useMutation<
    Pick<MainSchema.Mutation, 'deleteCommunityUserConnections'>,
    MainSchema.MutationDeleteCommunityUserConnectionsArgs
  >(deleteCommunityUserConnections)

  useOnClickOutside(containerRef, () => setOpen(false))

  const handleDisconnect = React.useCallback(async () => {
    if (!community || !me) {
      return
    }

    await removeConnection({
      variables: {
        communityIds: [community.id],
        communityUserConnectionIds: [communityUserConnectionId],
      },
      update: DeleteCommunityUserUpdater({
        communityIds: [community.id],
        fromCommunityUserId: communityUserId,
        refetchConnections,
      }),
    })
    onRemove?.(communityUserConnectionId, communityUserId)
    setOpen(false)
  }, [
    community,
    me,
    removeConnection,
    communityUserConnectionId,
    communityUserId,
    refetchConnections,
    onRemove,
  ])

  const handleViewProfile = React.useCallback(async () => {
    EventBus.trigger(
      EventBus.actions.profile.openRightUserPanelProfile,
      communityUserId,
    )
    setOpen(false)
  }, [communityUserId])

  return (
    <Container>
      <Popover
        appendTo={document.body}
        content={
          <DropdownContainer ref={containerRef}>
            <Option gap={2} onClick={handleViewProfile}>
              <ViewProfileIcon />
              <Text bodyMedium>View Profile</Text>
            </Option>
            <Option gap={2} onClick={handleDisconnect}>
              <DisconnectIcon />
              <Text bodyMedium>Disconnect</Text>
            </Option>
          </DropdownContainer>
        }
        interactive
        offset={[0, 5]}
        placement="bottom-end"
        visible={isOpen}
      >
        <DotsIcon onClick={() => setOpen(true)} />
      </Popover>
    </Container>
  )
}

export default ConnectionDropdown
