import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import deleteCommunityUserWorkHistoryMutation from 'GraphQL/Mutations/CommunityUserWorkHistory/deleteCommunityUserWorkHistory.graphql'
import { formatExperience } from 'Utils/User'

import { Column, Modal } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

export interface DeleteWorkHistoryModalProps {
  communityUserWorkHistory: MainSchema.CommunityUserWorkHistory
  onClose?: (success: boolean) => void
}

function DeleteWorkHistoryModal({
  communityUserWorkHistory,
  onClose,
}: DeleteWorkHistoryModalProps) {
  const t = useScopedI18n('components.blocks.modals.deleteWorkHistory')
  const [isLoading, setIsLoading] = useState(false)
  const [deleteCommunityUserWorkHistory] = useMutation<
    Pick<MainSchema.Mutation, 'deleteCommunityUserWorkHistory'>,
    MainSchema.MutationDeleteCommunityUserWorkHistoryArgs
  >(deleteCommunityUserWorkHistoryMutation)

  const handleClose = () => {
    onClose?.(false)
  }

  const handleConfirm = async () => {
    setIsLoading(true)

    try {
      await deleteCommunityUserWorkHistory({
        variables: {
          input: {
            communityUserId: communityUserWorkHistory.communityUserId,
            id: communityUserWorkHistory.workHistory.id,
          },
        },
      })

      EventBus.trigger(EventBus.actions.profile.reload)

      toast.success({
        title: t('toast.title'),
        text: t('toast.success'),
      })

      onClose?.(true)
    } catch (error) {
      let message = _('error.generic')

      if (error instanceof Error) {
        message = _(`error.${error.message || 'generic'}`)
      }

      toast.error({
        title: t('toast.title'),
        text: message,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      cancelText={t('form.cancel')}
      confirmDisabled={isLoading}
      confirmText={t('form.submit')}
      isOpen
      title={t('title')}
      onClose={handleClose}
      onConfirm={handleConfirm}
    >
      <Column gap={4} width={['100%', '100%', '600px']}>
        <Text
          color={colors.text.primary}
          fontSize={'14px'}
          fontWeight={400}
          lineHeight={'19px'}
        >
          {t('content', {
            workHistory: formatExperience(communityUserWorkHistory),
          })}
        </Text>
      </Column>
    </Modal>
  )
}

export default DeleteWorkHistoryModal
