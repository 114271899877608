import React, { MouseEventHandler, useRef, useState } from 'react'

import { Popover } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useOnClickOutside } from 'Hooks'

import {
  DotsButton,
  DotsIcon,
  DropdownContainer,
  Option,
} from './DotsDropdown.styles'

export interface DropdownOption {
  key: React.Key
  label: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export interface DropdownProps {
  options: DropdownOption[]
}

function Dropdown({ options }: DropdownProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(containerRef, () => setIsOpen(false), buttonRef)

  if (options.length === 0) {
    return null
  }

  return (
    <Popover
      appendTo={document.body}
      content={
        <DropdownContainer ref={containerRef}>
          {options.map(option => (
            <Option
              gap={2}
              key={option.key}
              onClick={event => {
                event.stopPropagation()
                setIsOpen(false)
                option.onClick(event)
              }}
            >
              <Text>{option.label}</Text>
            </Option>
          ))}
        </DropdownContainer>
      }
      delay={0}
      interactive
      offset={[0, 5]}
      placement="bottom-end"
      trigger="click"
      visible={isOpen}
      zIndex={999}
    >
      <DotsButton
        active={isOpen}
        ref={buttonRef}
        onClick={event => {
          event.stopPropagation()
          setIsOpen(!isOpen)
        }}
      >
        <DotsIcon />
      </DotsButton>
    </Popover>
  )
}

export default Dropdown
