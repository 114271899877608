import React from 'react'

import {
  Column,
  Divider,
  ImageInputField,
  InputField,
  Row,
} from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import _ from 'Services/I18n'

import { EditCommunityUserFormField } from '../fields'

function GeneralTab() {
  return (
    <Column>
      <ImageInputField
        name={EditCommunityUserFormField.PhotoUrl}
        uploadType={UPLOAD_TYPES.profilePhoto}
      />

      <Column fullWidth gap={4} mt={4}>
        <Row fullWidth gap={4}>
          <InputField
            label={_('auth.shared.firstName')}
            name={EditCommunityUserFormField.FirstName}
            placeholder="First name"
            required
            width={1}
          />
          <InputField
            label={_('auth.shared.lastName')}
            name={EditCommunityUserFormField.LastName}
            placeholder="Last name"
            required
            width={1}
          />
        </Row>

        <Row gap={4}>
          <InputField
            label={_('auth.shared.email')}
            name={EditCommunityUserFormField.Email}
            placeholder="Email"
            width={1}
          />

          <InputField
            label={_('auth.shared.phone')}
            name={EditCommunityUserFormField.PhoneNumber}
            phoneNumberFormat
            placeholder="Phone number"
            width={1}
          />
        </Row>
      </Column>

      <Divider my={5} />

      <Column fullWidth gap={3}>
        <InputField
          label={_('auth.shared.linkedIn')}
          name={EditCommunityUserFormField.LinkedInUrl}
          placeholder="LinkedIn"
        />

        <InputField
          label={_('auth.shared.twitterUrl')}
          name={EditCommunityUserFormField.TwitterUrl}
          placeholder="Twitter"
        />

        <InputField
          label={_('auth.shared.facebookUrl')}
          name={EditCommunityUserFormField.FacebookUrl}
          placeholder="Facebook"
        />

        <InputField
          label={_('auth.shared.job')}
          name={EditCommunityUserFormField.JobTitle}
          placeholder="Job"
        />

        <InputField
          label={_('auth.shared.organization')}
          name={EditCommunityUserFormField.Organization}
          placeholder="Company"
        />
      </Column>
    </Column>
  )
}

export default GeneralTab
