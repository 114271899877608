import React from 'react'
import { MultiValue } from 'react-select'

import useCommunitySearch, {
  SearchCommunityType,
} from 'Features/CommunitySearch/useCommunitySearch'
import createCommunityUserConnectionMutation from 'Features/ProfilePanel/Mutations/createCommunityUserConnection.graphql'
import communityUserConnectionsQuery from 'Features/ProfilePanel/Queries/communityUserConnections.graphql'
import { setMinSearchLength } from 'Utils/Form'
import {
  connectionsToOptions,
  IConnectionOption,
  IConnectionOptionInput,
} from 'Utils/Options'

import debounce from 'lodash/debounce'
import forEach from 'lodash/forEach'

import { Button, Column, Loader, Row, Tag } from 'Components/UI'

import { CommunityUserConnectionSource } from 'Constants/mainGraphQL'
import { TAG_COLOR_KIND } from 'Constants/tags'

import { useCommunity } from 'Hooks'

import { useMutation, useQuery } from 'Services/Apollo'
import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import ConnectionCard, { HandleClick } from './ConnectionCard'

import InlineDropdownCreate from '../InlineDropdownCreate/InlineDropdownCreate'

const DEFAULT_MIN_SEARCH_SIZE = 3
const DEFAULT_SEARCH_DEBOUNCE = 400
const DEFAULT_LIMIT = 10

export interface IConnectionsTabProps {
  communityUser: MainSchema.CommunityUser
}

function ConnectionsTab({ communityUser }: IConnectionsTabProps) {
  const s = useScopedI18n('features.profilePanel.overview.connections')
  const [initialLoad, setInitialLoad] = React.useState(true)
  const { community } = useCommunity()
  const communitySearch = useCommunitySearch()
  const [availablePages, setAvailablePages] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [isAdding, setIsAdding] = React.useState(false)
  const [connectionsToAdd, setConnectionsToAdd] = React.useState<any>([])
  const [loadedConnections, setLoadedConnections] = React.useState<
    MainSchema.CommunityUserConnection[]
  >([])
  const [totalConnections, setTotalConnections] = React.useState(0)

  const [createConnections] = useMutation(createCommunityUserConnectionMutation)

  const {
    data: communityUserConnectionsData,
    refetch,
    loading,
  } = useQuery<
    Pick<MainSchema.Query, 'communityUserConnections'>,
    MainSchema.QueryCommunityUserConnectionsArgs
  >(communityUserConnectionsQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !communityUser.communityUserId || !community?.id,
    variables: {
      communityIds: [community?.id!],
      fromCommunityUserId: communityUser.communityUserId,
      biDirectional: true,
      limit: DEFAULT_LIMIT,
      page,
    },
  })

  const connections: MainSchema.CommunityUserConnection[] = React.useMemo(
    () => loadedConnections,
    [loadedConnections],
  )

  React.useEffect(() => {
    if (communityUserConnectionsData?.communityUserConnections?.rows) {
      setAvailablePages(
        communityUserConnectionsData.communityUserConnections.pages,
      )

      setTotalConnections(
        communityUserConnectionsData.communityUserConnections.totalCount,
      )

      const existing = new Set(loadedConnections.map((e: { id: any }) => e.id))

      const filtered =
        communityUserConnectionsData.communityUserConnections.rows.filter(
          (e: { id: any }) => !existing.has(e.id),
        )

      setInitialLoad(false)
      setLoadedConnections((prevState: any) => [...prevState, ...filtered])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityUserConnectionsData?.communityUserConnections?.rows])

  const handleSelectCommunityUser = React.useCallback<HandleClick>(
    communityUser => {
      EventBus.trigger(EventBus.actions.graph.addCommunityUserById, {
        communityUserId: communityUser.communityUserId,
        isSelected: true,
      })
    },
    [],
  )

  const loadConnectionOptions = React.useCallback(
    async (
      inputValue: string,
      callback: (options: IConnectionOptionInput[]) => void,
    ) => {
      try {
        const limit = 10
        const results = await communitySearch.searchCommunity({
          communityIds: [community?.id!],
          searchText: inputValue,
          types: [
            SearchCommunityType.SemanticUsers,
            SearchCommunityType.DirectUsers,
          ],
          limit,
        })

        const processed = communitySearch.processUsers(
          results[SearchCommunityType.DirectUsers].data
            .directSearchCommunityUsers.results,
          results[SearchCommunityType.SemanticUsers].data
            .semanticSearchCommunityUsers.results,
          limit,
        )

        const people = processed.combinedUserResults.map(e => e.node)

        const options: IConnectionOptionInput[] = people?.map(
          (communityUser: MainSchema.CommunityUser) =>
            ({
              id: communityUser.id,
              firstName: communityUser.firstName,
              lastName: communityUser.lastName,
              photoUrl: communityUser.photoUrl,
            }) as unknown as IConnectionOptionInput,
        )

        callback(connectionsToOptions(options))
      } catch (error: any) {
        toast.error({
          title: s('errorTitle'),
          text: error.message,
        })
      }
    },
    [community?.id, communitySearch, s],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = React.useCallback(
    setMinSearchLength(
      debounce(loadConnectionOptions, DEFAULT_SEARCH_DEBOUNCE),
      DEFAULT_MIN_SEARCH_SIZE,
    ),
    [loadConnectionOptions],
  )

  const handleSave = React.useCallback(async () => {
    const response = await createConnections({
      variables: {
        communityId: community?.id,
        source: CommunityUserConnectionSource.NetworkOs,
        fromCommunityUserId: communityUser.communityUserId,
        toCommunityUserIds: connectionsToAdd.map(
          (connection: IConnectionOption) => connection.id,
        ),
      },
    })
    const { data, errors } = response

    if (errors) {
      toast.error({
        title: s('errorTitle'),
        text: errors?.[0]?.message || 'An unknown error has occurred',
      })
      return
    }

    // *** WORK AROUND ***
    // *** TODO: Replace with graphql updaters, whenenver I figure out why they aren't working ***

    const newConnections = data?.createCommunityUserConnections?.map(
      (connection: {
        id: string
        toCommunityUser: MainSchema.CommunityUser
        fromCommunityUser: MainSchema.CommunityUser
      }) => ({
        id: connection.id,
        toCommunityUser: connection.toCommunityUser,
        fromCommunityUser: communityUser,
      }),
    )

    forEach(newConnections, (connection: any) => {
      EventBus.trigger(EventBus.actions.graph.connectUser, {
        communityUser: {
          communityUserId: communityUser.communityUserId,
        },
        connectTo: {
          communityUserId: connection.toCommunityUser.communityUserId,
        },
      })
    })

    // Make sure we don't add duplicates
    const existingConnectionIds = new Set(
      loadedConnections.map((e: { id: any }) => e.id),
    )

    const filteredNewConnections = newConnections.filter(
      (e: { id: any }) => !existingConnectionIds.has(e.id),
    )

    // Put them at the top of the list
    setLoadedConnections([...filteredNewConnections, ...loadedConnections])
    setTotalConnections(totalConnections + filteredNewConnections.length)

    // *** END WORK AROUND ***

    toast.success({
      title: s('createTitle'),
      text: s('createSuccess'),
    })

    setIsAdding(false)
  }, [
    community?.id,
    communityUser,
    connectionsToAdd,
    createConnections,
    loadedConnections,
    s,
    totalConnections,
  ])

  const renderMultiValue = React.useCallback(
    (selectProps: any) => (
      <Tag
        colorKind={TAG_COLOR_KIND.TRANSPARENT}
        removable
        small
        text={selectProps?.children?.props?.text ?? selectProps?.children}
        onRemove={() => selectProps?.removeProps?.onClick()}
      />
    ),
    [],
  )

  const handleLoadMore = React.useCallback(() => {
    setPage(page + 1)
    refetch()
  }, [page, refetch])

  const handleOnChange = React.useCallback(
    (newValue: MultiValue<IConnectionOption>) => {
      setConnectionsToAdd(newValue)
    },
    [],
  )

  const handleRemoveConnection = React.useCallback(
    (connectionId: string, toCommunityUserId: string) => {
      setLoadedConnections(
        loadedConnections.filter(
          (connection: { id: string }) => connection.id !== connectionId,
        ),
      )

      EventBus.trigger(EventBus.actions.graph.disconnectUser, {
        fromId: communityUser.communityUserId,
        toId: toCommunityUserId,
      })

      setTotalConnections(totalConnections - 1)

      toast.success({
        title: s('removeSuccessTitle'),
        text: s('removeSuccess'),
      })
    },
    [communityUser.communityUserId, loadedConnections, s, totalConnections],
  )

  const detectConnectionDirection = React.useCallback(
    (
      connection: MainSchema.CommunityUserConnection,
    ): MainSchema.CommunityUser => {
      // This fixes the issue of the connection needing to be bi-directional
      if (
        connection?.fromCommunityUser?.communityUserId ===
        communityUser.communityUserId
      ) {
        return connection.toCommunityUser as MainSchema.CommunityUser
      }
      return connection.fromCommunityUser as MainSchema.CommunityUser
    },
    [communityUser.communityUserId],
  )

  if (initialLoad) {
    return (
      <Column center fullHeight fullWidth justifyCenter>
        <Loader />
      </Column>
    )
  }

  return (
    <>
      <Row center fullWidth gap={3} mb={3} spaceBetween>
        {totalConnections} connections
        <Button
          small
          onClick={() => (isAdding ? handleSave() : setIsAdding(true))}
        >
          {s(isAdding ? 'doneButton' : 'createButton')}
        </Button>
      </Row>
      {isAdding && (
        <Row fullWidth>
          <InlineDropdownCreate
            debouncedLoadOptions={debouncedLoadOptions}
            renderMultiValue={renderMultiValue}
            onChange={handleOnChange}
          />
        </Row>
      )}
      <Row fullWidth gap={2} wrapped>
        {connections.map((connection: MainSchema.CommunityUserConnection) => (
          <ConnectionCard
            communityUser={detectConnectionDirection(connection)}
            communityUserConnectionId={connection.id}
            key={connection.id}
            refetchConnections={refetch}
            showConnectionActions
            onClick={handleSelectCommunityUser}
            onRemove={handleRemoveConnection}
          />
        ))}
      </Row>
      {page < availablePages - 1 && (
        <Column center fullWidth mt={3}>
          <Button small onClick={handleLoadMore}>
            Show More {loading && <Loader />}
          </Button>
        </Column>
      )}
    </>
  )
}

export default ConnectionsTab
