import styled from 'styled-components'

import { DotsButton } from '../../DotsDropdown/DotsDropdown.styles'

export const WorkHistory = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  gap: 8px;
  cursor: pointer;

  :active {
    background-color: rgba(237, 239, 241, 0.63);
    border-radius: 5px;
  }

  :not(:hover) {
    ${DotsButton} {
      visibility: hidden;
    }
  }
`

export const WorkHistoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
