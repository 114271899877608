import { useCallback, useState } from 'react'

import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import addUsersToCommunitiesMutation from 'GraphQL/Mutations/Community/addUsersToCommunities.graphql'
import communityConnectUsersMutation from 'GraphQL/Mutations/Community/communityConnectUsers.graphql'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import { getCommunityUserCommunitiesUpdater } from 'GraphQL/Updaters/GetCommunityUserCommunities'
import { getCommunityUserSkillsUpdater } from 'GraphQL/Updaters/GetCommunityUserSkills'
import { getCommunityUserTagsUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'
import {
  Chart,
  DeepNullable,
  Glyph,
  Index,
  Items,
  Link,
  LinkStyle,
  Node,
} from 'regraph'
import { neighbors } from 'regraph/analysis'

import forEach from 'lodash/forEach'
import keys from 'lodash/keys'

import { DUMMY_LINK, GraphLayout } from 'Components/Blocks/Graph/constants'

import { ItemType, NODE_KIND, NodeKind, SkillTagKind } from 'Constants/graph'
import { CommunityUserConnectionSource } from 'Constants/mainGraphQL'

import { useAppContext, useCommunityContext } from 'Hooks'
import {
  IGraphHandlers,
  IGraphState,
  IShowGraphMenu,
} from 'Hooks/useGraphContext'

import { useMutation } from 'Services/Apollo'
import EventBus from 'Services/EventBus'
import _ from 'Services/I18n'

import { theme } from 'Theme'

import { IToolTip } from './GraphTooltip'
import utils, { IItemData } from './utils'

export interface IOptions {
  communityUserIds: string[]
  skills: string[]
  organizations: string[]
  tags: string[]
  communities: string[]
}

export interface IUseGraphHandlers {
  targetUser?: IGraphPersonNode
  initialLayout: Chart.LayoutOptions
  nodes: Items<IItemData>
  edges: Index<Link<IItemData>>
  graphState: IGraphState
  onSetPaths: React.Dispatch<React.SetStateAction<IGraphPersonNode[][]>>
  onSetGraphState: React.Dispatch<React.SetStateAction<IGraphState>>
  onSetShowGraphMenu: React.Dispatch<
    React.SetStateAction<IShowGraphMenu | null>
  >
  onSetContextMenuIds: React.Dispatch<React.SetStateAction<string[]>>
}

// Regraph Handlers Only
export default function useGraphHandlers({
  targetUser,
  nodes,
  edges,
  initialLayout,
  graphState,
  onSetPaths,
  onSetGraphState,
  onSetShowGraphMenu,
  onSetContextMenuIds,
}: IUseGraphHandlers): IGraphHandlers {
  const { community } = useCommunityContext()
  const { me } = useAppContext()
  const [isDragging, setIsDragging] = useState(false)
  const [dragStartId, setDragStartId] = useState<string | null>(null)

  const [connect] = useMutation<
    Pick<MainSchema.Mutation, 'communityConnectUsers'>,
    MainSchema.MutationCommunityConnectUsersArgs
  >(communityConnectUsersMutation)

  const [connectUsersToSkills] = useMutation<
    Pick<MainSchema.Mutation, 'connectUsersToSkills'>,
    MainSchema.MutationConnectUsersToSkillsArgs
  >(connectUsersToSkillsMutation)

  const [connectUsersToTags] = useMutation<
    Pick<MainSchema.Mutation, 'connectUsersToTags'>,
    MainSchema.MutationConnectUsersToTagsArgs
  >(connectUsersToTagsMutation)

  const [addUsersToCommunities] = useMutation<
    Pick<MainSchema.Mutation, 'addUsersToCommunities'>,
    MainSchema.MutationAddUsersToCommunitiesArgs
  >(addUsersToCommunitiesMutation)

  const handleChange = useCallback<Chart.onChangeHandler>(
    event => {
      if (event.selection) {
        onSetGraphState(prevState => ({
          ...prevState,
          selection: event.selection,
        }))
      }

      if (event.positions) {
        onSetGraphState(prevState => ({
          ...prevState,
          positions: event.positions,
        }))
      }
    },
    [onSetGraphState],
  )

  const handleHover = useCallback<Chart.onHoverHandler>(
    event => {
      const tip: IToolTip = {
        message: null,
        x: event.x,
        y: event.y,
        enabled: false,
      }

      // This will be expanded further, temporary logic for debugging and readability.
      const node = event.id ? graphState.items[event.id] : null

      if (utils.isUserQuickConnectGlyph(node, event.subItem)) {
        tip.message = _('tips.quickConnect')
        tip.enabled = true
      } else if (utils.isAskOfferGlyph(node, event.subItem)) {
        tip.message = _('tips.askOffer')
        tip.enabled = true
      } else if (utils.isCombo(event.id)) {
        // TODO: Expand to get tooltip from cluster id
        tip.message = 'Double Click to Open/Close'
        tip.enabled = true
      }

      EventBus.trigger(EventBus.actions.graph.setTooltip, tip)
    },
    [graphState.items],
  )

  const handleClick = useCallback<Chart.onClickHandler>(
    event => {
      if (utils.isCombo(event.id)) return

      if (event.id) {
        const item = graphState.items?.[event.id]

        EventBus.trigger(EventBus.actions.graph.clickItem, item)
        if (utils.isAskOfferGlyph(item, event.subItem)) {
          EventBus.trigger(
            EventBus.actions.profile.openRightUserPanelProfile,
            item.data?.data?.communityUserId,
            'ASK_OFFER',
          )
        }
      }

      if (!event.id) {
        onSetPaths([])
        onSetShowGraphMenu(null)
        onSetContextMenuIds([])
      }
    },
    [graphState.items, onSetPaths, onSetShowGraphMenu, onSetContextMenuIds],
  )

  const handleDoubleClick = useCallback<Chart.onDoubleClickHandler>(
    event => {
      if (!!event.id && utils.isCombo(event.id)) {
        onSetGraphState(prevState => ({
          ...prevState,
          openCombos: {
            ...prevState.openCombos,
            [event.id!]: !prevState.openCombos?.[event.id!],
          },
        }))
      }

      // Search if skill/tag/etc node
      const innerOptions: IOptions = {
        skills: [],
        organizations: [],
        communityUserIds: [],
        tags: [],
        communities: [],
      }
      const item = event.id ? graphState.items[event.id] : null

      if (item) {
        if (utils.isUserNode(item)) {
          const communityUserNode = utils.getAsUserNode(item)
          const communityUser = communityUserNode.data!.data

          if (!communityUser) return

          EventBus.trigger(EventBus.actions.graph.expandUser, {
            communityUserId: communityUser.communityUserId,
            communityId: communityUser.communityId,
          })

          EventBus.trigger(
            EventBus.actions.profile.openRightUserPanelProfile,
            communityUser.communityUserId,
          )
          return
        }

        if (utils.isSkillTagNode(item)) {
          const skillTagNode = utils.getAsSkillTagNode(item)

          if (skillTagNode.data!.data!.kind === NODE_KIND.skill) {
            innerOptions.skills.push(item.data!.id)
          } else if (
            skillTagNode.data!.data.kind === NODE_KIND.custom ||
            skillTagNode.data!.data.kind === NODE_KIND.event ||
            skillTagNode.data!.data.kind === NODE_KIND.group ||
            skillTagNode.data!.data.kind === NODE_KIND.project ||
            skillTagNode.data!.data.kind === NODE_KIND.role
          ) {
            innerOptions.tags.push(item.data!.id)
          }
        } else if (utils.isOrganizationNode(item)) {
          const organizationNode = utils.getAsOrganizationNode(item)

          innerOptions.organizations.push(organizationNode.data!.id)
        } else if (utils.isKnowledgeNode(item)) {
          const knowledgeNode = utils.getAsKnowledgeNode(item)

          EventBus.trigger(
            EventBus.actions.graph.expandKnowledge,
            knowledgeNode.data!.data,
          )

          return
        } else if (utils.isAreaOfExperienceNode(item)) {
          const areaOfExperienceNode = utils.getAsAreaOfExperienceNode(item)

          EventBus.trigger(
            EventBus.actions.graph.expandAreaOfExperience,
            areaOfExperienceNode.data!.data,
          )

          return
        } else if (utils.isCommunityNode(item)) {
          EventBus.trigger(
            EventBus.actions.graph.expandCommunity,
            item.data!.id,
          )
          return
        } else if (utils.isExploreNode(item)) {
          return
        }

        EventBus.trigger(EventBus.actions.graph.search, {
          limit: 9999,
          ...innerOptions,
        })
      }
    },
    [graphState.items, onSetGraphState],
  )

  type ComboStyle = Parameters<
    Parameters<Chart.onCombineNodesHandler>[0]['setStyle']
  >[0]

  // TODO: Optimize, runs slow with ALL users and ALL tags loaded, due to neighboring.
  const handleItemInteraction = useCallback<Chart.onItemInteractionHandler>(
    async event => {
      const updatedStyles: Record<
        string,
        DeepNullable<Node<any> | LinkStyle<any> | ComboStyle>
      > = {}

      const items = { ...nodes, ...edges }
      const itemIds = Object.keys(items)

      if (event.id && items[event.id] && !utils.isCombo(event.id)) {
        if (event.selected || event.hovered) {
          const neighboringItems = await neighbors(items, event.id)

          // This is a hack to allow the fade to reset when there are no neighbors
          // and the only item that is selected is deleted. There seems to be an issue
          // in regraph that the fade only resets if multiple items were selected.
          if (Object.keys(neighboringItems).length === 0) {
            const id = itemIds.find(id => id !== event.id)

            if (id) {
              updatedStyles[id] = {
                ...updatedStyles[id],
                fade: true,
              }
            }
          }

          updatedStyles[event.id] = {
            ...updatedStyles[event.id],
            fade: false,
            width: 2,
          }

          forEach(neighboringItems, (_, key) => {
            updatedStyles[key] = {
              ...updatedStyles[key],
              fade: false,
              width: 2,
            }
          })
        }
      }

      event.setStyle(updatedStyles)
    },
    [nodes, edges],
  )

  const handleDragStart = useCallback<Chart.onDragStartHandler<IItemData>>(
    event => {
      setIsDragging(true)

      const draggedItem = event.id ? event.draggedItems[event.id] : null

      if (
        event.id &&
        utils.isUserQuickConnectGlyph(draggedItem, event.subItem)
      ) {
        setDragStartId(event.id)
        event.setDragOptions({
          // Create a temporary link dragger
          dummyLink: DUMMY_LINK,
        })
      }
    },
    [],
  )

  // When drag ends, event shows items below the cursor
  const handleDragOver = useCallback<Chart.onDragOverHandler<IItemData>>(
    event => {
      if (!event.id || !dragStartId || event.id === dragStartId) return

      const hoveredItem = graphState.items[event.id]
      // Type is hardcoded to "node", but can be "create-link" so manually case it as a DraggerType to fix TS error
      if (hoveredItem && (event.type as DragEvent['type']) === 'create-link') {
        let kind: string = hoveredItem.data!.type

        if (utils.isSkillTagNode(hoveredItem)) {
          kind = utils.getAsSkillTagNode(hoveredItem).data!.data.kind
        }

        EventBus.trigger(EventBus.actions.graph.setTooltip, {
          message: `Link to ${kind}`,
          ...event,
          enabled: true,
        })
      } else {
        EventBus.trigger(EventBus.actions.graph.setTooltip, {
          message: null,
        })
      }
    },
    [dragStartId, graphState.items],
  )

  // TODO: add skill/tag directly if user is me
  const handleDragEnd = useCallback<Chart.onDragEndHandler<IItemData>>(
    async event => {
      setIsDragging(false)

      if (!event.id || !dragStartId || !community || event.id === dragStartId) {
        return
      }

      const startItem = graphState.items[dragStartId]
      const endItem = graphState.items[event.id]
      EventBus.trigger(EventBus.actions.graph.setTooltip, { message: null })

      if (!endItem) return

      const skillTagKinds: SkillTagKind[] = [
        NODE_KIND.skill,
        NODE_KIND.custom,
        NODE_KIND.event,
        NODE_KIND.group,
        NODE_KIND.project,
        NODE_KIND.role,
      ]

      const startCommunityUserId = startItem.data?.data?.communityUserId
      const endCommunityUserId = endItem.data?.data?.communityUserId

      const handleUserToUserConnection = async () => {
        await connect({
          variables: {
            communityId: community.id,
            fromCommunityUserId: startCommunityUserId,
            toCommunityUserId: endCommunityUserId,
            source: CommunityUserConnectionSource.NetworkOs,
          },
        })
        // TODO: Instead of calling eventbus, we should a better way to update the graphState
        EventBus.trigger(EventBus.actions.graph.connectUser, {
          communityUser: graphState.items[startItem.data!.id].data!.data,
          connectTo: graphState.items[endItem.data!.id].data!.data,
        })
      }

      const handleUserToSkillTagConnection = async (
        id: string,
        name: string,
        kind: any,
        communityUserId: string,
      ) => {
        if (!skillTagKinds.includes(kind)) {
          return
        }

        const usersToSkills = [
          {
            skillId: id,
            communityUserId,
          },
        ]

        const communityIds = me?.communities?.map(e => e.id) || []

        switch (kind) {
          case NODE_KIND.skill:
            await connectUsersToSkills({
              variables: {
                usersToSkills,
                communityId: community.id,
              },
              update: getCommunityUserSkillsUpdater({
                communityIds,
                communityUsers: [
                  {
                    communityUserId,
                    communityId: community.id,
                  },
                ],
                skills: [
                  {
                    id,
                    name,
                  },
                ],
              }),
            })
            break
          case NODE_KIND.custom:
          case NODE_KIND.event:
          case NODE_KIND.group:
          case NODE_KIND.project:
          case NODE_KIND.role:
            await connectUsersToTags({
              variables: {
                communityId: community?.id,
                usersToTags: [
                  {
                    tagId: id,
                    communityUserId,
                  },
                ],
              },
              update: getCommunityUserTagsUpdater({
                communityIds: [community?.id],
                communityUsers: [
                  {
                    communityUserId,
                    communityId: community.id,
                  },
                ],
                communityUserTags: [
                  {
                    tagId: id,
                    tag: {
                      id,
                      name,
                      kind,
                    },
                  },
                ] as any[],
              }),
            })
            break
          default:
            return
        }

        EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
          fromId: startItem.data!.id,
          toId: endItem.data!.id,
          kind: endItem.data!.type,
        })
      }

      if (utils.isUserNode(startItem) && utils.isUserNode(endItem)) {
        await handleUserToUserConnection()
      } else if (utils.isUserNode(startItem) && utils.isSkillTagNode(endItem)) {
        const skillTagNode = utils.getAsSkillTagNode(endItem)
        // Use the single/currently active community to load the proper communityUserId
        // IMPORTANT: When UI allows selecting more than one community, things will behave badly in the current UI implementation
        const communityUserId = startItem.data?.data?.communityUsers.find(
          (e: any) => e.communityId === community.id,
        )?.communityUserId

        await handleUserToSkillTagConnection(
          skillTagNode.data!.data?.id,
          skillTagNode.data!.data?.name,
          skillTagNode.data?.data?.kind,
          communityUserId,
        )
      } else if (utils.isSkillTagNode(startItem) && utils.isUserNode(endItem)) {
        const skillTagNode = utils.getAsSkillTagNode(startItem)
        // Use the single/currently active community to load the propert communityUserId
        // IMPORTANT: When UI allows selecting more than one community, things will behave badly in the current UI implementation
        const communityUserId = endItem.data?.data?.communityUsers.find(
          (e: any) => e.communityId === community.id,
        )?.communityUserId

        await handleUserToSkillTagConnection(
          skillTagNode.data!.data?.id,
          skillTagNode.data!.data?.name,
          skillTagNode.data?.data?.kind,
          communityUserId,
        )
      } else if (
        endItem?.data?.id &&
        startItem.data?.data.userId &&
        utils.isUserNode(startItem) &&
        utils.isCommunityNode(endItem)
      ) {
        // TODO: update to use communityUserIds
        await addUsersToCommunities({
          variables: {
            userIds: [startItem.data.data.userId],
            communityIds: [endItem.data.id],
            sourceCommunityId: startItem.data.data.communityId,
          },
          update: getCommunityUserCommunitiesUpdater({
            communityUsers: [
              {
                communityUserId: startItem.data.data.communityUserId,
                communityId: community.id,
              },
            ],
            communities: [
              {
                id: endItem.data.id,
                name: endItem?.data.data?.name,
              } as MainSchema.Community,
            ],
          }),
        })

        EventBus.trigger(EventBus.actions.graph.addCommunityEdge, {
          fromId: startCommunityUserId,
          toId: endCommunityUserId,
          kind: NODE_KIND.community,
        })
      }
    },
    [
      addUsersToCommunities,
      community,
      connect,
      connectUsersToSkills,
      connectUsersToTags,
      dragStartId,
      graphState.items,
      me?.communities,
    ],
  )

  const handleCombineNodes = useCallback<
    Chart.onCombineNodesHandler<IItemData>
  >(
    ({ combo, id, setStyle, nodes }) => {
      const color = utils.getClusteredColor(combo)
      const text = utils.getClusteredName(combo, id)

      const size = Math.sqrt(keys(nodes)?.length || 2)

      const typeGylph: Glyph = {
        angle: 180,
        radius: 45,
        label: { text, color: 'black' },
        color: 'white',
        border: {
          color,
        },
      }

      setStyle({
        open: !!graphState.openCombos?.[id],
        border: { color, width: 5 },
        fade: false,
        label: {
          text: '',
          fontSize: 10,
        },
        closedStyle: {
          color,
          size: Math.min(size, 10),
          glyphs: [typeGylph],
        },
      })
    },
    [graphState.openCombos],
  )

  const handleCombineLinks = useCallback<Chart.onCombineLinksHandler>(
    ({ id1, id2, setStyle }) => {
      const comboData = utils.isCombo(id1) ? id1.split('_') : id2.split('_')

      const kind = comboData[comboData.length - 2] as NodeKind

      const color = utils.nodeKindColor(kind, theme.colors.primaryCardinal)

      setStyle({
        contents: false,
        color,
        width: 1,
        lineStyle: 'solid',
      })
    },
    [],
  )

  const handleShowContextMenu = useCallback<Chart.onContextMenuHandler>(
    event => {
      event.preventDefault()

      const { id } = event

      if (id === null) {
        return
      }

      const item = graphState.items[id]

      if (item && item.data!.type === 'edge') {
        return
      }

      if (graphState.selection?.[id] === null) {
        onSetGraphState(prevState => ({
          ...prevState,
          selection: { ...prevState.selection, [id]: true },
        }))
      }

      onSetContextMenuIds([id])

      if (item.data!.type !== ItemType.User) {
        onSetShowGraphMenu({
          x: event.x,
          y: event.y + 25,
        })
      }
    },
    [
      graphState.items,
      graphState.selection,
      onSetContextMenuIds,
      onSetShowGraphMenu,
      onSetGraphState,
    ],
  )

  const handleLayoutGraph = useCallback(() => {
    onSetGraphState(prevState => ({
      ...prevState,
      positions: {},
      layout: initialLayout,
    }))
  }, [onSetGraphState, initialLayout])

  const handleSetLayout = useCallback(
    (layout: GraphLayout) => {
      onSetGraphState(prevState => ({
        ...prevState,
        positions: prevState.layout?.name === layout ? prevState.positions : {},
        layout: {
          ...prevState.layout,
          name: layout,
          top: targetUser?.communityUserId
            ? [targetUser.communityUserId]
            : undefined,
        },
      }))
    },
    [onSetGraphState, targetUser],
  )

  return {
    isDragging,
    handleClick,
    handleDoubleClick,
    handleChange,
    handleHover,
    handleItemInteraction,
    handleDragStart,
    handleCombineNodes,
    handleCombineLinks,
    handleDragEnd,
    handleDragOver,
    handleShowContextMenu,
    handleLayoutGraph,
    handleSetLayout,
  }
}
