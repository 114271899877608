import styled from 'styled-components'

import { resetButton } from 'Components/Styles'

import colors from 'Theme/_v2/colors'

export const Container = styled.div`
  position: absolute;
  z-index: 999;
  bottom: 0;
  right: 16px;
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid rgba(221, 225, 228, 0.49);
  width: 333px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
  max-height: 100%;
  overflow-y: auto;
`

export const CloseButton = styled.button`
  ${resetButton}

  color: ${colors.link.secondary.main};

  :enabled {
    cursor: pointer;

    :visited {
      color: ${colors.link.secondary.main};
    }

    :hover {
      color: ${colors.link.secondary.hover};
    }

    :focus,
    :active {
      color: ${colors.link.secondary.focus};
    }
  }
`
