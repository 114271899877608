import React from 'react'

import { Row, SelectField } from 'Components/UI'
import { ISelectField } from 'Components/UI/Form/Select/SelectField'

import {
  COMMUNITY_USER_RELATIONSHIP_LABELS,
  COMMUNITY_USER_RELATIONSHIP_STRENGTH,
} from 'Constants/ids'

import { Circle } from './styles'

export interface IStrengthOption {
  label: React.ReactNode
  value: string
}

export const OPTIONS: IStrengthOption[] = [
  {
    label: (
      <Row center gap={2}>
        <Circle backgroundColor="#2B2B2B">3</Circle>
        {
          COMMUNITY_USER_RELATIONSHIP_LABELS[
            COMMUNITY_USER_RELATIONSHIP_STRENGTH.STRONG
          ]
        }
      </Row>
    ),
    value: COMMUNITY_USER_RELATIONSHIP_STRENGTH.STRONG,
  },
  {
    label: (
      <Row center gap={2}>
        <Circle backgroundColor="#787878">2</Circle>
        {
          COMMUNITY_USER_RELATIONSHIP_LABELS[
            COMMUNITY_USER_RELATIONSHIP_STRENGTH.MODERATE
          ]
        }
      </Row>
    ),
    value: COMMUNITY_USER_RELATIONSHIP_STRENGTH.MODERATE,
  },
  {
    label: (
      <Row center gap={2}>
        <Circle backgroundColor="#A9A9A9">1</Circle>
        {
          COMMUNITY_USER_RELATIONSHIP_LABELS[
            COMMUNITY_USER_RELATIONSHIP_STRENGTH.WEAK
          ]
        }
      </Row>
    ),
    value: COMMUNITY_USER_RELATIONSHIP_STRENGTH.WEAK,
  },
  {
    label: (
      <Row center gap={2}>
        <Circle backgroundColor="#CECECE">0</Circle>
        {
          COMMUNITY_USER_RELATIONSHIP_LABELS[
            COMMUNITY_USER_RELATIONSHIP_STRENGTH.DONT_KNOW
          ]
        }
      </Row>
    ),
    value: COMMUNITY_USER_RELATIONSHIP_STRENGTH.DONT_KNOW,
  },
]

export interface IRelationshipStrengthField
  extends ISelectField<IStrengthOption, false> {
  info?: string
}

function RelationshipStrengthField({
  name,
  label = 'Relationship Strength',
  info = 'Categorizing your relationship <br /> with this person',
  ...rest
}: IRelationshipStrengthField) {
  return (
    <SelectField<IStrengthOption, false>
      {...rest}
      blurInputOnSelect
      infoText={info}
      isSearchable={false}
      label={label}
      name={name}
      options={OPTIONS}
      withPortal
    />
  )
}

export default RelationshipStrengthField
