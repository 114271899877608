import React, { useCallback } from 'react'

import { pick } from '@styled-system/props'

import { IRow, Row } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import { StyledTab } from './styles'

export interface ITabs extends IRow {
  tabs: string[]
  activeTab?: string
  onChange?: (index: string) => void
}

function Tabs({ tabs, activeTab = '', gap = 6, onChange, ...rest }: ITabs) {
  const handleClick = useCallback(
    (index: string) => {
      if (index !== activeTab) onChange?.(index)
    },
    [activeTab, onChange],
  )

  return (
    <Row {...pick(rest)} gap={gap}>
      {tabs.map(tab => (
        <StyledTab
          isActive={tab === activeTab}
          key={tab}
          onClick={() => handleClick(tab)}
        >
          <Text header4>{tab}</Text>
        </StyledTab>
      ))}
    </Row>
  )
}

export default Tabs
