import React from 'react'

interface SelectionHeaderProps {
  allSelected: boolean
  onSelectAll: (checked: boolean) => void
}

interface SelectionCellProps {
  rowIndex: number
  selected: boolean
  onRowClick: (index: number) => void
}

export const SelectionHeader: React.FC<SelectionHeaderProps> = React.memo(
  ({ allSelected, onSelectAll }) => (
    <input
      checked={allSelected}
      type="checkbox"
      onChange={e => onSelectAll(e.target.checked)}
    />
  ),
)

export const SelectionCell: React.FC<SelectionCellProps> = React.memo(
  ({ rowIndex, selected, onRowClick }) => (
    <input
      checked={selected}
      type="checkbox"
      onChange={() => onRowClick(rowIndex)}
      onClick={e => e.stopPropagation()}
    />
  ),
)
