/** RBA Actions: read,create,edit,delete */
export enum Action {
  Create = 'create',
  Delete = 'delete',
  Edit = 'edit',
  Read = 'read',
}

/** AskOfferStatementKind: Ask,Offer */
export enum AskOfferStatementKind {
  Ask = 'Ask',
  Offer = 'Offer',
}

/** CommunityInvite: pending,accepted,declined,active,onboarding */
export enum CommunityInviteState {
  Accepted = 'accepted',
  Active = 'active',
  Declined = 'declined',
  Onboarding = 'onboarding',
  Pending = 'pending',
}

export enum CommunityUserConnectionResultKind {
  Existing = 'EXISTING',
  New = 'NEW',
}

/** The source of a community user connection */
export enum CommunityUserConnectionSource {
  Contacts = 'contacts',
  Email = 'email',
  GoogleMeet = 'googleMeet',
  Migration = 'migration',
  NetworkOs = 'networkOS',
  Unknown = 'unknown',
}

/** The kind of graph view */
export enum CommunityUserGraphViewKind {
  SavedGraph = 'SavedGraph',
  SystemGraph = 'SystemGraph',
}

/** CommunityUserRelationship: dontKnow,weak,moderate,strong */
export enum CommunityUserRelationship {
  DontKnow = 'dontKnow',
  Moderate = 'moderate',
  Strong = 'strong',
  Weak = 'weak',
}

/** CommunityUser status: pending,full,archived */
export enum CommunityUserStatus {
  Archived = 'archived',
  Full = 'full',
  Pending = 'pending',
}

/** ConnectAccountType states: signIn,signUp,connect,onboarding */
export enum ConnectAccountType {
  Connect = 'connect',
  Onboarding = 'onboarding',
  SignIn = 'signIn',
  SignUp = 'signUp',
}

/** The kind of data source */
export enum DataSourceKind {
  Brand = 'brand',
  Exchange = 'exchange',
  Facebook = 'facebook',
  Google = 'google',
  HubSpot = 'hubSpot',
  Humantic = 'humantic',
  IScraper = 'iScraper',
  LinkedIn = 'linkedIn',
  Microsoft = 'microsoft',
  NetworkOs = 'networkOs',
  Networker = 'networker',
  PeopleDataLabs = 'peopleDataLabs',
  Twitter = 'twitter',
  User = 'user',
}

/** EmailCredential states: pending,active,deactivated */
export enum EmailCredentialState {
  Active = 'active',
  Deactivated = 'deactivated',
  Pending = 'pending',
}

/** They type of grouping strategy to use */
export enum GroupRecommendationStrategy {
  AlignedAskOffer = 'AlignedAskOffer',
  Diverse = 'Diverse',
}

/** HubSpotState: pending,active,inactive,syncing */
export enum HubSpotState {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
  Syncing = 'syncing',
}

/** The different kinds of nodes in the knowledge graph */
export enum KnowledgeGraphNodeKind {
  Event = 'event',
  Location = 'location',
  Organization = 'organization',
  Person = 'person',
  Skill = 'skill',
  Technology = 'technology',
  Topic = 'topic',
}

/** MentionKind: addUser,meetUser,addCommunityUser,meetCommunityUser,skill,event,project,role,custom,group */
export enum MentionKind {
  AddCommunityUser = 'addCommunityUser',
  AddUser = 'addUser',
  Custom = 'custom',
  Event = 'event',
  Group = 'group',
  MeetCommunityUser = 'meetCommunityUser',
  MeetUser = 'meetUser',
  Project = 'project',
  Role = 'role',
  Skill = 'skill',
}

/** NoteSource: networkOS,nylas */
export enum NoteSource {
  NetworkOs = 'networkOS',
  Nylas = 'nylas',
}

/** NoteTargetEntityKind: user,communityUser,tag */
export enum NoteTargetEntityKind {
  CommunityUser = 'communityUser',
  Tag = 'tag',
  User = 'user',
}

/** NotificationKind: task */
export enum NotificationKind {
  Task = 'task',
}

/** OAuthProvider: google,microsoft,hubspot */
export enum OAuthProvider {
  Google = 'google',
  Hubspot = 'hubspot',
  Microsoft = 'microsoft',
}

/** OAuthResponseKind: success,exists,notFound,created,deleted,requiresConsent */
export enum OAuthResponseKind {
  Created = 'created',
  Deleted = 'deleted',
  Exists = 'exists',
  NotFound = 'notFound',
  RequiresConsent = 'requiresConsent',
  Success = 'success',
}

export enum PinnedNodeKind {
  CommunityUser = 'CommunityUser',
  Organization = 'Organization',
  Skill = 'Skill',
  Tag = 'Tag',
}

/** The kind of profile entry */
export enum ProfileEntryKind {
  About = 'About',
  EducationHistory = 'EducationHistory',
  Email = 'Email',
  FacebookUrl = 'FacebookUrl',
  FirstName = 'FirstName',
  FullName = 'FullName',
  InterestsHobbies = 'InterestsHobbies',
  LastName = 'LastName',
  LinkedInUrl = 'LinkedInUrl',
  Location = 'Location',
  Phone = 'Phone',
  PhotoUrl = 'PhotoUrl',
  Skill = 'Skill',
  Tag = 'Tag',
  TwitterUrl = 'TwitterUrl',
  WorkHistory = 'WorkHistory',
}

/** RBA Scopes: own,other,public,private,target */
export enum Scope {
  Other = 'other',
  Own = 'own',
  Private = 'private',
  Public = 'public',
  Target = 'target',
}

/** Sort input order: asc,desc */
export enum SortInputOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sources: networkOS,hubSpot,nylas,nylasEmail,nylasContacts,nylasCalendar,peopleDataLabs */
export enum Source {
  HubSpot = 'hubSpot',
  NetworkOs = 'networkOS',
  Nylas = 'nylas',
  NylasCalendar = 'nylasCalendar',
  NylasContacts = 'nylasContacts',
  NylasEmail = 'nylasEmail',
  PeopleDataLabs = 'peopleDataLabs',
}

/** RBA Subjects: note,invite,account,adminPolicy,adminOnboarding,hubspot,metrics,uploadData,managementTag,managementIndustriesTag,managementSkill,managementRba,apiKey,askOffer,communityUserConnection */
export enum Subject {
  Account = 'account',
  AdminOnboarding = 'adminOnboarding',
  AdminPolicy = 'adminPolicy',
  ApiKey = 'apiKey',
  AskOffer = 'askOffer',
  CommunityUserConnection = 'communityUserConnection',
  Hubspot = 'hubspot',
  Invite = 'invite',
  ManagementIndustriesTag = 'managementIndustriesTag',
  ManagementRba = 'managementRba',
  ManagementSkill = 'managementSkill',
  ManagementTag = 'managementTag',
  Metrics = 'metrics',
  Note = 'note',
  UploadData = 'uploadData',
}

/** TagKind: event,project,role,custom,group,industry */
export enum TagKind {
  Custom = 'custom',
  Event = 'event',
  Group = 'group',
  Industry = 'industry',
  Project = 'project',
  Role = 'role',
}

/** Timeframe: hour,day,week,month,quarter,year */
export enum Timeframe {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

/** The sentiment of the user feedback */
export enum UserFeedbackSentiment {
  Negative = 'Negative',
  Neutral = 'Neutral',
  Positive = 'Positive',
}

/** User roles: user,superAdmin */
export enum UserRole {
  SuperAdmin = 'superAdmin',
  User = 'user',
}

/** User states: active,deactivated */
export enum UserState {
  Active = 'active',
  Deactivated = 'deactivated',
}
