import React, { useCallback, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { fullNameOrFallback } from 'Features/CommunityUsers/communityUserNameUtil'
import ExplainRecommendedPersonQuery from 'GraphQL/Queries/ExplainRecommendedPersonQuery.graphql'

import { Avatar, Loader } from 'Components/UI'

import { useAppContext, useCommunity } from 'Hooks'

import EventBus from 'Services/EventBus'

import * as Styled from './AskOfferTab.styles'

interface Props {
  recommendedPeople: MainSchema.RecommendedPersonType[]
  askOfferStatementId: string
  communityUser: MainSchema.CommunityUser
}

export default function RecommendedPeople({
  recommendedPeople,
  askOfferStatementId,
  communityUser,
}: Props) {
  const { community } = useCommunity()
  const { me } = useAppContext()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [activeCommunityUserId, setActiveCommunityUserId] = useState<
    string | null
  >(null)
  const [explanations, setExplanations] = useState<Record<string, string>>({})

  const onClickRecommendedPerson = useCallback(
    (index: number, communityUserId: string) => {
      if (activeIndex === index) {
        setActiveIndex(null)
        setActiveCommunityUserId(null)
      } else {
        setActiveIndex(index)
        setActiveCommunityUserId(communityUserId)

        const person = recommendedPeople[index]
        const communityUserCommunityId = person.communityUser.communityId

        EventBus.trigger(EventBus.actions.search.community, {
          id: person.communityUser.id,
          type: 'user',
          value: fullNameOrFallback(person.communityUser),
          label: fullNameOrFallback(person.communityUser),
          communities: [
            person.communityUser.communities?.find(
              community => community.id === communityUserCommunityId,
            ) || [],
          ],
        })

        EventBus.trigger(
          EventBus.actions.graph.focusNode,
          person.communityUser.id,
        )
      }
    },
    [activeIndex, recommendedPeople],
  )

  const {
    data: explainRecommendedPersonQuery,
    refetch,
    loading,
  } = useQuery<Pick<MainSchema.Query, 'explainRecommendedPerson'>>(
    ExplainRecommendedPersonQuery,
    {
      variables: {
        forAskOfferStatementId: askOfferStatementId,
        recommendedCommunityUserId: activeCommunityUserId,
        asViewedByCommunityUserId: me?.communityUserId,
      },
      skip:
        !activeCommunityUserId || !askOfferStatementId || !communityUser?.id,
      context: {
        headers: {
          'x-community-id': community?.id,
        },
      },
      onCompleted: data => {
        if (
          data?.explainRecommendedPerson?.explanation &&
          activeCommunityUserId
        ) {
          setExplanations(prev => ({
            ...prev,
            [activeCommunityUserId]: data.explainRecommendedPerson.explanation,
          }))
        }
      },
    },
  )

  useEffect(() => {
    if (activeCommunityUserId && !explanations[activeCommunityUserId]) {
      refetch({
        forAskOfferStatementId: askOfferStatementId,
        recommendedCommunityUserId: activeCommunityUserId,
        asViewedByCommunityUserId: me?.communityUserId,
      })
    }
  }, [
    activeCommunityUserId,
    askOfferStatementId,
    me?.communityUserId,
    refetch,
    explanations,
  ])

  const explanation =
    explainRecommendedPersonQuery?.explainRecommendedPerson.explanation

  if (recommendedPeople.length === 0) {
    return (
      <Styled.RecommendedPeople>
        <Styled.Explanation>
          No matches found, check back later.
        </Styled.Explanation>
      </Styled.RecommendedPeople>
    )
  }

  return (
    <Styled.RecommendedPeople>
      {recommendedPeople?.map((recommendedPerson, index) => (
        <li key={recommendedPerson.communityUser.id}>
          <Styled.RecommendedPerson
            onClick={() =>
              onClickRecommendedPerson(
                index,
                recommendedPerson.communityUser.id,
              )
            }
          >
            <Styled.ArrowIcon isActive={index === activeIndex} />
            <div>
              <Avatar
                extraSmall
                src={recommendedPerson.communityUser.photoUrl}
              />
            </div>
            <Styled.Label>
              {fullNameOrFallback(recommendedPerson.communityUser)}
              {index === activeIndex && loading && <Loader />}
            </Styled.Label>
          </Styled.RecommendedPerson>
          {index === activeIndex && !loading && (
            <Styled.Explanation>{explanation}</Styled.Explanation>
          )}
        </li>
      ))}
    </Styled.RecommendedPeople>
  )
}
