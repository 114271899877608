import { Draggable, DraggableProps } from 'react-drag-and-drop'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import {
  IconDotsVertical,
  IconFolderOpen,
  IconPlugConnectedX,
} from '@tabler/icons-react'

import { SEARCH_INPUT_HEIGHT } from 'Components/Blocks/Community/CommunitySearch/CommunitySearch.styles'
import { resetButton } from 'Components/Styles'

import { gap, GapProps } from 'Theme/system'

const wrapperSelectedCss = ({ selected }: { selected?: boolean }) =>
  selected &&
  css`
    border: 1px solid ${themeGet('colors.divider.hover')};
  `

export interface IWrapper extends DraggableProps {
  selected?: boolean
}

export const Wrapper = styled(Draggable)<IWrapper>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: ${themeGet('space.3')}px;
  border: 1px solid ${themeGet('colors.divider.default')};
  cursor: pointer;
  width: 100%;

  ${wrapperSelectedCss}
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`

export const DotsIcon = styled(IconDotsVertical)`
  width: 16px;
  height: 16px;
  color: ${themeGet('colors.text.header')};
  stroke-width: 1.5px;
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 4px;
  overflow: hidden;

  svg {
    width: 16px;
    height: 16px;
    stroke-width: 1.5px;
  }
`

export interface IOptionProps extends GapProps {}

export const Option = styled.button<IOptionProps>`
  ${resetButton};

  display: flex;
  width: 100%;
  border-bottom: 1px solid ${themeGet('colors.divider.default')};
  padding: ${themeGet('space.3')}px;
  align-items: center;
  cursor: pointer;
  color: ${themeGet('colors.text.header')};

  p {
    color: ${themeGet('colors.text.header')};
  }

  :last-of-type {
    border: 0;
  }

  :hover {
    color: ${themeGet('colors.hover')};
    background-color: ${themeGet('colors.bg.hover')};

    p {
      color: ${themeGet('colors.hover')};
    }
  }

  ${gap}
`

export const DisconnectIcon = styled(IconPlugConnectedX)``

export const ViewProfileIcon = styled(IconFolderOpen)``

export const Input = styled.input`
  width: 100%;
  height: ${SEARCH_INPUT_HEIGHT}px;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  appearance: none;
  background: transparent;
  color: #2d2d2d;
  font-weight: 400;
  font-size: 14px;

  ::placeholder {
    color: #757575;
  }
`

export const Dropdown = styled.div`
  position: relative;
  margin-right: 6px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid ${themeGet('colors.divider.default')};
  shadow: 1px 4px 8px rgba(0, 0, 0, 0.4);

  &:hover,
  &:focus,
  &:active,
  &[aria-expanded='true'] {
    > button > svg {
      color: ${themeGet('colors.main')};
    }
  }
`
