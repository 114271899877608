import styled from 'styled-components'

import { communitiesGlyph } from 'Assets/Svg/icons'
import {
  contactsGlyph,
  educationHistoriesGlyph,
  educationHistoryPlaceholderGlyph,
  workHistoriesGlyph,
  workHistoryPlaceholderGlyph,
} from 'Assets/Svg/icons/profile'
import {
  customTagGlyph,
  eventTagGlyph,
  groupTagGlyph,
  projectTagGlyph,
  skillTagGlyph,
} from 'Assets/Svg/icons/tags'

export const IconContacts = styled(contactsGlyph)``
export const IconCustomTag = styled(customTagGlyph)``
export const IconEducationHistories = styled(educationHistoriesGlyph)``
export const IconEducationHistoryPlaceholder = styled(
  educationHistoryPlaceholderGlyph,
)``
export const IconEventTag = styled(eventTagGlyph)``
export const IconGroupTag = styled(groupTagGlyph)``
export const IconProjectTag = styled(projectTagGlyph)``
export const IconSkillTag = styled(skillTagGlyph)``
export const IconWorkHistories = styled(workHistoriesGlyph)``
export const IconWorkHistoryPlaceholder = styled(workHistoryPlaceholderGlyph)``
export const IconCommunities = styled(communitiesGlyph)``
