import { ApolloCache, MutationUpdaterFunction } from '@apollo/client'
import getCommunityUserQuery from 'Features/ProfilePanel/Queries/getCommunityUser.graphql'

import forEach from 'lodash/forEach'

interface ICommunityUser {
  communityUserId?: string
  communityId: string
}

type IGetCommunityUserCommunitiesUpdater = MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'addUsersToCommunities'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
>

export function getCommunityUserCommunitiesUpdater({
  communityUsers,
  communities,
}: {
  communityUsers: ICommunityUser[]
  communities: MainSchema.Community[]
}): IGetCommunityUserCommunitiesUpdater {
  return (cache, { data }) => {
    if (!data?.addUsersToCommunities) return

    forEach(communityUsers, async communityUser => {
      const { communityUserId } = communityUser

      const queryGetCommunityUser = {
        query: getCommunityUserQuery,
        variables: {
          communityUserId,
          communityIds: communities.map(e => e.id),
        },
      }

      const getCommunityUserData = cache.readQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >(queryGetCommunityUser)

      if (getCommunityUserData?.getCommunityUser) {
        const combinedCommunities: MainSchema.Community[] = [
          ...(getCommunityUserData.getCommunityUser.communities || []),
          ...communities.filter(e => e.id === communityUser.communityId),
        ]

        const communityMap = new Map<string, MainSchema.Community>()

        for (const community of combinedCommunities) {
          if (!communityMap.has(community.id)) {
            communityMap.set(community.id, community)
          }
        }

        const mergedCommunities: MainSchema.Community[] = Array.from(
          communityMap.values(),
        )

        cache.writeQuery<
          Pick<MainSchema.Query, 'getCommunityUser'>,
          MainSchema.QueryGetCommunityUserArgs
        >({
          ...queryGetCommunityUser,
          data: {
            ...getCommunityUserData,
            getCommunityUser: {
              ...getCommunityUserData.getCommunityUser,
              communities: mergedCommunities,
            },
          },
        })
      }
    })
  }
}
