/* eslint-disable class-methods-use-this */
import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import Regraph from 'regraph'

import EventBus from 'Services/EventBus'

import { BaseGraphNode } from './BaseGraphNode'

import { ItemType } from '../constants'
import { isGlyphAskOffer } from '../Helpers/isGlyph'
import { IGraphNodeEdgeEvent } from '../IRegraphContext'

export class PersonNode extends BaseGraphNode {
  public item: Regraph.Node

  public entity: IGraphPersonNode

  private isMe: boolean

  constructor({
    person,
    isMe = false,
  }: {
    person: IGraphPersonNode
    isMe?: boolean
  }) {
    super(person.communityUserId)
    this.entity = person
    this.isMe = isMe
    this.data = {
      id: person?.communityUserId,
      userId: person?.userId,
      communityUserId: person?.communityUserId,
      type: ItemType.User,
    }

    this.item = this.build()
  }

  onClick(args?: IGraphNodeEdgeEvent): Regraph.Node {
    if (isGlyphAskOffer(this, args?.event?.subItem)) {
      EventBus.trigger(
        EventBus.actions.profile.openRightUserPanelProfile,
        this.entity?.communityUserId,
        'ASK_OFFER',
      )
    }
    return this.item
  }

  onDoubleClick(args?: IGraphNodeEdgeEvent): Regraph.Node {
    super.onDoubleClick()

    EventBus.trigger(
      EventBus.actions.profile.openRightUserPanelProfile,
      args?.event?.communityUserId,
    )

    const communityUserIds =
      this.entity.connections?.map(e => e.toCommunityUserId!) || []

    if (
      args?.handleLoadPeople &&
      args?.communityIds &&
      communityUserIds?.length
    ) {
      args?.handleLoadPeople?.({
        communityUserIds,
        communityIds: args.communityIds,
      })
    }

    return this.item
  }

  onItemIteraction(args?: IGraphNodeEdgeEvent): Regraph.Node {
    super.onItemIteraction()

    const hasAsk =
      this.entity?.askOfferStatements?.some(ask => ask.kind === 'Ask') ?? false

    // Get the current size of the selected item, so we can add modifiers to it
    let size = args?.event?.item?.size
    if (args?.event.selected) {
      const meNodeSizeMultiplier = this.isMe ? 0.5 : 0
      const isSelectedNodeSizeMultiplier = 0.75
      size = size + meNodeSizeMultiplier + isSelectedNodeSizeMultiplier
    }

    const glyphs = this.item?.glyphs ?? []

    glyphs.push({
      image: '/img/quick-connector.svg',
      radius: 29,
      angle: 180,
      size: 1,
    })

    const label = {
      ...this.item?.label,
      margin: {
        top: 95,
      },
    }

    return {
      ...this,
      size,
      glyphs,
      halos: [
        {
          color: '#27af8b',
          radius: 28,
          width: 3,
        },
        {
          color: '#1ccda4',
          radius: 32,
          width: 6,
        },
        {
          color: '#5ebbe5',
          radius: 28,
          width: hasAsk ? 3 : 0,
        },
        {
          color: '#8ecfed',
          radius: 32,
          width: hasAsk && true ? 6 : 0,
        },
      ],
      label,
    } as Regraph.Node
  }

  private build(): Regraph.Node {
    const text = this.getUserLabel(this.entity)
    const glyphs: Regraph.Glyph[] = []
    const hasAsk =
      this.entity?.askOfferStatements?.some(ask => ask.kind === 'Ask') ?? false

    if (this.entity) {
      glyphs.push({
        image: '/img/quick-connector.svg',
        radius: 29,
        angle: 180,
        size: 1,
      })
    }

    if (hasAsk && this.entity) {
      glyphs.push({
        image: '/img/ask-glyph.svg',
        radius: 28,
        angle: 45,
        size: 1,
        color: '#f8f9fc',
      })
    }

    if (!this.entity) {
      glyphs.push({
        image: '/img/invalid.svg',
        radius: 44,
        angle: 0,
        size: 1,
      })
    }

    const userImagePath = this.isMe
      ? '/img/nodes/user.svg'
      : '/img/nodes/contact.svg'

    const meNodeSizeMultiplier = this.isMe ? 0.5 : 0

    const halos = []

    if (hasAsk) {
      halos.push({
        color: '#5ebbe5',
        radius: 28,
        width: 3,
      })
    }

    return {
      data: this.data,
      color: this.isMe ? '#CD1C45' : '#BBBFC2',
      shape: 'circle',
      cutout: true,
      size: 1 + meNodeSizeMultiplier,
      glyphs,
      halos,
      label: {
        backgroundColor: 'transparent',
        margin: {
          top: 95,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2d2d2d',
        fontFamily: 'Inter',
        fontSize: 10,
        bold: false,
        text,
      } as Regraph.NodeLabel,
      image: this.entity?.photoUrl
        ? this.replaceImage(this.entity.photoUrl)
        : userImagePath,
      fade: false,
    }
  }
}
