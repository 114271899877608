import React from 'react'

import styled from 'styled-components'

import { Avatar } from 'Components/UI'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const Label = styled.div`
  margin-left: 4px;
`

export default function NameCell({
  name,
  photoUrl,
}: {
  name?: string
  photoUrl?: string
}) {
  return (
    <Wrapper>
      <Avatar extraSmall src={photoUrl} text={name?.charAt(0)} />
      <Label>{name}</Label>
    </Wrapper>
  )
}
