import React, { useCallback, useState } from 'react'
import { GroupBase } from 'react-select'
import { LoadOptions } from 'react-select-async-paginate'

import { useApolloClient } from '@apollo/client'
import directSearchJobTitlesQuery from 'GraphQL/Queries/JobTitle/directSearchJobTitles.graphql'
import { wrapSymbolWithSpan } from 'Utils/Strings'

import { SelectField } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

export interface JobTitleFieldProps {
  name?: string
  label?: string
  placeholder?: string
  isRequired?: boolean
}

type Additional = { page: number }

export interface JobTitleOption {
  label: React.ReactNode
  value: string
  isNew: boolean
}

// TODO: custom styles
function JobTitleField({
  name,
  label,
  placeholder,
  isRequired,
}: JobTitleFieldProps) {
  const client = useApolloClient()
  const t = useScopedI18n('components.blocks.forms.fields.jobTitleField')
  const errorT = useScopedI18n('error')
  const [isLoading, setIsLoading] = useState(false)

  const searchJobTitles = useCallback(
    async (inputValue: string, page: number) => {
      try {
        const result = await client.query<
          Pick<MainSchema.Query, 'directSearchJobTitles'>,
          MainSchema.QueryDirectSearchJobTitlesArgs
        >({
          query: directSearchJobTitlesQuery,
          fetchPolicy: 'network-only',
          variables: {
            query: inputValue,
            limit: 10,
            page,
          },
        })
        const results = result?.data

        return results.directSearchJobTitles
      } catch (error) {
        let message = errorT('generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: 'Oops...',
          text: `The server returned an error: "${message}"`,
        })
      }

      return null
    },
    [client, errorT],
  )

  const loadOptions = useCallback<
    LoadOptions<JobTitleOption, GroupBase<JobTitleOption>, Additional>
  >(
    async (inputValue, _, additional) => {
      if (inputValue.length < 3) {
        return {
          options: [],
          hasMore: false,
        }
      }

      setIsLoading(true)
      const page = additional?.page ?? 1

      try {
        const jobTitles = await searchJobTitles(inputValue, page)
        const options =
          jobTitles?.edges.map(jobTitleEdge => ({
            label: (
              <Text
                dangerouslySetInnerHTML={{
                  __html: wrapSymbolWithSpan(
                    jobTitleEdge.node.name!,
                    inputValue,
                  ),
                }}
                fontSize="14px"
                fontWeight={500}
                lineHeight="20px"
              />
            ),
            value: jobTitleEdge.node.id,
            isNew: false,
          })) ?? []
        const hasMore = !!jobTitles && page < jobTitles.pageInfo.totalPages

        return {
          options,
          hasMore,
          additional: {
            page: page + 1,
          },
        }
      } catch (error) {
        let message = errorT('generic')
        if (error instanceof Error) {
          message = error.message
        }
        toast.error({
          title: 'Oops...',
          text: `The server returned an error: "${message}"`,
        })
      } finally {
        setIsLoading(false)
      }

      return {
        options: [],
        hasMore: false,
      }
    },
    [searchJobTitles, errorT],
  )

  const handleIsValidNewOption = useCallback((inputValue: string) => {
    return inputValue.length > 3
  }, [])

  const handleFormatCreateLabel = useCallback(
    (inputValue: string) => {
      return (
        <Text fontSize="14px" fontWeight={600} lineHeight="20px">
          {t('createNew.label.notEmpty', {
            jobTitleName: inputValue,
          })}
        </Text>
      )
    },
    [t],
  )

  const handleNoOptionsMessage = useCallback(
    (props: { inputValue: string }) => {
      if (props.inputValue.length === 0) {
        return null
      }

      return (
        <Text fontSize="14px" fontWeight={600} lineHeight="20px">
          {t('noOptions.label', {
            jobTitleName: props.inputValue,
          })}
        </Text>
      )
    },
    [t],
  )

  const handleLoadingMessage = useCallback(() => {
    return (
      <Text fontSize="14px" fontWeight={600} lineHeight="20px">
        {t('loading.label')}
      </Text>
    )
  }, [t])

  const handleGetNewOptionData = useCallback(
    (inputValue: string, optionLabel: string) => {
      return {
        label: optionLabel,
        value: inputValue,
        isNew: true,
      }
    },
    [],
  )

  // TODO: search icon
  return (
    name && (
      <>
        <SelectField<
          JobTitleOption,
          false,
          GroupBase<JobTitleOption>,
          Additional
        >
          async
          clearable
          components={
            {
              // DropdownIndicator: null,
            }
          }
          creatable
          createOptionPosition="last"
          debounceTimeout={300}
          formatCreateLabel={handleFormatCreateLabel}
          getNewOptionData={handleGetNewOptionData}
          isLoading={isLoading}
          isValidNewOption={handleIsValidNewOption}
          label={label}
          loadOptions={loadOptions}
          loadingMessage={handleLoadingMessage}
          name={name}
          noOptionsMessage={handleNoOptionsMessage}
          paginated
          placeholder={placeholder}
          required={isRequired}
          withPortal
        />
      </>
    )
  )
}

export default JobTitleField
