import React, { useCallback, useEffect, useRef } from 'react'

import { FiDownload } from 'react-icons/fi'

import deleteCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/deleteCommunityUser.graphql'

import AddToCommunityModal from 'Components/Blocks/Modals/AddToCommunity'
import { useExportUsers } from 'Components/Blocks/Tables/AccountsTable/useExportUsers'

import { useCommunity, useEntityModal } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import EventBus from 'Services/EventBus'

import {
  ActionButton,
  ActionsButton,
  Add,
  ControlsWrapper,
  Delete,
  FilterButton,
  FilterDropdown,
  FilterIcon,
  FilterWrapper,
  Input,
  Kebab,
  Remove,
  SelectedRowsContainer,
} from './styles'

interface Community {
  id: string
  name: string
}

interface Props {
  filterText: string
  onFilterTextChange: React.ChangeEventHandler<HTMLInputElement>
  selectedRows: Set<number>
  userCommunitiesData: Community[]
  selectedCommunityIds: Set<string>
  onToggleAllCommunities: (checked: boolean) => void
  onCommunityCheckboxChange: (communityId: string, checked: boolean) => void
  isAllCommunitiesSelected: boolean
  showCommunityFilter: boolean
  setShowCommunityFilter: React.Dispatch<React.SetStateAction<boolean>>
  setShowActionsMenu: React.Dispatch<React.SetStateAction<boolean>>
  showActionsMenu: boolean
  selectedRowData: MainSchema.CommunityUser[]
  setShowMetaMenu: React.Dispatch<React.SetStateAction<boolean>>
  showMetaMenu: boolean
  filteredUsers: MainSchema.CommunityUser[]
}

const PeopleTableControls: React.FC<Props> = ({
  filterText,
  onFilterTextChange,
  selectedRows,
  userCommunitiesData,
  selectedCommunityIds,
  onToggleAllCommunities,
  onCommunityCheckboxChange,
  isAllCommunitiesSelected,
  showCommunityFilter,
  setShowCommunityFilter,
  setShowActionsMenu,
  showActionsMenu,
  selectedRowData,
  setShowMetaMenu,
  showMetaMenu,
  filteredUsers,
}) => {
  const filterRef = useRef<HTMLDivElement>(null)
  const actionsMenuRef = useRef<HTMLDivElement>(null)
  const [addToCommunityModal, addCommunityActions] =
    useEntityModal<MainSchema.CommunityUser[]>()
  const handleCloseAddToCommunityModal = addCommunityActions.closeModal
  const handleOpenAddToCommunityModal = useCallback(() => {
    EventBus.trigger(EventBus.actions.dashboard.addToCommunity, selectedRowData)
  }, [selectedRowData])
  const [deleteCommunityUser] = useMutation<
    Pick<MainSchema.Mutation, 'deleteCommunityUser'>,
    MainSchema.MutationDeleteCommunityUserArgs
  >(deleteCommunityUserMutation)
  const { community } = useCommunity()
  const { exportUsers, saveFile } = useExportUsers({
    communityIds: community ? [community.id] : [],
    selectedUsers: filteredUsers,
  })

  const onClickRemoveFromCommunity = async () => {
    if (!community) {
      return
    }

    for (const row of selectedRowData) {
      await deleteCommunityUser({
        variables: {
          communityId: community.id,
          id: row.communityUserId!,
        },
      })
    }
  }

  const onClickDeleteFromCommunity = () => {
    // console.log('onClickDeleteFromCommunity')
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (!filterRef.current ||
          !filterRef.current.contains(event.target as Node)) &&
        (!actionsMenuRef.current ||
          !actionsMenuRef.current.contains(event.target as Node))
      ) {
        setShowCommunityFilter(false)
        setShowActionsMenu(false)
        setShowMetaMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [setShowActionsMenu, setShowCommunityFilter, setShowMetaMenu])

  const onClickExportAllPeople = async () => {
    await exportUsers()
    saveFile('export-all-people.csv')
  }

  return (
    <ControlsWrapper>
      <Input
        placeholder="Search by name or email"
        type="text"
        value={filterText}
        onChange={onFilterTextChange}
      />
      {selectedRows && selectedRows.size > 0 && (
        <SelectedRowsContainer>
          <span>{selectedRows.size} selected</span>
          <ActionsButton
            type="button"
            onClick={() => setShowActionsMenu(ps => !ps)}
          >
            Actions
          </ActionsButton>
          {showActionsMenu && (
            <FilterDropdown ref={actionsMenuRef}>
              <ActionButton
                type="button"
                onClick={handleOpenAddToCommunityModal}
              >
                <Add />
                <span>Add to Community</span>
              </ActionButton>
              <ActionButton
                disabled
                type="button"
                onClick={onClickRemoveFromCommunity}
              >
                <Remove />
                <span>Remove from Community</span>
              </ActionButton>
              <ActionButton
                disabled
                type="button"
                onClick={onClickDeleteFromCommunity}
              >
                <Delete />
                <span>Delete</span>
              </ActionButton>
            </FilterDropdown>
          )}
        </SelectedRowsContainer>
      )}
      <FilterWrapper ref={filterRef}>
        <FilterButton onClick={() => setShowCommunityFilter(ps => !ps)}>
          <FilterIcon />
          Filter
        </FilterButton>
        <FilterButton onClick={() => setShowMetaMenu(ps => !ps)}>
          <Kebab />
        </FilterButton>
        {showCommunityFilter && (
          <FilterDropdown>
            <label>
              <input
                checked={isAllCommunitiesSelected}
                type="checkbox"
                onChange={e => onToggleAllCommunities(e.target.checked)}
              />
              Select All
            </label>
            {userCommunitiesData.map(community => {
              const isChecked = selectedCommunityIds.has(community.id)

              return (
                <label key={community.id}>
                  <input
                    checked={isChecked}
                    type="checkbox"
                    onChange={e =>
                      onCommunityCheckboxChange(community.id, e.target.checked)
                    }
                  />
                  {community.name}
                </label>
              )
            })}
          </FilterDropdown>
        )}
        {showMetaMenu && (
          <FilterDropdown>
            <FilterButton onClick={onClickExportAllPeople}>
              <FiDownload /> Export All People
            </FilterButton>
          </FilterDropdown>
        )}
      </FilterWrapper>

      <AddToCommunityModal
        isOpen={addToCommunityModal.isOpen}
        users={addToCommunityModal.entity}
        onClose={handleCloseAddToCommunityModal}
      />
    </ControlsWrapper>
  )
}

export default PeopleTableControls
