import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { TAG_KIND } from 'Constants/ids'

export interface EntityValue {
  id: string
  name: string
  value: string
  label: string
  kind?: string
}

export function entitiesToValues(
  entities: MainSchema.Skill[] | MainSchema.Tag[],
): EntityValue[] {
  return map(entities, entity => ({
    ...entity,
    value: entity.id,
    label: entity.name,
  }))
}

export function tagsByKind(tags: EntityValue[]) {
  return reduce(
    tags,
    (acc, tag) => {
      switch (tag.kind) {
        case TAG_KIND.ROLE:
          acc.roles.push(tag)
          break
        case TAG_KIND.PROJECT:
          acc.projects.push(tag)
          break
        case TAG_KIND.CUSTOM:
          acc.custom.push(tag)
          break
        case TAG_KIND.GROUP:
          acc.groups.push(tag)
          break
        case TAG_KIND.EVENT:
          acc.events.push(tag)
          break
        default:
          break
      }

      return acc
    },
    {
      roles: [],
      projects: [],
      custom: [],
      groups: [],
      events: [],
    } as {
      roles: EntityValue[]
      projects: EntityValue[]
      custom: EntityValue[]
      groups: EntityValue[]
      events: EntityValue[]
    },
  )
}
