import React, { useCallback, useMemo } from 'react'

import { pick } from '@styled-system/props'

import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'

import { Row } from 'Components/UI/Flex'
import { Select } from 'Components/UI/Form/Select'
import Text from 'Components/UI/Text'

import {
  IPaginationContainerProps,
  Paginate,
  PaginationContainer,
} from './styles'

const SIZES = [10, 25, 50, 100]

const OPTIONS = SIZES.map(size => ({ value: size, label: size }))

export interface IPaginationProps extends IPaginationContainerProps {
  canChangePageSize?: boolean
  loading?: boolean
  showTotal?: boolean
  state?: {
    pageSize: number
    pageIndex: number
  }
  total?: number
  totalText?: string
  rowsPerPageText?: string
  onFirstPage?: () => void
  onLastPage?: () => void
  onNextPage?: () => void
  onPageIndexChange?: (pageIndex: number) => void
  onPageSizeChange?: (pageSize: number) => void
  onPreviousPage?: () => void
}

function Pagination({
  canChangePageSize = true,
  loading = false,
  showTotal = true,
  state: { pageSize, pageIndex } = { pageSize: 10, pageIndex: 0 },
  total = 0,
  totalText = 'results',
  rowsPerPageText = 'Rows per page',
  onPageIndexChange,
  onPageSizeChange,
  ...rest
}: IPaginationProps) {
  const from = useMemo(() => pageSize * pageIndex + 1, [pageSize, pageIndex])

  const to = useMemo(
    () => (from + pageSize > total ? total : from + pageSize - 1),
    [total, from, pageSize],
  )

  const pageCount = useMemo(
    () => Math.ceil(total / pageSize),
    [pageSize, total],
  )

  // TODO: convert any to actual type
  const handleChangeSize = useCallback(
    (value: any) => {
      const nextPageSize = Number(
        (value && 'value' in value && value.value) ?? 0,
      )
      onPageSizeChange?.(nextPageSize)
    },
    [onPageSizeChange],
  )

  const handlePageChange = useCallback(
    (page: { selected: number }) => {
      onPageIndexChange?.(page?.selected ?? 0)
    },
    [onPageIndexChange],
  )

  const value = useMemo(
    () => ({ value: pageSize, label: pageSize }),
    [pageSize],
  )

  if (!total && !loading) return null

  return (
    <PaginationContainer {...pick(rest)}>
      {showTotal && (
        <Text body bodyMedium nowrap>
          {`Show ${from} to ${to} of ${total} ${totalText}`}
        </Text>
      )}

      <Paginate
        breakLabel="…"
        forcePage={pageCount ? pageIndex : -1}
        marginPagesDisplayed={1}
        nextLabel={<IconChevronRight />}
        pageCount={pageCount}
        pageRangeDisplayed={2}
        previousLabel={<IconChevronLeft />}
        renderOnZeroPageCount={null}
        onPageChange={handlePageChange}
      />

      {!!(canChangePageSize && total) && (
        <Row center gap={3}>
          <Text body bodyMedium nowrap>
            {rowsPerPageText}
          </Text>
          <Select
            options={OPTIONS}
            value={value}
            width={80}
            withPortal
            onChange={handleChangeSize}
          />
        </Row>
      )}
    </PaginationContainer>
  )
}

export default Pagination
