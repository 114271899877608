import React from 'react'

import { pick } from '@styled-system/props'

import { IconX } from '@tabler/icons-react'
import { IconCommunities } from 'Features/ProfilePanel/Blocks/icons.styles'

import { Box } from 'Components/UI'

import colors from 'Theme/_v2/colors'

import * as Styled from './CommunityItem.styles'

export interface ICommunityItem extends Styled.IContainer {
  removable?: boolean
  text?: string
  entity?: any
  passClickThrough?: boolean
  renderAfter?: React.ReactNode
  renderBefore?: React.ReactNode
  onRemove?: (e: Styled.ITagMouseEvent) => void
  onClick?: (e: Styled.ITagMouseEvent) => void
}

function CommunityItem({
  colorKind,
  removable = false,
  renderAfter,
  renderBefore,
  small = false,
  passClickThrough = false,
  text,
  entity,
  onClick,
  onRemove,
  ...rest
}: ICommunityItem) {
  const handleOnClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      onClick?.({
        ...e,
        entity,
      } as Styled.ITagMouseEvent)
    },
    [entity, onClick],
  )

  const handleOnRemove = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onRemove?.({
        ...e,
        entity,
      } as Styled.ITagMouseEvent)
    },
    [entity, onRemove],
  )

  if (!text) return null

  return (
    <Styled.Container
      {...pick(rest)}
      colorKind={colorKind}
      passClickThrough={passClickThrough}
      small={small}
      onClick={handleOnClick}
    >
      {renderBefore}
      <Box
        backgroundColor={'#F0F0F0'}
        borderRadius={'100%'}
        display={'flex'}
        height={'12px'}
        justifyContent={'center'}
        marginTop={'2px'}
        overflow={'hidden'}
        width={'12px'}
      >
        {entity.photoUrl ? (
          <Styled.CommunityImage alt={text} src={entity.photoUrl} />
        ) : (
          <IconCommunities color={colors.icon.profile} />
        )}
      </Box>
      <Styled.CommunityName ellipsis tagLarge={!small} tagSmall={small}>
        {text}
      </Styled.CommunityName>
      {renderAfter}

      {removable && (
        <Styled.RemoveButton onClick={handleOnRemove}>
          <IconX />
        </Styled.RemoveButton>
      )}
    </Styled.Container>
  )
}

export default CommunityItem
