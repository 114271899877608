import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'

import removeUserSkillsMutation from 'GraphQL/Mutations/Admin/User/removeUserSkills.graphql'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import disconnectUsersFromTagsMutation from 'GraphQL/Mutations/Community/disconnectUsersFromTags.graphql'
import updateCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/updateCommunityUser.graphql'
import { getCommunityUserSkillsUpdater } from 'GraphQL/Updaters/GetCommunityUserSkills'
import { getCommunityUserTagsUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'
import Utils from 'Utils'
import validate from 'validate.js'

import differenceBy from 'lodash/differenceBy'
import map from 'lodash/map'
import noop from 'lodash/noop'

import {
  Button,
  Divider,
  IconButton,
  Loader,
  Modal,
  Row,
  Text,
} from 'Components/UI'

import { LINKEDIN_REGEX } from 'Constants/regex'

import { useMutation } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { EditCommunityUserFormField } from './fields'
import { GeneralTab, TagsTab } from './Tabs'
import { entitiesToValues, EntityValue, tagsByKind } from './utils'

enum Tab {
  GeneralInfo = 'General Info',
  Tags = 'Tags',
}

const DEFAULT_TABS: Tab[] = [Tab.GeneralInfo, Tab.Tags]

interface UpdateCommunityUserFormValues {
  [EditCommunityUserFormField.PhotoUrl]?: string
  [EditCommunityUserFormField.FirstName]?: string
  [EditCommunityUserFormField.LastName]?: string
  [EditCommunityUserFormField.Email]?: string
  [EditCommunityUserFormField.PhoneNumber]?: string
  [EditCommunityUserFormField.JobTitle]?: string
  [EditCommunityUserFormField.Organization]?: string
  [EditCommunityUserFormField.LinkedInUrl]?: string
  [EditCommunityUserFormField.TwitterUrl]?: string
  [EditCommunityUserFormField.FacebookUrl]?: string
  [EditCommunityUserFormField.InterestsHobbies]?: string
  [EditCommunityUserFormField.About]?: string
  [EditCommunityUserFormField.Skills]?: EntityValue[]
  [EditCommunityUserFormField.Event]?: EntityValue[]
  [EditCommunityUserFormField.Project]?: EntityValue[]
  [EditCommunityUserFormField.Role]?: EntityValue[]
  [EditCommunityUserFormField.Custom]?: EntityValue[]
  [EditCommunityUserFormField.Group]?: EntityValue[]
}

interface EditCommunityUserProps {
  communityUser?: MainSchema.CommunityUser | null
  communityId?: string | null
  isOpen?: boolean
  onClose?: (success: boolean) => void
}

function EditCommunityUser({
  communityUser,
  communityId,
  isOpen = false,
  onClose = noop,
}: EditCommunityUserProps) {
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(Tab.GeneralInfo)

  const [updateCommunityUser] = useMutation<
    Pick<MainSchema.Mutation, 'updateCommunityUser'>,
    MainSchema.MutationUpdateCommunityUserArgs
  >(updateCommunityUserMutation)
  const [connectUsersToTags] = useMutation<
    Pick<MainSchema.Mutation, 'connectUsersToTags'>,
    MainSchema.MutationConnectUsersToTagsArgs
  >(connectUsersToTagsMutation)
  const [connectUsersToSkills] = useMutation<
    Pick<MainSchema.Mutation, 'connectUsersToSkills'>,
    MainSchema.MutationConnectUsersToSkillsArgs
  >(connectUsersToSkillsMutation)
  const [removeUserSkills] = useMutation<
    Pick<MainSchema.Mutation, 'removeUserSkills'>,
    MainSchema.MutationRemoveUserSkillsArgs
  >(removeUserSkillsMutation)
  const [disconnectUsersFromTags] = useMutation<
    Pick<MainSchema.Mutation, 'disconnectUsersFromTags'>,
    MainSchema.MutationDisconnectUsersFromTagsArgs
  >(disconnectUsersFromTagsMutation)

  const initialSkills = useMemo(
    () =>
      entitiesToValues(
        communityUser?.communityUserSkills?.map(skill => skill.skill!) ?? [],
      ),
    [communityUser],
  )
  const initialTags = useMemo(
    () =>
      tagsByKind(
        entitiesToValues(
          communityUser?.communityUserTags?.map(tag => tag.tag!) ?? [],
        ),
      ),
    [communityUser],
  )

  const initialValues = useMemo(
    () => ({
      [EditCommunityUserFormField.Email]: communityUser?.email,
      [EditCommunityUserFormField.FirstName]: communityUser?.firstName,
      [EditCommunityUserFormField.LastName]: communityUser?.lastName,
      [EditCommunityUserFormField.PhotoUrl]: communityUser?.photoUrl,
      [EditCommunityUserFormField.LinkedInUrl]: communityUser?.linkedInUrl,
      [EditCommunityUserFormField.TwitterUrl]: communityUser?.twitterUrl,
      [EditCommunityUserFormField.FacebookUrl]: communityUser?.facebookUrl,
      [EditCommunityUserFormField.PhoneNumber]: communityUser?.phoneNumber,
      [EditCommunityUserFormField.InterestsHobbies]:
        communityUser?.interestsHobbies,
      [EditCommunityUserFormField.About]: communityUser?.about,
      [EditCommunityUserFormField.Skills]: initialSkills,
      [EditCommunityUserFormField.Custom]: initialTags.custom,
      [EditCommunityUserFormField.Project]: initialTags.projects,
      [EditCommunityUserFormField.Role]: initialTags.roles,
      [EditCommunityUserFormField.Event]: initialTags.events,
      [EditCommunityUserFormField.Group]: initialTags.groups,
    }),
    [communityUser, initialTags, initialSkills],
  )

  const formConstraints = useMemo(
    () => ({
      [EditCommunityUserFormField.PhotoUrl]: {
        type: 'string',
      },
      [EditCommunityUserFormField.FirstName]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.firsNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.firsNameTooLong')}`,
        },
      },
      [EditCommunityUserFormField.LastName]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.lastNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.lastNameTooLong')}`,
        },
      },
      [EditCommunityUserFormField.JobTitle]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.jobTooLong')}`,
        },
      },
      [EditCommunityUserFormField.LinkedInUrl]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_REGEX,
          message: `^${_('auth.shared.linkedinProfileUrl')}`,
        },
      },
      [EditCommunityUserFormField.Email]: {
        email: {
          email: true,
          message: `^${_('auth.shared.emailInvalid')}`,
        },
      },
      [EditCommunityUserFormField.Organization]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.organizationTooLong')}`,
        },
      },
      [EditCommunityUserFormField.InterestsHobbies]: {
        type: 'string',
      },
      [EditCommunityUserFormField.About]: {
        type: 'string',
      },
    }),
    [],
  )

  const handleFinish = useCallback(
    async (success: boolean) => {
      onClose(success)
    },
    [onClose],
  )

  const handleCancel = useCallback(async () => {
    await handleFinish(false)
  }, [handleFinish])

  const submit = useCallback(
    async (values: UpdateCommunityUserFormValues) => {
      if (!communityId || !communityUser) {
        return
      }

      setLoading(true)

      const tags = [
        ...(values[EditCommunityUserFormField.Event] ?? []),
        ...(values[EditCommunityUserFormField.Group] ?? []),
        ...(values[EditCommunityUserFormField.Project] ?? []),
        ...(values[EditCommunityUserFormField.Role] ?? []),
        ...(values[EditCommunityUserFormField.Custom] ?? []),
      ].map(tag => ({
        id: tag.value,
        name: tag.label,
        kind: tag.kind!,
      }))

      const skills = [...(values[EditCommunityUserFormField.Skills] ?? [])].map(
        skill => ({
          id: skill.value,
          name: skill.label,
        }),
      )

      const skillIds = map(values[EditCommunityUserFormField.Skills], 'id')
      const tagIds = map(tags, 'id')

      const removedSkills = differenceBy(
        initialSkills,
        values[EditCommunityUserFormField.Skills] ?? [],
        'id',
      )

      const removedTags = differenceBy(
        Object.values(initialTags).flat(),
        tags,
        'id',
      )

      try {
        await updateCommunityUser({
          variables: {
            id: communityUser.id,
            communityId,
            firstName: values[EditCommunityUserFormField.FirstName],
            lastName: values[EditCommunityUserFormField.LastName],
            photoUrl: values[EditCommunityUserFormField.PhotoUrl],
            email: values[EditCommunityUserFormField.Email],
            phoneNumber: values[EditCommunityUserFormField.PhoneNumber],
            // TODO: job title can't be removed currently
            jobTitle: values[EditCommunityUserFormField.JobTitle],
            // TODO: organization can't be removed currently
            organization: values[EditCommunityUserFormField.Organization],
            linkedInUrl: values[EditCommunityUserFormField.LinkedInUrl],
            twitterUrl: values[EditCommunityUserFormField.TwitterUrl],
            facebookUrl: values[EditCommunityUserFormField.FacebookUrl],
            about: values[EditCommunityUserFormField.About],
            interestsHobbies:
              values[EditCommunityUserFormField.InterestsHobbies],
          },
        })

        if (removedSkills?.length) {
          await removeUserSkills({
            variables: {
              communityUserId: communityUser.id,
              communityId,
              skillIds: map(removedSkills, 'id'),
            },
          })
        }

        if (removedTags?.length) {
          const usersFromTags: MainSchema.DisconnectUsersFromTagsInputType[] =
            []
          removedTags.forEach(tag => {
            usersFromTags.push({
              communityUserId: communityUser.communityUserId!,
              tagId: tag.value,
            })
          })

          await disconnectUsersFromTags({
            variables: {
              communityId,
              usersFromTags,
            },
          })
        }

        if (skillIds?.length) {
          const communityUsers = [
            {
              communityUserId: communityUser.communityUserId!,
              communityId,
            },
          ]

          const usersToSkills: MainSchema.ConnectUsersToSkillsInputType[] = []
          skillIds.forEach(skillId => {
            usersToSkills.push({
              skillId,
              communityUserId: communityUser.communityUserId!,
            })
          })

          await connectUsersToSkills({
            variables: {
              communityId,
              usersToSkills,
            },
            update: getCommunityUserSkillsUpdater({
              communityUsers,
              communityIds: [communityId],
              skills,
            }),
          })
        }

        if (tagIds?.length) {
          const usersToTags: MainSchema.ConnectUsersToTagsInputType[] = []
          tagIds.forEach(tagId => {
            usersToTags.push({
              tagId,
              communityUserId: communityUser.communityUserId!,
            })
          })

          const communityUsers = [
            {
              communityUserId: communityUser.communityUserId!,
              communityId,
            },
          ]

          await connectUsersToTags({
            variables: {
              communityId,
              usersToTags,
            },
            update: getCommunityUserTagsUpdater({
              communityIds: [communityId],
              communityUsers,
              communityUserTags: tags.map(
                tag =>
                  ({
                    communityUserId: communityUser.communityUserId!,
                    tagId: tag.id,
                    tag: {
                      ...tag,
                      __typename: 'Tag',
                    } as MainSchema.Tag,
                    __typename: 'CommunityUserTag',
                  }) as MainSchema.CommunityUserTag,
              ),
            }),
          })
        }

        toast.success({
          title: 'Community User',
          text: `Community user updated`,
        })

        await handleFinish(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: 'Server error',
          text: message,
        })
      } finally {
        setLoading(false)
      }
    },
    [
      communityId,
      connectUsersToSkills,
      connectUsersToTags,
      disconnectUsersFromTags,
      handleFinish,
      initialSkills,
      initialTags,
      removeUserSkills,
      updateCommunityUser,
      communityUser,
    ],
  )

  return (
    <Modal
      isOpen={isOpen}
      minWidth="640px"
      shouldCloseOnOverlayClick={false}
      title="Edit User Profile"
      onClose={handleCancel}
    >
      <Form<UpdateCommunityUserFormValues>
        initialValues={initialValues}
        render={({ handleSubmit, form }) => {
          const formErrors = Utils.Form.errors(
            form,
            EditCommunityUserFormField,
            {
              checkDirty: true,
            },
          )

          return (
            <>
              <Row fullWidth gap={5}>
                {DEFAULT_TABS.map((item, index) => (
                  <Row center gap={3} key={item}>
                    <IconButton
                      gap={3}
                      outline={item !== tab}
                      onClick={() => setTab(item)}
                    >
                      {index + 1}
                    </IconButton>
                    <Text header header3>
                      {item}
                    </Text>
                  </Row>
                ))}
              </Row>

              <Divider my={5} />

              {tab === Tab.GeneralInfo && <GeneralTab />}

              {tab === Tab.Tags && communityId && (
                <TagsTab communityId={communityId} />
              )}

              {Object.values(formErrors).length > 0 && (
                <Row center justifyEnd mt={4}>
                  <Text actionMedium error>
                    * Please fill all required fields!
                  </Text>
                </Row>
              )}

              <Divider my={5} />

              <Row center mt={5} spaceBetween>
                {loading ? (
                  <Row fullWidth justifyCenter>
                    <Loader />
                  </Row>
                ) : (
                  <>
                    <Button
                      secondary
                      small
                      onClick={e => {
                        e.preventDefault()
                        handleCancel()
                      }}
                    >
                      {_('general.cancel')}
                    </Button>

                    <Button small onClick={handleSubmit}>
                      {_('general.save')}
                    </Button>
                  </>
                )}
              </Row>
            </>
          )
        }}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

export default EditCommunityUser
