import React, { PropsWithChildren } from 'react'
import { LoadOptions as ReactSelectAsyncPaginateLoadOptions } from 'react-select-async-paginate'

import { Column, Loader, Row, Select } from 'Components/UI'
import { ReactSelectAsyncLoadOptions } from 'Components/UI/Form/Select/Select'

import * as Styled from './InlineDropdownCreate.styles'

export interface IInlineDropdownCreateProps extends PropsWithChildren {
  disabled?: boolean
  isLoading?: boolean
  placeholder?: string
  isCreatable?: boolean
  renderMultiValue?: (props: any) => React.ReactNode
  isValidNewOption?: (inputValue: string) => boolean
  debouncedLoadOptions?:
    | ReactSelectAsyncPaginateLoadOptions<any, any, any>
    | ReactSelectAsyncLoadOptions<any, any>
  onChange?: (value: any) => void
  onBlur?: () => void
  onCancel?: () => void
  onSave?: () => Promise<void>
}

function InlineDropdownCreate({
  disabled,
  isLoading,
  placeholder,
  isCreatable,
  renderMultiValue,
  isValidNewOption,
  debouncedLoadOptions,
  onBlur,
  onChange,
  onCancel,
  onSave,
}: IInlineDropdownCreateProps) {
  return (
    <Styled.Container>
      <Row fullWidth gap={4} mb={1}>
        <Column>
          {onSave && (
            <Styled.ActionButton
              disabled={isLoading}
              secondary
              onClick={onCancel}
            >
              Cancel
            </Styled.ActionButton>
          )}
        </Column>
        <Column>
          {onCancel && (
            <Styled.ActionButton
              disabled={disabled || isLoading}
              onClick={onSave}
            >
              {isLoading ? <Loader /> : 'Save'}
            </Styled.ActionButton>
          )}
        </Column>
      </Row>
      <Row fullWidth mb={2}>
        <Column fullWidth>
          <Select
            async
            components={{ MultiValue: renderMultiValue }}
            creatable={isCreatable}
            isMulti
            isValidNewOption={isValidNewOption}
            loadOptions={debouncedLoadOptions}
            mt={2}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
          />
        </Column>
      </Row>
    </Styled.Container>
  )
}

export default InlineDropdownCreate
