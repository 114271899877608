import React, { useCallback, useMemo } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useApolloClient, useMutation } from '@apollo/client'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import useRegraphLoaders from 'Features/Graph/useRegraphLoaders'
import { IGraphSkillNode, IGraphTagNode } from 'Features/GraphNodes/NodeTypes'
import { FormApi } from 'final-form'
import createSkillsMutation from 'GraphQL/Mutations/Skill/createSkills.graphql'
import createTagsMutation from 'GraphQL/Mutations/Tag/createTags.graphql'
import listSkillsQuery from 'GraphQL/Queries/listSkills.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'
import { communityToOption, ICommunityOption } from 'Utils/Options'
import { validate } from 'validate.js'

import { CommunityField } from 'Components/Blocks/Forms/Fields'
import SkillTagSelectField, {
  SkillTagOption,
} from 'Components/Blocks/Forms/Fields/SkillTagSelectField/SkillTagSelectField'
import { Column, Modal } from 'Components/UI'

import { SkillKind, SkillTagKind } from 'Constants/ids'
import { TagKind } from 'Constants/mainGraphQL'

import { useCommunityContext, useModal } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

export enum CreateSkillTagFormField {
  Communities = 'community',
  Name = 'name',
}

export interface CreateSkillTagFormValues {
  [CreateSkillTagFormField.Communities]: ICommunityOption[]
  [CreateSkillTagFormField.Name]: SkillTagOption
}

export interface CreateSkillTagModalProps {
  initialValues?: Partial<CreateSkillTagFormValues>
  isOpen: boolean
  onClose: () => void
  skillTagType: SkillTagKind | null
}

const CreateSkillTagModal: React.FC<CreateSkillTagModalProps> = ({
  initialValues,
  isOpen,
  onClose,
  skillTagType,
}) => {
  const t = useScopedI18n('components.blocks.modals.createSkillTagModal')
  const { community } = useCommunityContext()
  const [modalProps] = useModal({ onClose })
  const { isFeatureEnabled } = useFeatureFlag(['regraph'])
  const { handleSpawnSkills, handleSpawnTags } = useRegraphLoaders()
  const { focusNodes } = useRegraphHandlers()
  const client = useApolloClient()

  const [createTags] = useMutation<
    Pick<MainSchema.Mutation, 'createTags'>,
    MainSchema.MutationCreateTagsArgs
  >(createTagsMutation)
  const [createSkills] = useMutation<
    Pick<MainSchema.Mutation, 'createSkills'>,
    MainSchema.MutationCreateSkillsArgs
  >(createSkillsMutation)

  const prepopulatedInitialValues = useMemo(() => {
    if (!community) return initialValues
    return {
      ...initialValues,
      [CreateSkillTagFormField.Communities]: [communityToOption(community)],
    }
  }, [community, initialValues])

  const transformedSkillTagType = useMemo(() => {
    if (!skillTagType) {
      return ''
    }
    if (skillTagType === TagKind.Custom) {
      return 'Custom Tag'
    }
    return skillTagType.replace(/^./, char => char.toUpperCase())
  }, [skillTagType])

  const isTagType = useMemo(
    () => skillTagType !== SkillKind.Skill,
    [skillTagType],
  )

  const matchingSkillTag = useCallback(
    async (value: SkillTagOption, communities: ICommunityOption[]) => {
      if (!value) return false
      if (isTagType && !communities[0]?.name) {
        return {
          [CreateSkillTagFormField.Name]: t('validationCommunity'),
        }
      }

      const result = await client.query({
        query: isTagType ? listTagsQuery : listSkillsQuery,
        variables: {
          communityIds: communities.map(
            (community: ICommunityOption) => community.id,
          ),
          kind: isTagType ? skillTagType : undefined,
          search: value?.label?.trim(),
          limit: 25,
        },
      })
      const nameMatches = isTagType
        ? result?.data.listTags.rows.map((tag: MainSchema.Tag) => ({
            name: tag?.name,
            communityName: tag?.community.name,
          }))
        : result?.data.listSkills.rows.map((skill: MainSchema.Skill) => ({
            name: skill?.name,
            communityName: 'NetworkOS',
          }))

      const foundMatch = nameMatches.find(
        (match: any) => match.name === value?.label?.trim(),
      )

      if (foundMatch) {
        return {
          [CreateSkillTagFormField.Name]: [
            t('error', {
              nodeName: foundMatch.name,
              nodeType: transformedSkillTagType,
              communityName: foundMatch.communityName,
            }),
          ],
        }
      }
      return {}
    },
    [client, isTagType, skillTagType, t, transformedSkillTagType],
  )

  const formConstraints = useMemo(() => {
    const constraints = {
      [CreateSkillTagFormField.Name]: {
        presence: {
          allowEmpty: false,
          message: `^${t('validationName', { nodeType: transformedSkillTagType })}`,
        },
      },
    }
    if (!isTagType) {
      return constraints
    }
    return {
      ...constraints,
      [CreateSkillTagFormField.Communities]: {
        presence: {
          allowEmpty: false,
          message: `^${t('validationCommunity')}`,
        },
      },
    }
  }, [isTagType, t, transformedSkillTagType])

  const customValidate = (values: CreateSkillTagFormValues) => {
    const errors = validate(values, formConstraints)
    if (
      errors &&
      errors[CreateSkillTagFormField.Communities]?.includes(
        t('validationCommunity'),
      )
    ) {
      return {
        ...errors,
        [CreateSkillTagFormField.Name]: [t('validationCommunity')],
      }
    }
    return errors && Object.keys(errors).length
      ? errors
      : matchingSkillTag(
          values[CreateSkillTagFormField.Name],
          values[CreateSkillTagFormField.Communities] || [community],
        )
  }

  const processSkillKind = useCallback(
    async (option: SkillTagOption) => {
      let createdSkills: MainSchema.Skill[] = []

      const skillToCreate = {
        name: option.label?.trim(),
      } as MainSchema.CreateSkillInput

      if (skillToCreate.name) {
        const createSkillsResponse = await createSkills({
          variables: { skills: [skillToCreate] },
        })
        createdSkills = createSkillsResponse?.data?.createSkills || []
      }

      createdSkills.forEach(skill => {
        if (isFeatureEnabled('regraph')) {
          handleSpawnSkills([skill as IGraphSkillNode])
          focusNodes([skill?.id])
        } else {
          EventBus.trigger(EventBus.actions.graph.addSkillTags, {
            id: skill.id,
            name: skill.name,
            kind: SkillKind.Skill,
            communityUserId: '',
          })
        }
      })
    },
    [createSkills, focusNodes, handleSpawnSkills, isFeatureEnabled],
  )

  const processTagKind = useCallback(
    async (communities: ICommunityOption[], option: SkillTagOption) => {
      if (!communities || communities.length < 1) {
        throw new Error('Community not found')
      }

      let createdTags: MainSchema.Tag[] = []

      const tagsToCreate = communities.map(community => ({
        name: option.label?.trim(),
        communityId: community.id,
        kind: skillTagType,
      })) as MainSchema.CreateTagInput[]

      if (tagsToCreate.length > 0) {
        const createTagsResponse = await createTags({
          variables: { tags: tagsToCreate },
        })
        createdTags = createTagsResponse?.data?.createTags || []
      }

      createdTags.forEach(tag => {
        if (isFeatureEnabled('regraph')) {
          handleSpawnTags([tag as IGraphTagNode])
          focusNodes([tag?.id])
        } else {
          EventBus.trigger(EventBus.actions.graph.addSkillTags, {
            id: tag.id,
            name: tag.name,
            kind: tag.kind,
            communityUserId: '',
          })
        }
      })
    },
    [createTags, focusNodes, handleSpawnTags, isFeatureEnabled, skillTagType],
  )

  const onSubmit = useCallback(
    async (
      values: CreateSkillTagFormValues,
      form: FormApi<
        CreateSkillTagFormValues,
        Partial<CreateSkillTagFormValues>
      >,
    ) => {
      const options = values[CreateSkillTagFormField.Name]

      try {
        if (isTagType) {
          const communities = values[CreateSkillTagFormField.Communities]
          await processTagKind(communities, options)
        } else {
          await processSkillKind(options)
        }
        form.restart(prepopulatedInitialValues)

        onClose?.()

        toast.success({
          title: t('toast.title', {
            nodeType: transformedSkillTagType.toLowerCase(),
          }),
          text: t('toast.success', { nodeName: values.name.label }),
        })
      } catch {
        toast.error({
          title: t('title', {
            nodeType: transformedSkillTagType.toLowerCase(),
          }),
          text: t('toast.error'),
        })
      }
    },
    [
      isTagType,
      onClose,
      prepopulatedInitialValues,
      processSkillKind,
      processTagKind,
      t,
      transformedSkillTagType,
    ],
  )

  const handleClose = useCallback(
    async (
      form: FormApi<
        CreateSkillTagFormValues,
        Partial<CreateSkillTagFormValues>
      >,
    ) => {
      form.restart(prepopulatedInitialValues)
      onClose?.()
    },
    [onClose, prepopulatedInitialValues],
  )

  const renderForm = useCallback(
    ({ form, handleSubmit }: FormRenderProps<CreateSkillTagFormValues>) => {
      const formState = form.getState()

      return (
        <Modal
          {...modalProps}
          cancelText={t('cancel')}
          confirmDisabled={
            formState.hasValidationErrors || formState.submitting
          }
          confirmText={t('create')}
          isOpen={isOpen}
          title={t('title', { nodeType: transformedSkillTagType })}
          width="456px"
          onClose={() => handleClose(form)}
          onConfirm={handleSubmit}
        >
          {isTagType ? (
            <>
              <Column>
                {t('subtitle', {
                  nodeType: transformedSkillTagType.toLowerCase(),
                })}
              </Column>

              <Column pt={4}>
                <CommunityField
                  isRequired
                  label={t('communitiesLabel')}
                  name={CreateSkillTagFormField.Communities}
                />
              </Column>
            </>
          ) : null}

          <Column pb={8} pt={4}>
            <SkillTagSelectField
              isRequired
              label={t('nodeLabel', { nodeType: transformedSkillTagType })}
              name={CreateSkillTagFormField.Name}
              placeholder={t('nodeLabel', {
                nodeType: transformedSkillTagType,
              })}
            />
          </Column>
        </Modal>
      )
    },
    [handleClose, isOpen, isTagType, modalProps, t, transformedSkillTagType],
  )

  return (
    <Form<CreateSkillTagFormValues>
      initialValues={prepopulatedInitialValues}
      render={renderForm}
      validate={customValidate}
      validateOnBlur
      onSubmit={onSubmit}
    />
  )
}

export default CreateSkillTagModal
