import React from 'react'

import Utils from 'Utils'

import { Link } from 'Components/UI'

import { COMMUNITY_MANAGEMENT } from 'Router/routes'

import { Empty, EmptyWrapper } from './styles'

interface Props {
  slug: string | undefined
}

const PeopleEmptyState: React.FC<Props> = ({ slug }) => (
  <EmptyWrapper>
    <Empty />
    <p>Your network is waiting to grow! Start by adding some connections.</p>
    <Link
      to={Utils.URL.generatePath(COMMUNITY_MANAGEMENT, {
        slug,
      })}
    >
      Connect or Upload Data
    </Link>
  </EmptyWrapper>
)

export default PeopleEmptyState
