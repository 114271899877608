import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const NameHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  padding-top: ${themeGet('space.5')}px;
  padding-bottom: ${themeGet('space.3')}px;
  padding-left: ${themeGet('space.8')}px;
  padding-right: ${themeGet('space.8')}px;

  ${down('lg')} {
    height: auto;
    width: 100%;
    padding: 10px;
  }
`
