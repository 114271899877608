import { ApolloCache, MutationUpdaterFunction } from '@apollo/client'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'

import forEach from 'lodash/forEach'

interface ICommunityUser {
  communityUserId: string
  communityId: string
}

type IGetCommunityUserTagsUpdater = MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'connectUsersToTags'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
>

export function getCommunityUserTagsUpdater({
  communityIds,
  communityUsers,
  communityUserTags,
}: {
  communityIds: string[]
  communityUsers: ICommunityUser[]
  communityUserTags: MainSchema.CommunityUserTag[]
}): IGetCommunityUserTagsUpdater {
  return (cache, { data }) => {
    if (!data?.connectUsersToTags) return

    forEach(communityUsers, async communityUser => {
      const { communityUserId } = communityUser

      const queryGetCommunityUser = {
        query: getCommunityUserQuery,
        variables: {
          communityUserId,
          communityIds,
        },
      }

      const getCommunityUserData = cache.readQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >(queryGetCommunityUser)

      if (!getCommunityUserData?.getCommunityUser) return

      const combinedTags: MainSchema.CommunityUserTag[] = [
        ...(getCommunityUserData.getCommunityUser.communityUserTags || []),
        ...communityUserTags,
      ]

      const tagsMap = new Map<string, MainSchema.CommunityUserTag>()

      for (const tag of combinedTags) {
        if (tag.tagId && !tagsMap.has(tag.tagId)) {
          tagsMap.set(tag.tagId, tag)
        }
      }

      const mergedTags: MainSchema.CommunityUserTag[] = Array.from(
        tagsMap.values(),
      )

      cache.writeQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        ...queryGetCommunityUser,
        data: {
          ...getCommunityUserData,
          getCommunityUser: {
            ...getCommunityUserData.getCommunityUser,
            communityUserTags: mergedTags,
          },
        },
      })
    })
  }
}

export function getCommunityUserTagsRemoveUpdater({
  communityUserId,
  communityIds,
  tagIds,
}: {
  communityUserId: string
  communityIds: string[]
  tagIds: any[]
}): MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'disconnectUsersFromTags'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
> {
  return (cache, { data }) => {
    if (!data?.disconnectUsersFromTags) return

    const queryGetCommunityUser = {
      query: getCommunityUserQuery,
      variables: {
        communityUserId,
        communityIds,
      },
    }

    const getCommunityUserData = cache.readQuery<
      Pick<MainSchema.Query, 'getCommunityUser'>,
      MainSchema.QueryGetCommunityUserArgs
    >(queryGetCommunityUser)

    const filteredTags =
      getCommunityUserData?.getCommunityUser?.communityUserTags?.filter(
        tag => !tagIds.includes(tag.tagId),
      )

    if (
      getCommunityUserData?.getCommunityUser &&
      communityUserId &&
      communityIds?.length
    ) {
      cache.writeQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        ...queryGetCommunityUser,
        data: {
          ...getCommunityUserData,
          getCommunityUser: {
            ...getCommunityUserData.getCommunityUser,
            communityUserTags: filteredTags,
          },
        },
      })
    }
  }
}
