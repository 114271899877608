import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

export const CloseContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-inline: ${themeGet('space.3')}px;
  padding-bottom: ${themeGet('space.3')}px;
  padding-left: ${themeGet('space.8')}px;
  padding-right: ${themeGet('space.8')}px;
`

export const ScrollableContainer = styled.div`
  height: 100%;
  margin-right: -12px;
  padding-right: 12px;
  overflow: scroll;

  ${customScrollbar}
`
