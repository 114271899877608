import { ApolloCache } from '@apollo/client'
import communityUserDirectConnectionsQuery from 'GraphQL/Queries/Community/communityUserDirectConnections.graphql'

export function updateCommunityUserDirectConnectionsUpdater(
  communityId: string,
  communityUserId: string,
  store: ApolloCache<
    | Pick<MainSchema.Mutation, 'createCommunityUser'>
    | Pick<MainSchema.Mutation, 'communityConnectUsers'>
  >,
  communityUser?: MainSchema.CommunityUser,
) {
  const query = {
    query: communityUserDirectConnectionsQuery,
    variables: {
      communityId,
      communityUserId,
    },
  }
  const queryData = store.readQuery<
    Pick<MainSchema.Query, 'communityUserDirectConnections'>,
    MainSchema.QueryCommunityUserDirectConnectionsArgs
  >(query)
  if (queryData && communityUser) {
    store.writeQuery<
      Pick<MainSchema.Query, 'communityUserDirectConnections'>,
      MainSchema.QueryCommunityUserDirectConnectionsArgs
    >({
      ...query,
      data: {
        communityUserDirectConnections: [
          ...queryData.communityUserDirectConnections,
          communityUser,
        ],
      },
    })
  }
}
