import React, { useCallback, useMemo } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import { IconX } from '@tabler/icons-react'
import { FormApi } from 'final-form'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import createSkillsMutation from 'GraphQL/Mutations/Skill/createSkills.graphql'
import createTagsMutation from 'GraphQL/Mutations/Tag/createTags.graphql'
import { getCommunityUserSkillsUpdater } from 'GraphQL/Updaters/GetCommunityUserSkills'
import { getCommunityUserTagsUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'
import { validate } from 'validate.js'

import SkillTagKindSelectField, {
  SkillTagKindOption,
  useSkillTagKindOptions,
} from 'Components/Blocks/Forms/Fields/SkillTagKindSelectField/SkillTagKindSelectField'
import SkillTagSelectField, {
  SkillTagOption,
} from 'Components/Blocks/Forms/Fields/SkillTagSelectField/SkillTagSelectField'
import UsersField from 'Components/Blocks/Forms/Fields/UsersField/UsersField'
import { Button, Column, Divider, Loader, Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { NODE_KIND } from 'Constants/graph'
import { SkillKind } from 'Constants/ids'
import { TagKind } from 'Constants/mainGraphQL'

import { useAppContext, useCommunityContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import * as Styled from './styles'

export enum AddSkillTagFormField {
  Users = 'users',
  Name = 'name',
  Kind = 'kind',
}

export interface AddSkillTagFormValues {
  [AddSkillTagFormField.Users]: MainSchema.CommunityUser[]
  [AddSkillTagFormField.Name]: SkillTagOption
  [AddSkillTagFormField.Kind]: SkillTagKindOption
}

export interface AddSkillTagPanelProps {
  initialValues?: Partial<AddSkillTagFormValues>
  onClose?: () => void
}

const AddSkillTagPanel: React.FC<AddSkillTagPanelProps> = props => {
  const t = useScopedI18n(
    'components.blocks.graph.quickActions.addSkillTagPanel',
  )
  const { community } = useCommunityContext()
  const { me } = useAppContext()

  const [connectUsersToTags] = useMutation<
    Pick<MainSchema.Mutation, 'connectUsersToTags'>,
    MainSchema.MutationConnectUsersToTagsArgs
  >(connectUsersToTagsMutation)
  const [connectUsersToSkills] = useMutation<
    Pick<MainSchema.Mutation, 'connectUsersToSkills'>,
    MainSchema.MutationConnectUsersToSkillsArgs
  >(connectUsersToSkillsMutation)
  const [createTags] = useMutation<
    Pick<MainSchema.Mutation, 'createTags'>,
    MainSchema.MutationCreateTagsArgs
  >(createTagsMutation)
  const [createSkills] = useMutation<
    Pick<MainSchema.Mutation, 'createSkills'>,
    MainSchema.MutationCreateSkillsArgs
  >(createSkillsMutation)
  // TODO: this is a temporary solution until we can refactor the select field to only return a value instead of the entire option
  const skillTagKindOptions = useSkillTagKindOptions()

  const formConstraints = useMemo(() => {
    return {
      [AddSkillTagFormField.Name]: {
        presence: {
          allowEmpty: false,
          message: `^${t('name.validation.required')}`,
        },
      },
      [AddSkillTagFormField.Kind]: {
        presence: {
          allowEmpty: false,
          message: `^${t('kind.validation.required')}`,
        },
      },
    }
  }, [t])

  const processSkillKind = useCallback(
    async (
      users: MainSchema.CommunityUser[],
      options: SkillTagOption,
      kindOption: SkillTagKindOption,
    ) => {
      if (!community?.id) {
        throw new Error('Community not found')
      }

      if (!me) {
        throw new Error('User not found')
      }

      const selectedSkills: any[] = [
        {
          id: options.value,
          name: options.label,
          kind: kindOption.value,
        },
      ]

      let createdSkills: MainSchema.Skill[] = []

      const communityUsers = users.map(user => ({
        communityUserId: user.communityUserId!,
        communityId: community.id,
      }))

      const skillsToCreate = selectedSkills
        .filter(tag => !tag.id)
        .map(tag => ({
          name: tag?.name?.trim(),
        })) as MainSchema.CreateSkillInput[]

      const existingSkills = selectedSkills
        .filter(skill => skill.id)
        .map(skill => ({
          id: skill?.id,
          name: skill?.name,
          kind: skill?.kind,
        })) as MainSchema.Skill[]

      if (skillsToCreate.length > 0) {
        const createTagsResponse = await createSkills({
          variables: { skills: skillsToCreate },
        })
        createdSkills = createTagsResponse?.data?.createSkills || []
      }

      const combinedSkills = [...existingSkills, ...createdSkills]

      const usersToSkills: MainSchema.ConnectUsersToSkillsInputType[] = []

      users.forEach(user => {
        combinedSkills.forEach(skill => {
          usersToSkills.push({
            skillId: skill.id!,
            communityUserId: user.communityUserId!,
          })
        })
      })

      await connectUsersToSkills({
        variables: {
          usersToSkills,
          communityId: community.id,
        },
        update: getCommunityUserSkillsUpdater({
          communityIds: [community?.id],
          communityUsers,
          skills: combinedSkills,
        }),
      })

      users.forEach(user => {
        combinedSkills.forEach(skill => {
          // Add tag to graph
          EventBus.trigger(EventBus.actions.graph.addSkillTags, {
            id: skill.id,
            name: skill.name,
            kind: NODE_KIND.skill,
            communityUserId: user?.communityUserId,
          })

          // Connect tag to user on graph
          EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
            fromId: user?.communityUserId,
            toId: skill.id,
            kind: NODE_KIND.skill,
          })
        })
      })
    },
    [community?.id, connectUsersToSkills, createSkills, me],
  )

  const processTagKind = useCallback(
    async (
      users: MainSchema.CommunityUser[],
      options: SkillTagOption,
      kindOption: SkillTagKindOption,
    ) => {
      if (!community?.id) {
        throw new Error('Community not found')
      }

      if (!me) {
        throw new Error('User not found')
      }

      const selectedTags: any[] = [
        {
          id: options.value,
          name: options.label,
          kind: kindOption.value,
        },
      ]

      let createdTags: MainSchema.Tag[] = []

      const communityUsers = users.map(user => ({
        communityUserId: user.communityUserId!,
        communityId: community.id,
      }))

      const tagsToCreate = selectedTags
        .filter(tag => !tag.id)
        .map(tag => ({
          name: tag?.name?.trim(),
          communityId: community?.id,
          kind: tag.kind,
        })) as MainSchema.CreateTagInput[]

      const existingTags = selectedTags
        .filter(tag => tag.id)
        .map(tag => ({
          id: tag?.id,
          name: tag?.name,
          kind: tag?.kind,
        })) as MainSchema.Tag[]

      if (tagsToCreate.length > 0) {
        const createTagsResponse = await createTags({
          variables: { tags: tagsToCreate },
        })
        createdTags = createTagsResponse?.data?.createTags || []
      }

      // Combine the existing tags with the newly created tags and connect them to the user
      const combinedTags = [...existingTags, ...createdTags]

      // Map to our graphql input type
      const usersToTags: MainSchema.ConnectUsersToTagsInputType[] = []

      users.forEach(user => {
        combinedTags.forEach(tag => {
          usersToTags.push({
            tagId: tag.id!,
            communityUserId: user.communityUserId!,
          })
        })
      })

      // Connect the tags to the user, and update the cache
      await connectUsersToTags({
        variables: {
          communityId: community?.id,
          usersToTags,
        },
        update: getCommunityUserTagsUpdater({
          communityIds: [community?.id],
          communityUsers,
          communityUserTags: combinedTags.map(tag => ({
            tagId: tag.id!,
          })),
        }),
      })

      // Add the tags to the graph and create the edges
      users.forEach(user => {
        combinedTags.forEach(tag => {
          // Add tag to graph
          EventBus.trigger(EventBus.actions.graph.addSkillTags, {
            id: tag.id,
            name: tag.name,
            kind: tag.kind,
            communityUserId: user?.communityUserId,
          })

          // Connect tag to user on graph
          EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
            fromId: user?.communityUserId,
            toId: tag.id,
            kind: tag.kind,
          })
        })
      })
    },
    [community?.id, connectUsersToTags, createTags, me],
  )

  // TODO: This should be refactored
  const onSubmit = useCallback(
    async (
      values: AddSkillTagFormValues,
      form: FormApi<AddSkillTagFormValues, Partial<AddSkillTagFormValues>>,
    ) => {
      const users = values[AddSkillTagFormField.Users]
      const options = values[AddSkillTagFormField.Name]
      const kindOptions = values[AddSkillTagFormField.Kind]

      try {
        switch (kindOptions.value) {
          case SkillKind.Skill:
          case SkillKind.NeedsSkill:
            await processSkillKind(users, options, kindOptions)
            break
          case TagKind.Custom:
          case TagKind.Event:
          case TagKind.Group:
          case TagKind.Project:
          case TagKind.Role:
            await processTagKind(users, options, kindOptions)
            break
          default:
            break
        }
        form.restart(props.initialValues)

        props.onClose?.()

        toast.success({
          title: t('toast.title'),
          text: t('toast.success'),
        })
      } catch (error) {
        let message = t('errors.generic')

        if (error instanceof Error && error.message) {
          message = t(`errors.${error.message}`, { defaultValue: message })
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      }
    },
    [props, t, processSkillKind, processTagKind],
  )

  const handleClose = useCallback(async () => {
    props.onClose?.()
  }, [props])

  const renderForm = useCallback(
    ({ form, handleSubmit }: FormRenderProps<AddSkillTagFormValues>) => {
      const formState = form.getState()

      form.subscribe(
        formState => {
          if (formState?.active === AddSkillTagFormField.Name) {
            const nameValue = formState.values[AddSkillTagFormField.Name]
            const kindValue = skillTagKindOptions.find(
              skillTagKindOption =>
                skillTagKindOption.value === nameValue?.kind,
            )

            if (kindValue) {
              form.getFieldState(AddSkillTagFormField.Kind)?.change(kindValue)
            }
          }
        },
        {
          active: true,
          values: true,
        },
      )

      return (
        <Styled.Container>
          <Row center px={4} py={3} spaceBetween>
            <Text
              color={colors.text.primary}
              fontWeight={600}
              variant="body-xs"
            >
              {t('heading')}
            </Text>

            <Styled.CloseButton title={t('close')} onClick={handleClose}>
              <IconX display="block" size={16} />
            </Styled.CloseButton>
          </Row>

          <Divider />

          <Row px={4} py={4}>
            <UsersField name={AddSkillTagFormField.Users} />
          </Row>

          <Divider />

          <Column pt={4} px={4}>
            <SkillTagSelectField
              isRequired
              label={t('name.label')}
              name={AddSkillTagFormField.Name}
              placeholder={t('name.placeholder')}
            />
          </Column>

          <Column pt={4} px={4}>
            <SkillTagKindSelectField
              isClearable
              isRequired
              label={t('kind.label')}
              name={AddSkillTagFormField.Kind}
              placeholder={t('kind.placeholder')}
            />
          </Column>

          <Row justifyContent="flex-end" px={4} py={4}>
            <Row gap={3}>
              <Button minWidth="80px" secondary onClick={handleClose}>
                {t('cancel')}
              </Button>

              <Button
                disabled={formState.hasValidationErrors || formState.submitting}
                minWidth="80px"
                primary
                onClick={handleSubmit}
              >
                {formState.submitting && <Loader />}
                {t('submit')}
              </Button>
            </Row>
          </Row>
        </Styled.Container>
      )
    },
    [t, handleClose, skillTagKindOptions],
  )

  return (
    <Form<AddSkillTagFormValues>
      initialValues={props.initialValues}
      render={renderForm}
      validate={values => validate(values, formConstraints)}
      onSubmit={onSubmit}
    />
  )
}

export default AddSkillTagPanel
