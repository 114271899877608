import React from 'react'

import disconnectUsersFromSkillsMutation from 'Features/ProfilePanel/Mutations/disconnectUsersFromSkills.graphql'
import disconnectUsersFromTagsMutation from 'Features/ProfilePanel/Mutations/disconnectUsersFromTags.graphql'
import { getCommunityUserSkillRemoveUpdater } from 'Features/ProfilePanel/Updaters/GetCommunityUserSkillsUpdater'
import { getCommunityUserTagsRemoveUpdater } from 'Features/ProfilePanel/Updaters/GetCommunityUserTagsUpdater'

import { Column } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'
import { TAG_BLOCK_KIND } from 'Constants/tags'

import { useAppContext, useCommunityContext } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import Communities from './Communities'
import ContactBox from './Contacts'
import EducationHistories from './EducationHistories'
import Skills from './Skills'
import TagBlock from './Tags'
import WorkHistories from './WorkHistories'

export interface IOverviewTabProps {
  communityUser: MainSchema.CommunityUser
  showCreate?: boolean
}

function OverviewTab({ communityUser, showCreate = true }: IOverviewTabProps) {
  const { community } = useCommunityContext()
  const { me } = useAppContext()
  const s = useScopedI18n('community')

  const [disconnectUsersFromSkills] = useMutation<
    Pick<MainSchema.Mutation, 'disconnectUsersFromSkills'>,
    MainSchema.MutationDisconnectUsersFromSkillsArgs
  >(disconnectUsersFromSkillsMutation)

  const [disconnectUsersFromTags] = useMutation<
    Pick<MainSchema.Mutation, 'disconnectUsersFromTags'>,
    MainSchema.MutationDisconnectUsersFromTagsArgs
  >(disconnectUsersFromTagsMutation)

  const getCommunityForCommunityUser = React.useCallback(
    (communityUserId: string) => {
      const matchingCommunityUser = communityUser.communityUsers?.find(
        user => communityUserId === user.communityUserId,
      )
      return communityUser.communities?.find(
        community => matchingCommunityUser?.communityId === community.id,
      )
    },
    [communityUser.communityUsers, communityUser.communities],
  )

  const skills = React.useMemo(
    () =>
      communityUser.communityUserSkills?.map(
        e =>
          ({
            ...e,
            community: getCommunityForCommunityUser(e.communityUserId || ''),
          }) as MainSchema.CommunityUserSkill,
      ),
    [communityUser.communityUserSkills, getCommunityForCommunityUser],
  )

  const projects = React.useMemo(
    () =>
      communityUser.communityUserTags
        ?.map(
          e =>
            ({
              communityUserId: e.communityUserId,
              tagId: e.tagId,
              tag: e.tag,
              community: getCommunityForCommunityUser(e.communityUserId || ''),
            }) as MainSchema.CommunityUserTag,
        )
        ?.filter(
          (t: MainSchema.CommunityUserTag) =>
            t?.tag?.kind === SEARCH_TYPES.project,
        ) ?? [],
    [communityUser.communityUserTags, getCommunityForCommunityUser],
  )

  const events = React.useMemo(
    () =>
      communityUser.communityUserTags
        ?.map(
          e =>
            ({
              communityUserId: e.communityUserId,
              tagId: e.tagId,
              tag: e.tag,
              community: getCommunityForCommunityUser(e.communityUserId || ''),
            }) as MainSchema.CommunityUserTag,
        )
        ?.filter(
          (t: MainSchema.CommunityUserTag) =>
            t?.tag?.kind === SEARCH_TYPES.event,
        ) ?? [],
    [communityUser.communityUserTags, getCommunityForCommunityUser],
  )

  const customTags = React.useMemo(
    () =>
      communityUser.communityUserTags
        ?.map(
          e =>
            ({
              communityUserId: e.communityUserId,
              tagId: e.tagId,
              tag: e.tag,
              community: getCommunityForCommunityUser(e.communityUserId || ''),
            }) as MainSchema.CommunityUserTag,
        )
        .filter(
          (t: MainSchema.CommunityUserTag) =>
            t?.tag?.kind === SEARCH_TYPES.custom,
        ) ?? [],
    [communityUser.communityUserTags, getCommunityForCommunityUser],
  )

  const groups = React.useMemo(
    () =>
      communityUser.communityUserTags
        ?.map(
          e =>
            ({
              communityUserId: e.communityUserId,
              tagId: e.tagId,
              tag: e.tag,
              community: getCommunityForCommunityUser(e.communityUserId || ''),
            }) as MainSchema.CommunityUserTag,
        )
        ?.filter(
          (t: MainSchema.CommunityUserTag) =>
            t?.tag?.kind === SEARCH_TYPES.group,
        ) ?? [],
    [communityUser.communityUserTags, getCommunityForCommunityUser],
  )

  const communities = React.useMemo(
    () => communityUser.communities ?? [],
    [communityUser.communities],
  )

  const handleRemoveSkill = React.useCallback(
    async (skillId: string, communityUserId: string) => {
      try {
        const communityId = communityUser?.communityUsers?.find(
          e => e.communityUserId === communityUserId,
        )?.communityId

        if (!communityId || !communityUserId || !skillId) {
          return
        }

        const communityIds = me?.communities?.map(e => e.id) || []

        await disconnectUsersFromSkills({
          variables: {
            communityId,
            usersFromSkills: [
              {
                communityUserId,
                skillId,
              },
            ],
          },
          update: getCommunityUserSkillRemoveUpdater({
            communityUsers: [
              {
                communityUserId,
                communityId,
              },
            ],
            communityIds,
            skillIds: [skillId],
          }),
        })

        EventBus.trigger(EventBus.actions.graph.disconnectSkillTag, {
          toId: communityUser.communityUserId,
          fromId: skillId,
        })

        toast.success({
          title: 'Remove Skill',
          text: 'Skill successfully removed',
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: 'Failed to Remove skill',
          text: message,
        })
      }
    },
    [
      disconnectUsersFromSkills,
      me?.communities,
      communityUser?.communityUsers,
      communityUser.communityUserId,
    ],
  )

  const handleRemoveTag = React.useCallback(
    async (tagId: string, communityUserId: string) => {
      try {
        if (!community?.id || !communityUserId) {
          return
        }

        const communityIds = me?.communities?.map(e => e.id) || []

        await disconnectUsersFromTags({
          variables: {
            communityId: community.id,
            usersFromTags: [
              {
                communityUserId,
                tagId,
              },
            ],
          },
          update: getCommunityUserTagsRemoveUpdater({
            communityUsers: [
              {
                communityUserId,
                communityId: community.id,
              },
            ],
            communityIds,
            tagIds: [tagId],
          }),
        })

        EventBus.trigger(EventBus.actions.graph.disconnectSkillTag, {
          toId: communityUser.communityUserId,
          fromId: tagId,
        })

        toast.success({
          title: s('communityUserTag.title'),
          text: s('communityUserTag.deleteSuccess'),
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.success({
          title: s('communityUserTag.errorTitle'),
          text: message,
        })
      }
    },
    [
      community?.id,
      communityUser.communityUserId,
      disconnectUsersFromTags,
      me?.communities,
      s,
    ],
  )

  return (
    <Column fullWidth gap={2}>
      <ContactBox communityUser={communityUser} showCreate={showCreate} />

      <WorkHistories communityUser={communityUser} />

      <EducationHistories communityUser={communityUser} />

      <Skills
        communityUser={communityUser}
        communityUserSkills={skills}
        onRemove={handleRemoveSkill}
      />

      <TagBlock
        communityUser={communityUser}
        communityUserTags={events}
        kind={TAG_BLOCK_KIND.EVENT}
        onRemove={handleRemoveTag}
      />

      <TagBlock
        communityUser={communityUser}
        communityUserTags={projects}
        kind={TAG_BLOCK_KIND.PROJECT}
        onRemove={handleRemoveTag}
      />

      <TagBlock
        communityUser={communityUser}
        communityUserTags={groups}
        kind={TAG_BLOCK_KIND.GROUP}
        onRemove={handleRemoveTag}
      />

      <TagBlock
        communityUser={communityUser}
        communityUserTags={customTags}
        kind={TAG_BLOCK_KIND.CUSTOM}
        onRemove={handleRemoveTag}
      />

      <Communities communities={communities} communityUser={communityUser} />
    </Column>
  )
}

export default OverviewTab
