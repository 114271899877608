import React from 'react'

import TagsField from 'Components/Blocks/Admin/Forms/Fields/TagsField'
import { Column, InputField, Row } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import _ from 'Services/I18n'

import { EditCommunityUserFormField } from '../fields'

interface TagsTabProps {
  communityId: string
}

function TagsTab({ communityId }: TagsTabProps) {
  return (
    <Column fullWidth gap={4}>
      <Row fullWidth gap={4}>
        <Column fullWidth gap={4}>
          <TagsField
            communityId={communityId}
            label="Skills"
            name={EditCommunityUserFormField.Skills}
            type={SEARCH_TYPES.skill}
          />

          <TagsField
            communityId={communityId}
            label="Event"
            name={EditCommunityUserFormField.Event}
            type={SEARCH_TYPES.event}
          />

          <TagsField
            communityId={communityId}
            label="Role"
            name={EditCommunityUserFormField.Role}
            type={SEARCH_TYPES.role}
          />

          <TagsField
            communityId={communityId}
            label="Group"
            name={EditCommunityUserFormField.Group}
            type={SEARCH_TYPES.group}
          />
        </Column>

        <Column fullWidth gap={4}>
          <InputField
            label={_('auth.shared.hobbies&Interests')}
            name={EditCommunityUserFormField.InterestsHobbies}
            width={1}
          />

          <TagsField
            communityId={communityId}
            label="Project"
            name={EditCommunityUserFormField.Project}
            type={SEARCH_TYPES.project}
          />

          <TagsField
            communityId={communityId}
            label="Custom"
            name={EditCommunityUserFormField.Custom}
            type={SEARCH_TYPES.custom}
          />
        </Column>
      </Row>

      <InputField
        label="About"
        name={EditCommunityUserFormField.About}
        placeholder="Write about user"
        small
        textArea
      />
    </Column>
  )
}

export default TagsTab
