export enum ProfileField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PhotoUrl = 'photoUrl',
  Organization = 'organization',
  JobTitle = 'jobTitle',
  LinkedInUrl = 'linkedInUrl',
  TwitterUrl = 'twitterUrl',
  FacebookUrl = 'facebookUrl',
  PhoneNumber = 'phoneNumber',
  About = 'about',
}
