import React from 'react'

import { FaXTwitter } from 'react-icons/fa6'

export interface ITwitter {
  color?: string
  size?: number
}

const Twitter = ({ color = '#1D9BF0', size }: ITwitter) => {
  return <FaXTwitter color={color} display="block" size={size} />
}

export default Twitter
