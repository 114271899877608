import React, { useCallback } from 'react'

import { MdOutlineEditOff } from 'react-icons/md'

import addUsersToCommunitiesMutation from 'GraphQL/Mutations/Community/addUsersToCommunities.graphql'
import { getCommunityUserCommunitiesUpdater } from 'GraphQL/Updaters/GetCommunityUserCommunities'

import { Column, Row } from 'Components/UI'

import { useAppContext, useCommunityContext } from 'Hooks'

import { useMutation } from 'Services/Apollo'

import * as Styled from './ReadOnlyHeader.styles'

interface ReadOnlyHeaderProps {
  communityUser: MainSchema.CommunityUser
}

export default function ReadOnlyHeader({ communityUser }: ReadOnlyHeaderProps) {
  const { community } = useCommunityContext()
  const { me } = useAppContext()

  const [addUsersToCommunities] = useMutation<
    Pick<MainSchema.Mutation, 'addUsersToCommunities'>,
    MainSchema.MutationAddUsersToCommunitiesArgs
  >(addUsersToCommunitiesMutation)

  const handleAddCommunity = useCallback(async () => {
    if (!community || !communityUser?.userId) {
      return
    }

    await addUsersToCommunities({
      variables: {
        userIds: [communityUser.userId],
        communityIds: [community.id],
        sourceCommunityId: communityUser.communityId,
      },
      update: getCommunityUserCommunitiesUpdater({
        communityUsers: [
          {
            communityUserId: communityUser.communityUserId,
            communityId: community.id,
          },
        ],
        communities: me?.communities || [],
      }),
    })
  }, [
    addUsersToCommunities,
    community,
    communityUser?.userId,
    communityUser?.communityId,
    communityUser?.communityUserId,
    me?.communities,
  ])

  if (
    !community ||
    communityUser?.communities?.filter(e => e.id === community.id).length
  ) {
    return null
  }

  return (
    <Styled.Content>
      <Row fullWidth p={0}>
        <Styled.Text>
          <MdOutlineEditOff color={'#545454'} /> Profile is view only - Person
          not in {community?.name}.
        </Styled.Text>
      </Row>
      <Column center pb={1}>
        <Styled.Link onClick={handleAddCommunity}>Add to Community</Styled.Link>
      </Column>
    </Styled.Content>
  )
}
