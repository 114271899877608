import React, { forwardRef, useCallback } from 'react'

import { FeatureFlag } from 'Features/FeatureFlags/FeatureFlag'
import { useRegraphContext } from 'Features/Graph/useRegraphContext'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import useRegraphLoaders from 'Features/Graph/useRegraphLoaders'

import { ContextMenu, ContextMenuItem } from 'Components/UI'

import { ANALYZERS_NAME } from 'Constants/graph'
import {
  COMMUNITY_USER_RELATIONSHIP_STRENGTH,
  CommunityUserRelationshipStrength,
} from 'Constants/ids'

import { useCommunity } from 'Hooks'

import {
  getGraphStateApplicationStorage,
  setGraphStateApplicationStorage,
} from 'Services/Store/graph'

import {
  CONTEXT_NETWORK_FIELD,
  CONTEXT_RESET_FIELD,
  LayoutType,
} from '../../constants'

export interface INetworkMagicContext {
  isOpen: boolean
  left: number | string
  right?: number | string
  top: number | string
}

const NetworkMagicContext = forwardRef<HTMLDivElement, INetworkMagicContext>(
  ({ isOpen, left, right, top }, ref) => {
    const { community } = useCommunity()
    const {
      showPeopleConnections,
      showSkillConnections,
      showTagConnections,
      showWorkHistoryConnections,
      showEducationHistoryConnections,
      showCommunityConnections,
      setShowPeopleConnections,
      setShowSkillConnections,
      setShowTagConnections,
      setShowWorkHistoryConnections,
      setShowEducationHistoryConnections,
      setShowCommunityConnections,
    } = useRegraphContext()

    const { handleLoadPeopleByDegrees, handleStreamPeople, handleLoadTags } =
      useRegraphLoaders()

    const {
      handleClustering,
      handleLayoutGraph,
      handleUpdateNetworkAnalyser,
      handleResetGraph,
    } = useRegraphHandlers()

    const [clusteringEnabled, setClusteringEnabled] = React.useState(false)

    const handleToggleRelationship = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (strength: CommunityUserRelationshipStrength) => {
        /*
        graphState.setShowRelationshipStrength(prevState => ({
          ...prevState,
          [strength]: !prevState[strength],
        }))
        */
      },
      [],
    )

    const handleToggleClustering = useCallback(() => {
      if (handleClustering) {
        setClusteringEnabled(prevState => !prevState)
        handleClustering(clusteringEnabled ? 3 : 0)
      }
    }, [clusteringEnabled, handleClustering])

    React.useEffect(() => {
      handleClustering?.(clusteringEnabled ? 3 : 0)
    }, [clusteringEnabled, handleClustering])

    return (
      <ContextMenu
        isOpen={isOpen}
        left={left}
        ref={ref}
        right={right}
        top={top}
      >
        <ContextMenuItem
          iconPrefix="reset"
          id={CONTEXT_RESET_FIELD.RESET_GRAPH}
          label="Reset Graph"
          onClick={handleResetGraph}
        />
        <ContextMenuItem
          iconPrefix="relayout"
          id={CONTEXT_RESET_FIELD.RELAYOUT_GRAPH}
          label="Relayout Graph"
          showDivider
          onClick={() => handleLayoutGraph()}
        />

        <ContextMenuItem
          id={CONTEXT_NETWORK_FIELD.SHOW_NETWORK}
          label="Show Network"
        >
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.FIRST_DEGREE}
            label="1st Degree"
            onClick={() => handleLoadPeopleByDegrees(1)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SECOND_DEGREE}
            label="2nd Degree"
            onClick={() => handleLoadPeopleByDegrees(2)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.THIRD_DEGREE}
            label="3rd Degree"
            onClick={() => handleLoadPeopleByDegrees(3)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.ALL_TAGS}
            label="All Tags"
            onClick={() => handleLoadTags()}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.ALL_USERS}
            label="All Users"
            onClick={() => handleStreamPeople([community?.id!])}
          />
        </ContextMenuItem>

        <ContextMenuItem id={CONTEXT_NETWORK_FIELD.LAYOUTS} label="Layouts">
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.ORGANIC_LAYOUT}
            label="Organic"
            onClick={() => handleLayoutGraph(LayoutType.Organic)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SEQUENTIAL_LAYOUT}
            label="Sequential"
            onClick={() => handleLayoutGraph(LayoutType.Sequential)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.STRUCTURAL_LAYOUT}
            label="Structural"
            onClick={() => handleLayoutGraph(LayoutType.Structural)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.RADIAL_LAYOUT}
            label="Radial"
            onClick={() => handleLayoutGraph(LayoutType.Radial)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.LENS_LAYOUT}
            label="Lens"
            onClick={() => handleLayoutGraph(LayoutType.Lens)}
          />
        </ContextMenuItem>

        <ContextMenuItem
          id={CONTEXT_NETWORK_FIELD.NETWORK_ANALYZER}
          label="Network Analyzers"
        >
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.NO_ANALYZER}
            label="None"
            onClick={() => handleUpdateNetworkAnalyser(null)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.BETWEENESS}
            label="Betweeness"
            onClick={() => handleUpdateNetworkAnalyser(ANALYZERS_NAME.between)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.CLOSENESS}
            label="Closeness"
            onClick={() => handleUpdateNetworkAnalyser(ANALYZERS_NAME.close)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.EIGEN_CENTRALITY}
            label="Degrees"
            onClick={() => handleUpdateNetworkAnalyser(ANALYZERS_NAME.degree)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.ALL_USERS}
            label="Centrality"
            onClick={() => handleUpdateNetworkAnalyser(ANALYZERS_NAME.central)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.PAGE_RANK}
            label="Rank"
            onClick={() => handleUpdateNetworkAnalyser(ANALYZERS_NAME.rank)}
          />
        </ContextMenuItem>

        <ContextMenuItem
          id={CONTEXT_NETWORK_FIELD.RELATIONSHIP_STRENGTH}
          label="Relationship Strength"
        >
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SHOW_STRONG}
            isToggled={false}
            label="Strong (3)"
            useToggle
            onClick={() =>
              handleToggleRelationship(
                COMMUNITY_USER_RELATIONSHIP_STRENGTH.STRONG,
              )
            }
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SHOW_MODERATE}
            isToggled={false}
            label="Moderate (2)"
            useToggle
            onClick={() =>
              handleToggleRelationship(
                COMMUNITY_USER_RELATIONSHIP_STRENGTH.MODERATE,
              )
            }
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SHOW_WEAK}
            isToggled={false}
            label="Weak (1)"
            useToggle
            onClick={() =>
              handleToggleRelationship(
                COMMUNITY_USER_RELATIONSHIP_STRENGTH.WEAK,
              )
            }
          />
        </ContextMenuItem>

        <FeatureFlag featureKey={'regraph'}>
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.RELATIONSHIP_STRENGTH}
            label="Show Edges"
          >
            <ContextMenuItem
              id={CONTEXT_NETWORK_FIELD.SHOW_PEOPLE_CONNECTIONS_EDGES}
              isToggled={showPeopleConnections}
              label="People"
              useToggle
              onClick={() => {
                setShowPeopleConnections(!showPeopleConnections)
                setGraphStateApplicationStorage({
                  ...getGraphStateApplicationStorage(),
                  showPeopleConnections: !showPeopleConnections,
                })
              }}
            />
            <ContextMenuItem
              id={CONTEXT_NETWORK_FIELD.SHOW_SKILL_CONNECTIONS_EDGES}
              isToggled={showSkillConnections}
              label="Skills"
              useToggle
              onClick={() => {
                setShowSkillConnections(!showSkillConnections)
                setGraphStateApplicationStorage({
                  ...getGraphStateApplicationStorage(),
                  showSkillConnections: !showSkillConnections,
                })
              }}
            />
            <ContextMenuItem
              id={CONTEXT_NETWORK_FIELD.SHOW_TAG_CONNECTIONS_EDGES}
              isToggled={showTagConnections}
              label="Tags"
              useToggle
              onClick={() => {
                setShowTagConnections(!showTagConnections)
                setGraphStateApplicationStorage({
                  ...getGraphStateApplicationStorage(),
                  showTagConnections: !showTagConnections,
                })
              }}
            />
            <ContextMenuItem
              id={CONTEXT_NETWORK_FIELD.SHOW_WORK_HISTORY_CONNECTIONS_EDGES}
              isToggled={showWorkHistoryConnections}
              label="Work History"
              useToggle
              onClick={() => {
                setShowWorkHistoryConnections(!showWorkHistoryConnections)
                setGraphStateApplicationStorage({
                  ...getGraphStateApplicationStorage(),
                  showWorkHistoryConnections: !showWorkHistoryConnections,
                })
              }}
            />
            <ContextMenuItem
              id={
                CONTEXT_NETWORK_FIELD.SHOW_EDUCATION_HISTORY_CONNECTIONS_EDGES
              }
              isToggled={showEducationHistoryConnections}
              label="Education History"
              useToggle
              onClick={() => {
                setShowEducationHistoryConnections(
                  !showEducationHistoryConnections,
                )
                setGraphStateApplicationStorage({
                  ...getGraphStateApplicationStorage(),
                  showEducationHistoryConnections:
                    !showEducationHistoryConnections,
                })
              }}
            />
            <ContextMenuItem
              id={CONTEXT_NETWORK_FIELD.SHOW_COMMUNITY_CONNECTIONS_EDGES}
              isToggled={showCommunityConnections}
              label="Community"
              useToggle
              onClick={() => {
                setShowCommunityConnections(!showCommunityConnections)
                setGraphStateApplicationStorage({
                  ...getGraphStateApplicationStorage(),
                  showCommunityConnections: !showCommunityConnections,
                })
              }}
            />
          </ContextMenuItem>
        </FeatureFlag>

        <ContextMenuItem
          id={CONTEXT_NETWORK_FIELD.CLUSTER}
          isToggled={clusteringEnabled}
          label="Clustering"
          useToggle
          onClick={handleToggleClustering}
        />
      </ContextMenu>
    )
  },
)

export default NetworkMagicContext
