import { MouseEventHandler } from 'react'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { resetButton } from 'Components/Styles'
import Text from 'Components/UI/Text'

export interface ITagMouseEvent
  extends React.MouseEvent<HTMLDivElement | HTMLButtonElement> {
  entity?: any
}

export interface IContainer extends MarginProps, LayoutProps {
  onClick?: MouseEventHandler<HTMLDivElement>
  colorKind: string
  passClickThrough?: boolean
  small?: boolean
}

export interface ContainerCssProps extends MarginProps {}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: row;
  height: ${mapToTheme('tags.height')}px;
  width: 100%;
  gap: ${themeGet('space.3')}px;
  background-color: ${props => themeGet(`colors.tag.${props.colorKind}.bg`)};
  border: 1px solid ${props => themeGet(`colors.tag.${props.colorKind}.border`)};
  overflow: hidden;
  flex-shrink: 0;
  cursor: ${props => (props.onClick ? 'pointer' : 'initial')};
  pointer-events: ${props => (props.passClickThrough ? 'none' : 'initial')};

  & * {
    color: ${props => themeGet(`colors.tag.${props.colorKind}.color`)};
  }

  & svg {
    flex-shrink: 0;
    height: ${mapToTheme('tags.iconSize')}px;
    width: ${mapToTheme('tags.iconSize')}px;
  }

  ${margin}
  ${layout}
`

export interface SecondaryActionButtonCssProps {
  secondary?: boolean
}

const secondaryActionButtonCss = ({
  secondary,
}: SecondaryActionButtonCssProps) =>
  secondary &&
  css`
    color: ${themeGet('colors.error')};
  `

export interface DisabledActionButtonCssProps {
  disabled?: boolean
}

const disabledActionButtonCss = ({ disabled }: DisabledActionButtonCssProps) =>
  disabled &&
  css`
    cursor: not-allowed;
    color: ${themeGet('colors.text.disabled')};

    &:active,
    &:focus,
    &:hover {
      color: ${themeGet('colors.text.disabled')};
    }
  `

export interface ActionButtonCssProps
  extends SecondaryActionButtonCssProps,
    DisabledActionButtonCssProps {}

export const ActionButton = styled.button<ActionButtonCssProps>`
  ${resetButton};

  font-weight: ${themeGet('fontWeight.1')};
  font-size: ${themeGet('fontSize.1')}px;
  line-height: 16px;
  cursor: pointer;
  color: ${themeGet('colors.success')};

  ${secondaryActionButtonCss};
  ${disabledActionButtonCss}
`

export const CommunityImage = styled.img`
  width: 12px;
  height: 12px;
  object-fit: cover;
`

export const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
`

export const CommunityName = styled(Text)`
  flex-direction: row;
  width: 100%;
  overflow: hidden;
`
