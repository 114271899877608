import React from 'react'
import ReactSelect, { SingleValue } from 'react-select'

import styled from 'styled-components'

const CountBadge = styled.span`
  background: #f0f0f0;
  color: #666666;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 8px;
`

interface OptionType {
  value: string
  label: string
  count?: number
}

interface CustomSelectProps {
  options: OptionType[]
  onChange: (option: SingleValue<OptionType>) => void
  maxWidth?: number
  minHeight?: number
  width?: number
}

const formatOptionLabel = ({ label, count }: OptionType) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {label}
    {typeof count !== 'undefined' && <CountBadge>{count}</CountBadge>}
  </div>
)

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  onChange,
  ...props
}) => (
  <ReactSelect
    defaultValue={options.find(option => option.value === 'all')}
    formatOptionLabel={formatOptionLabel}
    options={options}
    styles={{
      option: base => ({
        ...base,
        borderBottom: 'none',
        backgroundColor: 'white',
        color: '#000',
        fontSize: '14px',
        fontWeight: 500,
        padding: '8px 12px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
        '&:active': {
          backgroundColor: '#f5f5f5',
          color: '#000',
        },
        '&:selected': {
          backgroundColor: '#f5f5f5',
          color: '#000',
        },
      }),
      control: base => ({
        ...base,
        borderBottom: 'none',
        border: 'none',
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 500,
        '&:hover': {
          border: 'none',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      menu: base => ({
        ...base,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '4px',
        marginTop: '4px',
      }),
    }}
    {...props}
    onChange={onChange}
  />
)

export default CustomSelect
