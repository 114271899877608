import React, { PropsWithChildren, useState } from 'react'

import { Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'
import Flex, { Column, IFlex } from 'Components/UI/Flex'

import colors from 'Theme/_v2/colors'

import * as Styled from './ShowMore.styles'

export interface ShowMoreProps extends PropsWithChildren<IFlex> {
  initialShown: number
  singleRow?: boolean
}

export default function ShowMore({
  initialShown,
  children,
  ...props
}: ShowMoreProps) {
  const numberFormatter = Intl.NumberFormat()
  const [showAll, setShowAll] = useState<boolean>(false)

  const handleClick = () => {
    setShowAll(true)
  }

  const totalChildren = React.Children.count(children)
  const totalHiddenChildren =
    totalChildren - initialShown > 0 ? totalChildren - initialShown : 0

  return (
    <Column>
      <Flex flexDirection={'column'} {...props}>
        {React.Children.map(children, (child, index) => {
          const isHidden = !showAll && index >= initialShown

          return <Styled.Child hidden={isHidden}>{child}</Styled.Child>
        })}
      </Flex>

      {!showAll && totalHiddenChildren > 0 && (
        <Row pt={1}>
          <Styled.Button onClick={handleClick}>
            <Styled.Content>
              <Text
                as="span"
                color={colors.text.secondary}
                fontSize={'10px'}
                fontWeight={500}
                lineHeight={1}
              >
                +{numberFormatter.format(totalHiddenChildren)}
              </Text>

              <Styled.IconShowMore color={colors.text.secondary} />
            </Styled.Content>
          </Styled.Button>
        </Row>
      )}
    </Column>
  )
}
