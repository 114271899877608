import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IconX } from '@tabler/icons-react'

import { mergeGlyph } from 'Assets/Svg/icons'

import { Button, Row } from 'Components/UI'

export const ProfileTokenItem = styled.div<{ isAnchor?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px;
  padding-right: 8px;
  gap: 6px;
  width: fit-content;
  background-color: ${({ isAnchor }) =>
    isAnchor
      ? themeGet('colors.bg.secondary')
      : themeGet('colors.bg.tertiary')};
  border-radius: 100px;
  border: 1px solid
    ${({ isAnchor }) =>
      isAnchor ? 'transparent' : themeGet('colors.border.secondary')};
  cursor: ${({ isAnchor }) => (isAnchor ? 'default' : 'pointer')};

  > p {
    color: ${({ isAnchor }) =>
      isAnchor
        ? themeGet('colors.text.contrast')
        : themeGet('colors.text.header')};

    font-weight: ${themeGet('fontWeight.1')};
  }
`

export const ProfileTokenClose = styled(IconX)`
  color: ${themeGet('colors.text.body')};
`

export const ProfileItem = styled(Row)`
  align-items: center;
  padding: 8px;
  gap: 12px;
  width: 100%;
`

export const AddButton = styled(Button)`
  min-width: 32px;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MergeIcon = styled(mergeGlyph)`
  width: 48px;
  height: 48px;
  padding: 10px;
  border: 2px solid ${themeGet('colors.border.primary')};
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 10px;
  color: ${themeGet('colors.text.primary')};
`

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  margin: 12px 0;
  background-color: ${themeGet('colors.border.primary')};
`

export const SearchWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 0 0 6px 6px;
  border: 1px solid ${themeGet('colors.border.primary')};
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`

export const ScrollContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`
