import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import NameCell from 'Features/PeopleTable/NameCell'
import PeopleEmptyState from 'Features/PeopleTable/PeopleEmptyState'
import PeopleTableControls from 'Features/PeopleTable/PeopleTableControls'
import PeopleTableView from 'Features/PeopleTable/PeopleTableView'
import ProfilePanel from 'Features/ProfilePanel/ProfilePanel'
import userCommunitiesQuery from 'GraphQL/Queries/Community/userCommunities.graphql'

import debounce from 'lodash/debounce'

import { useCommunity } from 'Hooks'

import MultiValueCell from './MultiValueCell'
import { SelectionCell, SelectionHeader } from './SelectionHeader'
import { Heading, ProfilePanelWrapper, Wrapper } from './styles'
import { usePeopleTable } from './usePeopleTable'

export type PersonRow = {
  name: string
  emails: MainSchema.CommunityUserProfileEntry[]
  communities: MainSchema.Community[]
  source: string
  createdAt: string
  updatedAt: string
  photoUrl?: string
  communityUserId: string
}

export default function PeopleTable() {
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set())
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'createdAt', desc: true },
  ])
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const { community } = useCommunity()

  // Initialize with default community if available
  const [selectedCommunityIds, setSelectedCommunityIds] = useState<Set<string>>(
    () => (community?.id ? new Set([community.id]) : new Set()),
  )
  const [showCommunityFilter, setShowCommunityFilter] = useState(false)
  const [showMetaMenu, setShowMetaMenu] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(false)
  const [selectedCommunityUserId, setSelectedCommunityUserId] = useState<
    string | null
  >(null)

  const columnHelper = createColumnHelper<PersonRow>()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')

  const debouncedSetFilterText = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedFilterText(value)
      }, 500),
    [],
  )

  const handleFilterTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setFilterText(value)
      debouncedSetFilterText(value)
    },
    [debouncedSetFilterText],
  )

  useEffect(() => {
    return () => {
      debouncedSetFilterText.cancel()
    }
  }, [debouncedSetFilterText])

  // Update selected communities when default community changes
  useEffect(() => {
    if (community?.id && selectedCommunityIds.size === 0) {
      setSelectedCommunityIds(new Set([community.id]))
    }
  }, [community?.id, selectedCommunityIds.size])

  const {
    users,
    loading,
    searching,
    currentPage,
    totalPages,
    limit,
    totalCount,
    handlePageChange,
    handleLimitChange,
  } = usePeopleTable({
    communityId: community?.id,
    selectedCommunityIds,
    filterText: debouncedFilterText,
    sortBy: sorting[0]?.id || 'createdAt',
    sortDirection: sorting[0]?.desc ? 'desc' : 'asc',
  })

  const { data: communitiesData } = useQuery<
    Pick<MainSchema.Query, 'userCommunities'>,
    MainSchema.QueryUserCommunitiesArgs
  >(userCommunitiesQuery, { variables: { page: 0, limit: 100 } })

  const userCommunitiesData = useMemo(
    () => communitiesData?.userCommunities?.rows || [],
    [communitiesData],
  )

  const isAllCommunitiesSelected = useMemo(() => {
    for (const community of userCommunitiesData) {
      if (!selectedCommunityIds.has(community.id)) return false
    }
    return userCommunitiesData.length > 0
  }, [userCommunitiesData, selectedCommunityIds])

  const usersTable = useMemo(() => {
    return users.map((communityUser: MainSchema.CommunityUser) => ({
      name:
        communityUser.firstName && communityUser.lastName
          ? `${communityUser.firstName} ${communityUser.lastName}`.trim()
          : 'Unknown',
      emails: communityUser.emails || [],
      source: communityUser.source || '',
      createdAt: communityUser.createdAt
        ? format(new Date(communityUser.createdAt), 'MM/dd/yyyy')
        : '',
      updatedAt: communityUser.updatedAt
        ? format(new Date(communityUser.updatedAt), 'MM/dd/yyyy')
        : '',
      photoUrl: communityUser.photoUrl,
      communities: communityUser.communities || [],
      communityUserId: communityUser.communityUserId,
    }))
  }, [users])

  const selectedRowData = useMemo(
    () => Array.from(selectedRows, rowId => users[rowId]),
    [users, selectedRows],
  )

  const renderSelectionHeader = useCallback(() => {
    return (
      <SelectionHeader
        allSelected={users.length > 0 && selectedRows.size === users.length}
        onSelectAll={handleSelectAll}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.length, selectedRows.size])

  const renderSelectionCell = useCallback(
    (info: CellContext<PersonRow, unknown>) => (
      <SelectionCell
        rowIndex={info.row.index}
        selected={selectedRows.has(info.row.index)}
        onRowClick={handleRowClick}
      />
    ),
    [selectedRows],
  )

  const renderNameCell = useCallback(
    (info: CellContext<PersonRow, string>) => (
      <NameCell name={info.getValue()} photoUrl={info.row.original.photoUrl} />
    ),
    [],
  )

  const renderEmailsCell = useCallback(
    (info: CellContext<PersonRow, MainSchema.CommunityUserProfileEntry[]>) => {
      const emails = info.row.original.emails || []
      if (emails.length === 0) return ''

      const formattedEmails = emails
        .map(email => email?.profileEntry?.value)
        .filter(Boolean) as string[]

      if (formattedEmails.length === 0) return ''

      if (formattedEmails.length === 1) return formattedEmails[0]

      return (
        <MultiValueCell
          additionalValues={formattedEmails.slice(1)}
          firstValue={formattedEmails[0]}
          moreText="more"
        />
      )
    },
    [],
  )

  const renderCommunitiesCell = useCallback(
    (info: CellContext<PersonRow, MainSchema.Community[]>) => {
      const communities = info.row.original.communities || []
      const communityNames = communities
        .map(c => (typeof c === 'object' && c?.name ? c.name : c))
        .filter(Boolean) as string[]

      if (communityNames.length === 0) return ''

      if (communityNames.length === 1) return communityNames[0]

      return (
        <MultiValueCell
          additionalValues={communityNames.slice(1)}
          firstValue={communityNames[0]}
          moreText="more"
        />
      )
    },
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'selection',
        header: renderSelectionHeader,
        cell: renderSelectionCell,
        enableSorting: false,
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: renderNameCell,
        enableSorting: true,
      }),
      columnHelper.accessor('emails', {
        header: 'Email',
        cell: renderEmailsCell,
        enableSorting: true,
      }),
      columnHelper.accessor('communities', {
        header: 'Communities',
        cell: renderCommunitiesCell,
        enableSorting: true,
      }),
      columnHelper.accessor('source', {
        header: 'Source',
        enableSorting: true,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Date Created',
        enableSorting: true,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last Updated',
        enableSorting: true,
      }),
    ],
    [
      renderSelectionHeader,
      renderSelectionCell,
      renderNameCell,
      renderEmailsCell,
      renderCommunitiesCell,
      columnHelper,
    ],
  )

  const table = useReactTable({
    data: usersTable,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    manualPagination: true,
    pageCount: totalPages,
    initialState: {
      sorting: [{ id: 'createdAt', desc: true }],
    },
  })

  const handleRowClick = (rowId: number) => {
    setSelectedRows(prev => {
      const newSelectedRows = new Set(prev)
      if (newSelectedRows.has(rowId)) {
        newSelectedRows.delete(rowId)
      } else {
        newSelectedRows.add(rowId)
      }
      return newSelectedRows
    })
  }

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const allRowIds = new Set(users.map((_: unknown, index: number) => index))
      setSelectedRows(allRowIds as Set<number>)
    } else {
      setSelectedRows(new Set())
    }
  }

  const handleNameClick = (communityUserId: string) => {
    setSelectedCommunityUserId(communityUserId)
  }

  const handleCommunityCheckboxChange = (
    communityId: string,
    checked: boolean,
  ) => {
    setSelectedCommunityIds(prev => {
      const updated = new Set(prev)
      if (checked) {
        updated.add(communityId)
      } else {
        updated.delete(communityId)
        // If removing the last community, add back the default community
        if (updated.size === 0 && community?.id) {
          updated.add(community.id)
        }
      }
      return updated
    })
  }

  const onToggleAllCommunities = (checked: boolean) => {
    if (checked) {
      setSelectedCommunityIds(new Set(userCommunitiesData.map(comm => comm.id)))
    } else {
      // When deselecting all, revert to default community
      setSelectedCommunityIds(
        community?.id ? new Set([community.id]) : new Set(),
      )
    }
  }

  if (usersTable.length === 0 && !filterText && !taskId) {
    return <PeopleEmptyState slug={userCommunitiesData[0].slug} />
  }

  return (
    <Wrapper>
      <Heading>People {totalCount} total</Heading>
      <PeopleTableControls
        filterText={filterText}
        filteredUsers={users}
        isAllCommunitiesSelected={isAllCommunitiesSelected}
        selectedCommunityIds={selectedCommunityIds}
        selectedRowData={selectedRowData}
        selectedRows={selectedRows}
        setShowActionsMenu={setShowActionsMenu}
        setShowCommunityFilter={setShowCommunityFilter}
        setShowMetaMenu={setShowMetaMenu}
        showActionsMenu={showActionsMenu}
        showCommunityFilter={showCommunityFilter}
        showMetaMenu={showMetaMenu}
        userCommunitiesData={userCommunitiesData}
        onCommunityCheckboxChange={handleCommunityCheckboxChange}
        onFilterTextChange={handleFilterTextChange}
        onToggleAllCommunities={onToggleAllCommunities}
      />
      <PeopleTableView
        currentPage={currentPage}
        filterText={filterText}
        handleNameClick={handleNameClick}
        handleRowClick={handleRowClick}
        isLoading={loading}
        limit={limit}
        searching={searching}
        table={table}
        totalCount={totalCount}
        totalPages={totalPages}
        onLimitChange={handleLimitChange}
        onPageChange={handlePageChange}
      />
      {selectedCommunityUserId && (
        <ProfilePanelWrapper>
          <ProfilePanel
            communityUserId={selectedCommunityUserId}
            onClose={() => setSelectedCommunityUserId(null)}
          />
        </ProfilePanelWrapper>
      )}
    </Wrapper>
  )
}
