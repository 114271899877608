import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Dropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DropdownTrigger = styled.button`
  background: none;
  border: none;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: 4px solid #edeff1;
  }

  &:active,
  &[aria-expanded='true'] {
    outline: 4px solid ${themeGet('colors.avatar.border')};
  }
`

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 14px;
  background-color: #ffffff;
  min-width: 231px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`

export const DropdownProfile = styled.button`
  padding: 20px 12px;
  background: none;
  border: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: ${themeGet('fontSize.2')}px;
  font-weight: ${themeGet('fontWeight.2')};
  line-height: 20px;
  color: #2d2d2d;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: ${themeGet('colors.link.primary.color')};
  }
`

export const DropdownDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
`

export const DropdownLinks = styled.div`
  padding: 8px 0 16px 0;
  display: flex;
  flex-direction: column;
`

export const DropdownLink = styled.a<{ fontSize?: 'small' }>`
  text-decoration: none;
  display: block;
  font-size: ${({ fontSize }) =>
    fontSize === 'small' ? themeGet('fontSize.1') : themeGet('fontSize.2')}px;
  font-weight: ${themeGet('fontWeight.0')};
  line-height: 16px;
  color: #2d2d2d;
  padding: 10px 16px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: ${themeGet('colors.link.primary.color')};
  }
`
export const DropdownRouterLink = styled(DropdownLink.withComponent(Link))``

export const DropdownIconLink = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: ${themeGet('fontSize.2')}px;
  font-weight: ${themeGet('fontWeight.1')};
  color: #2d2d2d;
  line-height: 20px;
  padding: 6px 16px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: ${themeGet('colors.link.primary.color')};
  }
`
