import styled from 'styled-components'

export const MultiValueCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const MoreCount = styled.span`
  background: #eef1f5;
  color: #2d64bc;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  cursor: help;
  transition: background-color 0.2s;

  &:hover {
    background: #e4e9f2;
  }
`

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
`

export const TooltipContent = styled.div<{ $showBelow?: boolean }>`
  background-color: #2d2d2d;
  color: white;
  text-align: left;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 999;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

  &::after {
    content: '';
    position: absolute;
    ${props => (props.$showBelow ? 'top: -10px;' : 'top: 100%;')}
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${props =>
      props.$showBelow
        ? 'transparent transparent #2d2d2d transparent'
        : '#2d2d2d transparent transparent transparent'};
  }
`
