import React, { FocusEventHandler, PropsWithChildren } from 'react'

import { Column, Input, Loader, Row } from 'Components/UI'

import * as Styled from './InlineTextboxCreate.styles'

export interface IInlineTextboxCreateProps extends PropsWithChildren {
  disabled?: boolean
  isLoading?: boolean
  placeholder?: string
  value: string
  onChange?: (value: any) => void
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onCancel?: () => void
  onSave?: () => Promise<void>
}

function InlineTextboxCreate({
  disabled,
  isLoading,
  placeholder,
  value,
  onBlur,
  onChange,
  onCancel,
  onSave,
}: IInlineTextboxCreateProps) {
  return (
    <Styled.Container>
      <Row fullWidth gap={4} mb={1}>
        <Column>
          <Styled.ActionButton
            disabled={isLoading}
            secondary
            onClick={onCancel}
          >
            Cancel
          </Styled.ActionButton>
        </Column>
        <Column>
          <Styled.ActionButton
            disabled={disabled || isLoading}
            onClick={onSave}
          >
            {isLoading ? <Loader /> : 'Save'}
          </Styled.ActionButton>
        </Column>
      </Row>
      <Row fullWidth mb={2}>
        <Column fullWidth>
          <Input
            mt={2}
            name="emailOrPhone"
            placeholder={placeholder}
            value={value}
            width={1}
            onBlur={onBlur}
            onChange={onChange}
          />
        </Column>
      </Row>
    </Styled.Container>
  )
}

export default InlineTextboxCreate
