import { ApolloClient } from '@apollo/client'

import { utils } from 'Components/Blocks/Graph'

import { CommunityUserRelationshipStrength } from 'Constants/ids'

// TODO: Create a new rs query specifically for loading rs data
import communityUserRelationshipsQuery from './Queries/graphRelationshipEdges.graphql'

export interface ILoadGraphRelationshipEdgesResults {
  edges: Record<string, Record<string, CommunityUserRelationshipStrength>>
  count: number
  pages: number
}

export interface ILoadGraphRelationshipEdgesArgs {
  communityIds: string[]
  limit?: number
  page?: number
  client?: ApolloClient<any>
}

export default async function LoadRelationshipEdges(
  args: ILoadGraphRelationshipEdgesArgs,
): Promise<ILoadGraphRelationshipEdgesResults> {
  if (!args.client || !args.communityIds?.length) {
    return {
      edges: {},
      count: 0,
      pages: 0,
    }
  }

  const result = await args.client.query<
    Pick<MainSchema.Query, 'communityUsersRelationships'>,
    MainSchema.QueryCommunityUsersRelationshipsArgs
  >({
    query: communityUserRelationshipsQuery,
    variables: {
      communityId: args?.communityIds?.[0],
    },
  })

  const results = {
    edges: utils.deserializeUserRelationStrength(
      result?.data?.communityUsersRelationships,
    ),
    count: result?.data?.communityUsersRelationships?.length || 0,
    pages: 1,
  }

  return results
}
