import React from 'react'

import {
  IGraphCommunityNode,
  IGraphCommunityUserEducationHistory,
  IGraphCommunityUserSkillNode,
  IGraphCommunityUserTagNode,
  IGraphCommunityUserWorkHistory,
  IGraphOrganizationNode,
  IGraphPersonNode,
  IGraphSkillNode,
  IGraphTagNode,
} from 'Features/GraphNodes/NodeTypes'

import forEach from 'lodash/forEach'

import { useAppContext, useCommunity } from 'Hooks'

import { mapPeopleToGraphHandler } from './Helpers/mapPeopleToGraphHandler'
import { loadPeopleByDegrees } from './Loaders/loadPeopleByDegrees'
import {
  IOnLoadPeopleArgs,
  loadPeopleHandler,
} from './Loaders/loadPeopleHandler'
import {
  IOnLoadPersonArgs,
  loadPersonHandler,
} from './Loaders/loadPersonHandler'
import loadTagsHandler, { ILoadTagsArgs } from './Loaders/loadTagsHandler'
import { streamPeopleHandler } from './Loaders/streamPeopleHandler'
import { IGraphNode } from './Nodes/BaseGraphNode'
import { CommunityNode } from './Nodes/CommunityNode'
import { OrganizationNode } from './Nodes/OrganizationNode'
import { SkillNode } from './Nodes/SkillNode'
import { TagNode } from './Nodes/TagNode'
import { useRegraphContext } from './useRegraphContext'

export interface IRegraphLoaders {
  // GraphQL Load Handlers
  handleStreamPeople: (communityIds: string[]) => Promise<void>
  handleLoadPeople: (args: IOnLoadPeopleArgs) => Promise<void>
  handleLoadPerson: (args: IOnLoadPersonArgs) => Promise<void>
  handleLoadTags: (args?: ILoadTagsArgs) => Promise<void>
  handleLoadPeopleByDegrees: (degrees: number) => void

  // Spawn Nodes onto the graph loaders
  handleSpawnPeople: (people: IGraphPersonNode[]) => void
  handleSpawnTags: (tags: IGraphTagNode[]) => void
  handleSpawnSkills: (skills: IGraphSkillNode[]) => void
  handleSpawnOrganization: (organizations: IGraphOrganizationNode[]) => void
  handleSpawnEducation: (organizations: IGraphOrganizationNode[]) => void
  handleSpawnCommunityUserTags: (
    communityUserTags: IGraphCommunityUserTagNode[],
  ) => void
  handleSpawnCommunityUserSkills: (
    communityUserSkills: IGraphCommunityUserSkillNode[],
  ) => void
  handleSpawnCommunityUserWorkHistory: (
    communityUserWorkHistory: IGraphCommunityUserWorkHistory[],
  ) => void
  handleSpawnCommunityUserEducationHistory: (
    communityUserEducationHistory: IGraphCommunityUserEducationHistory[],
  ) => void
  handleSpawnCommunityUserCommunities: (
    communities: IGraphCommunityNode[],
  ) => void
  handleSpawnCommunities: (communities: IGraphCommunityNode[]) => void
}

const useRegraphLoaders = (): IRegraphLoaders => {
  const context = useRegraphContext()
  const { community } = useCommunity()
  const { me } = useAppContext()

  const activeCommunityUserId = React.useMemo(() => {
    return (
      me?.communityUsers?.find(e => e.communityId === community?.id)?.id || null
    )
  }, [community?.id, me?.communityUsers])

  const handleSpawnPeople = React.useCallback(
    (people: IGraphPersonNode[]) => {
      const {
        newPeople,
        newConnectionEdges,
        newTagEdges,
        newSkillEdges,
        newWorkHistoryEdges,
        newCommunityEdges,
      } = mapPeopleToGraphHandler({
        people,
        myCommunityUserId: me?.communityUserId,
        activeCommunityUserId,
      })

      context.setData(prevState => ({
        ...prevState,
        people: {
          ...prevState.people,
          ...newPeople,
        },
        allEdges: {
          ...prevState.allEdges,
          ...newConnectionEdges,
          ...newTagEdges,
          ...newSkillEdges,
          ...newWorkHistoryEdges,
          ...newCommunityEdges,
        },
      }))
    },
    [activeCommunityUserId, context, me?.communityUserId],
  )

  const handleSpawnTags = React.useCallback(
    (tags: IGraphTagNode[]) => {
      const newTags: Record<string, IGraphNode> = {}

      forEach(tags, (tag: IGraphTagNode) => {
        if (tag.id) {
          const newTag = new TagNode({ tag })
          newTags[tag.id] = newTag
        }
      })

      context.setData(prevState => ({
        ...prevState,
        tags: {
          ...prevState.tags,
          ...newTags,
        },
      }))
    },
    [context],
  )

  const handleSpawnSkills = React.useCallback(
    (skills: IGraphSkillNode[]) => {
      const newSkills: Record<string, IGraphNode> = {}

      forEach(skills, (skill: IGraphSkillNode) => {
        if (skill.id) {
          const newSkill = new SkillNode({ skill })
          newSkills[skill.id] = newSkill
        }
      })

      context.setData(prevState => ({
        ...prevState,
        skills: {
          ...prevState.skills,
          ...newSkills,
        },
      }))
    },
    [context],
  )

  const handleSpawnOrganization = React.useCallback(
    (organizations: IGraphOrganizationNode[]) => {
      const newOrganizations: Record<string, IGraphNode> = {}

      forEach(organizations, (organization: IGraphOrganizationNode) => {
        if (organization.id) {
          newOrganizations[organization.id] = new OrganizationNode({
            organization,
          })
        }
      })

      context.setData(prevState => ({
        ...prevState,
        organizations: {
          ...prevState.organizations,
          ...newOrganizations,
        },
      }))
    },
    [context],
  )

  const handleSpawnEducation = React.useCallback(
    (organizations: IGraphOrganizationNode[]) => {
      const newEducations: Record<string, IGraphNode> = {}

      forEach(organizations, (organization: IGraphOrganizationNode) => {
        if (organization.id) {
          newEducations[organization.id] = new OrganizationNode({
            organization,
          })
        }
      })

      context.setData(prevState => ({
        ...prevState,
        organizations: {
          ...prevState.organizations,
          ...newEducations,
        },
      }))
    },
    [context],
  )

  const handleSpawnCommunityUserTags = React.useCallback(
    (communityUserTags: IGraphCommunityUserTagNode[]) => {
      handleSpawnTags(communityUserTags.map(tag => tag.tag!))
    },
    [handleSpawnTags],
  )

  const handleSpawnCommunityUserSkills = React.useCallback(
    (communityUserSkills: IGraphCommunityUserSkillNode[]) => {
      handleSpawnSkills(communityUserSkills.map(skill => skill.skill!))
    },
    [handleSpawnSkills],
  )

  const handleSpawnCommunities = React.useCallback(
    (communities: IGraphCommunityNode[]) => {
      const newCommunities: Record<string, IGraphNode> = {}

      forEach(communities, (community: IGraphCommunityNode) => {
        if (community.id) {
          newCommunities[community.id] = new CommunityNode({ community })
        }
      })

      context.setData(prevState => ({
        ...prevState,
        communities: {
          ...prevState.communities,
          ...newCommunities,
        },
      }))
    },
    [context],
  )

  const handleSpawnCommunityUserWorkHistory = React.useCallback(
    (communityUserWorkHistory: IGraphCommunityUserWorkHistory[]) => {
      handleSpawnOrganization(
        communityUserWorkHistory.map(workHistory => workHistory.organization!),
      )
    },
    [handleSpawnOrganization],
  )

  const handleSpawnCommunityUserEducationHistory = React.useCallback(
    (communityUserEducationHistory: IGraphCommunityUserEducationHistory[]) => {
      handleSpawnEducation(
        communityUserEducationHistory.map(
          educationHistory => educationHistory.organization!,
        ),
      )
    },
    [handleSpawnEducation],
  )

  const handleSpawnCommunityUserCommunities = React.useCallback(
    (communities: IGraphCommunityNode[]) => {
      handleSpawnCommunities(communities)
    },
    [handleSpawnCommunities],
  )

  const handleStreamPeople = React.useCallback(
    async (communityIds: string[]): Promise<void> => {
      try {
        context.setLoading(true)

        for await (const people of streamPeopleHandler(communityIds)) {
          if (people?.length) {
            handleSpawnPeople(people)
          }
        }
      } catch {
        /* empty */
      }

      context.setLoading(false)
    },
    [context, handleSpawnPeople],
  )

  const handleLoadPeople = React.useCallback(
    async (args: IOnLoadPeopleArgs): Promise<void> => {
      try {
        if (args?.communityIds?.length) {
          context.setLoading(true)

          const result = await loadPeopleHandler({
            ...args,
          })

          handleSpawnPeople(result.nodes)
        }
      } catch {
        /* empty */
      }

      context.setLoading(false)
    },
    [context, handleSpawnPeople],
  )

  const handleLoadPeopleByDegrees = React.useCallback(
    async (degrees: number) => {
      try {
        if (!me || !community?.id) {
          return
        }

        context.setLoading(true)

        const result = await loadPeopleByDegrees(me, degrees, community?.id)

        handleSpawnPeople(result)
      } catch {
        /* empty */
      }

      context.setLoading(false)
    },
    [community?.id, context, handleSpawnPeople, me],
  )

  const handleLoadPerson = React.useCallback(
    async (args: IOnLoadPersonArgs): Promise<void> => {
      try {
        if (args?.communityId && args?.communityUserId) {
          context.setLoading(true)

          const result = await loadPersonHandler({
            ...args,
          })

          if (!result) {
            return
          }

          handleSpawnPeople([result])

          if (args.showTargetTags) {
            handleSpawnCommunityUserTags(
              result.communityUserTags?.filter(
                e => e.communityUserId === activeCommunityUserId,
              ) || [],
            )
          }
          if (args.showTargetSkills) {
            handleSpawnCommunityUserSkills(result.communityUserSkills || [])
          }
          if (args.showTargetOrganizations) {
            handleSpawnCommunityUserWorkHistory(
              result.communityUserWorkHistory || [],
            )
          }
          if (args.showTargetEducation) {
            handleSpawnCommunityUserEducationHistory(
              result.communityUserWorkHistory || [],
            )
          }
          if (args.showTargetCommunities) {
            handleSpawnCommunityUserCommunities(
              result.communities?.filter(
                e => e.id && !args?.excludeShowCommunities?.includes(e.id),
              ) || [],
            )
          }
          if (args.showTargetEducation) {
            handleSpawnCommunityUserEducationHistory(
              result.communityUserEducationHistory || [],
            )
          }
        }
      } catch {
        /* empty */
      }

      context.setLoading(false)
    },
    [
      context,
      handleSpawnPeople,
      handleSpawnCommunityUserTags,
      activeCommunityUserId,
      handleSpawnCommunityUserSkills,
      handleSpawnCommunityUserWorkHistory,
      handleSpawnCommunityUserEducationHistory,
      handleSpawnCommunityUserCommunities,
    ],
  )

  const handleLoadTags = React.useCallback(
    async (args?: ILoadTagsArgs): Promise<void> => {
      try {
        if (args?.communityIds?.length || community?.id) {
          context.setLoading(true)

          const result = await loadTagsHandler({
            ...args,
            communityIds: args?.communityIds || [community?.id!],
          })

          handleSpawnCommunityUserTags(result.nodes)
        }
      } catch {
        /* empty */
      }

      context.setLoading(false)
    },
    [community?.id, context, handleSpawnCommunityUserTags],
  )

  return {
    handleStreamPeople,
    handleLoadPeople,
    handleLoadPerson,
    handleLoadTags,
    handleLoadPeopleByDegrees,
    handleSpawnPeople,
    handleSpawnTags,
    handleSpawnSkills,
    handleSpawnOrganization,
    handleSpawnEducation,
    handleSpawnCommunityUserTags,
    handleSpawnCommunityUserSkills,
    handleSpawnCommunityUserWorkHistory,
    handleSpawnCommunityUserEducationHistory,
    handleSpawnCommunityUserCommunities,
    handleSpawnCommunities,
  } as IRegraphLoaders
}

export default useRegraphLoaders
