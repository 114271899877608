import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { IconSearch } from '@tabler/icons-react'
import { getFullName } from 'Utils/User'

import isNil from 'lodash/isNil'

import { Input, Loader } from 'Components/UI'

import { ACTION_KIND, ActionKind } from 'Constants/graph'

import { useCommunity, useKeys, useScrollToSelected } from 'Hooks'

import Highlight from './Highlight'
import { Container, Dropdown, Option } from './styles'
import useResource, {
  IKindOption,
  IOption,
  NEW_ID,
  NEW_ID_VALUES,
} from './useResource'
import utils from './utils'

const KEYBOARD_KEYS = new Set([
  'Up',
  'ArrowUp',
  'Down',
  'ArrowDown',
  'Enter',
  'Escape',
  'Backspace',
])

const PLACEHOLDER = {
  [ACTION_KIND.addCommunityUser]: 'Search or create a person',
  [ACTION_KIND.meetCommunityUser]: 'Search or create a meet person',
  [ACTION_KIND.skill]: 'Search or create a skill',
  [ACTION_KIND.event]: 'Search or create an event',
  [ACTION_KIND.project]: 'Search or create a project',
  [ACTION_KIND.group]: 'Search or create a group',
  [ACTION_KIND.role]: 'Search or create a role',
  [ACTION_KIND.custom]: 'Search or create a custom',
}

export type SearchSelectHandler = (option: IKindOption) => void

export interface ISearchProps {
  kind: ActionKind
  onBack: () => void
  onSelect: SearchSelectHandler
}

function Search({ kind, onSelect, onBack }: ISearchProps) {
  const { community } = useCommunity()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const [inputValue, setInputValue] = useState<string | null>(null)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [options, setOptions] = useState<IOption[]>([])

  const {
    fetchSkills,
    fetchTags,
    fetchCommunityUsers,
    createNewCommunityUser,
    createNewSkill,
    createNewTag,
    isLoading,
    isCreateNewLoading,
  } = useResource(community?.id)

  useScrollToSelected(dropdownRef, activeIndex)

  useKeys(event => {
    switch (event.key) {
      case 'Up':
      case 'ArrowUp':
        setActiveIndex(prevState =>
          prevState && prevState > 0 ? prevState - 1 : options.length - 1,
        )
        break
      case 'Down':
      case 'ArrowDown':
        setActiveIndex(prevState =>
          !isNil(prevState) ? (prevState + 1) % options.length : 0,
        )
        break
      case 'Enter':
        handleSelect(activeIndex ? options[activeIndex] : undefined).then()
        break
      case 'Backspace':
        if (!inputValue?.length) onBack()
        break
      case 'Escape':
        onBack()
        break

      default:
        break
    }
  }, KEYBOARD_KEYS)

  useLayoutEffect(() => {
    inputRef.current?.focus()
  }, [isLoading])

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async event => {
      const { value } = event.target
      let result: IOption[] = []

      setActiveIndex(null)
      setInputValue(value)

      switch (kind) {
        case ACTION_KIND.skill:
          result = await fetchSkills(value)
          break
        case ACTION_KIND.addCommunityUser:
        case ACTION_KIND.meetCommunityUser:
          result = await fetchCommunityUsers(value)
          break
        case ACTION_KIND.event:
        case ACTION_KIND.project:
        case ACTION_KIND.group:
        case ACTION_KIND.role:
        case ACTION_KIND.custom:
          result = await fetchTags(value, utils.actionKindToTagKind(kind)!)
          break
        default:
          break
      }

      setOptions(result)
    },
    [fetchSkills, fetchTags, fetchCommunityUsers, kind],
  )

  const handleSelect = useCallback(
    async (option?: IOption) => {
      if (!option || (NEW_ID_VALUES.includes(option.id) && isCreateNewLoading))
        return

      switch (option.id) {
        case NEW_ID.COMMUNITY_USER: {
          const communityUser = await createNewCommunityUser(option.label)

          if (communityUser) {
            onSelect({
              id: communityUser.id,
              label: getFullName(communityUser),
              kind,
            })
          }
          break
        }
        case NEW_ID.TAG: {
          const tag = await createNewTag(
            option.label,
            utils.actionKindToTagKind(kind)!,
          )

          if (tag) {
            onSelect({ id: tag.id, label: tag.name, kind })
          }
          break
        }
        case NEW_ID.SKILL: {
          const skill = await createNewSkill(option.label)

          if (skill) {
            onSelect({ id: skill.id, label: skill?.name || 'N/A', kind })
          }
          break
        }
        default:
          onSelect({ ...option, kind })
      }
    },
    [
      isCreateNewLoading,
      createNewCommunityUser,
      createNewSkill,
      createNewTag,
      onSelect,
      kind,
    ],
  )

  const memoizedOptions = useMemo(
    () =>
      options.map((option, index) => {
        const isNew = NEW_ID_VALUES.includes(option.id)
        const label = isNew ? `Create ${option.label}` : option.label
        const showLoader = isNew && isCreateNewLoading

        return (
          <Option
            active={index === activeIndex}
            disabled={showLoader}
            key={option.id}
            onClick={() => handleSelect(option)}
          >
            <Highlight word={isNew ? null : inputValue}>{label}</Highlight>
            {showLoader && <Loader ml={2} />}
          </Option>
        )
      }),
    [activeIndex, handleSelect, inputValue, options, isCreateNewLoading],
  )

  return (
    <Container>
      <Input
        isLoading={isLoading || isCreateNewLoading}
        placeholder={PLACEHOLDER[kind]}
        ref={inputRef}
        renderBeforeElement={() => <IconSearch />}
        onChange={handleChange}
      />

      {options?.length > 0 && (
        <Dropdown mt={2} ref={dropdownRef}>
          {memoizedOptions}
        </Dropdown>
      )}
    </Container>
  )
}

export default React.memo(Search)
