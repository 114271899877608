import React, { useCallback, useMemo, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import { format, isBefore, isFuture, parse } from 'date-fns'
import createCommunityUserWorkHistoryMutation from 'GraphQL/Mutations/CommunityUserWorkHistory/createCommunityUserWorkHistory.graphql'
import createJobTitleMutation from 'GraphQL/Mutations/JobTitle/createJobTitle.graphql'
import createOrganizationMutation from 'GraphQL/Mutations/Organization/createOrganization.graphql'
import validate from 'validate.js'

import JobTitleField, {
  JobTitleOption,
} from 'Components/Blocks/Forms/Fields/JobTitleField/JobTitleField'
import OrganizationField, {
  OrganizationOption,
} from 'Components/Blocks/Forms/Fields/OrganizationField/OrganizationField'
import {
  CheckboxField,
  Column,
  Icon,
  InputField,
  Modal,
  Row,
} from 'Components/UI'
import DateInputField from 'Components/UI/Form/DateInput/DateInputField'

import {
  FACEBOOK_REGEX,
  LINKEDIN_COMPANY_REGEX,
  TWITTER_REGEX,
  URL_REGEX,
} from 'Constants/regex'

import useCommunityContext from 'Hooks/useCommunityContext'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

enum CreateWorkHistoryFormField {
  Organization = 'organization',
  OrganizationWebsiteUrl = 'organizationWebsiteUrl',
  OrganizationLinkedInUrl = 'organizationLinkedInUrl',
  OrganizationTwitterUrl = 'organizationTwitterUrl',
  OrganizationFacebookUrl = 'organizationFacebookUrl',
  JobTitle = 'jobTitle',
  StartDate = 'startDate',
  EndDate = 'endDate',
  IsPrimary = 'isPrimary',
}

interface CreateWorkHistoryFormValues {
  [CreateWorkHistoryFormField.Organization]: OrganizationOption
  [CreateWorkHistoryFormField.OrganizationWebsiteUrl]: string
  [CreateWorkHistoryFormField.OrganizationLinkedInUrl]: string
  [CreateWorkHistoryFormField.OrganizationTwitterUrl]: string
  [CreateWorkHistoryFormField.OrganizationFacebookUrl]: string
  [CreateWorkHistoryFormField.JobTitle]: JobTitleOption
  [CreateWorkHistoryFormField.StartDate]: string
  [CreateWorkHistoryFormField.EndDate]: string
  [CreateWorkHistoryFormField.IsPrimary]: boolean
}

export interface CreateWorkHistoryModalProps {
  communityUser: MainSchema.CommunityUser
  onClose?: (success: boolean) => void
}

validate.validators.date = (value: string) => {
  const MONTH_DATE_REGEX = /^(0[1-9]|1[0-2])\/[0-9]{4}$/

  if (value && !MONTH_DATE_REGEX.test(value)) {
    return '^Date is invalid'
  }

  return null
}

function CreateWorkHistoryModal({
  communityUser,
  onClose,
}: CreateWorkHistoryModalProps) {
  const { community } = useCommunityContext()
  // Use the single/currently active community to load the propert communityUserId
  // IMPORTANT: When UI allows selecting more than one community, things will behave badly in the current UI implementation
  const communityUserId = useMemo(
    () =>
      communityUser?.communityUsers?.find(e => e.communityId === community?.id)
        ?.communityUserId || null,
    [communityUser, community],
  )
  const t = useScopedI18n('components.blocks.modals.createWorkHistory')
  const [isLoading, setIsLoading] = useState(false)
  const [createOrganization] = useMutation<
    Pick<MainSchema.Mutation, 'createOrganization'>,
    MainSchema.MutationCreateOrganizationArgs
  >(createOrganizationMutation)
  const [createJobTitle] = useMutation<
    Pick<MainSchema.Mutation, 'createJobTitle'>,
    MainSchema.MutationCreateJobTitleArgs
  >(createJobTitleMutation)
  const [createCommunityUserWorkHistory] = useMutation<
    Pick<MainSchema.Mutation, 'createCommunityUserWorkHistory'>,
    MainSchema.MutationCreateCommunityUserWorkHistoryArgs
  >(createCommunityUserWorkHistoryMutation)

  const createWorkHistoryFormConstraints = useMemo(() => {
    return {
      [CreateWorkHistoryFormField.Organization]: {
        type: 'object',
        presence: {
          allowEmpty: false,
          message: `^${t('form.organization.validation.required')}`,
        },
      },
      [CreateWorkHistoryFormField.OrganizationWebsiteUrl]: {
        type: 'string',
        format: {
          pattern: URL_REGEX,
          message: `^${t('form.organizationWebsiteUrl.validation.invalid')}`,
        },
      },
      [CreateWorkHistoryFormField.OrganizationLinkedInUrl]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_COMPANY_REGEX,
          message: `^${t('form.organizationLinkedInUrl.validation.invalid')}`,
        },
      },
      [CreateWorkHistoryFormField.OrganizationTwitterUrl]: {
        type: 'string',
        format: {
          pattern: TWITTER_REGEX,
          message: `^${t('form.organizationTwitterUrl.validation.invalid')}`,
        },
      },
      [CreateWorkHistoryFormField.OrganizationFacebookUrl]: {
        type: 'string',
        format: {
          pattern: FACEBOOK_REGEX,
          message: `^${t('form.organizationFacebookUrl.validation.invalid')}`,
        },
      },
      [CreateWorkHistoryFormField.JobTitle]: {
        type: 'object',
        presence: {
          allowEmpty: false,
          message: `^${t('form.jobTitle.validation.required')}`,
        },
      },
      [CreateWorkHistoryFormField.StartDate]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${t('form.startDate.validation.required')}`,
        },
        date: {
          message: `^${t('form.startDate.validation.invalid')}`,
        },
      },
    }
  }, [t])

  const submit = useCallback(
    async (values: CreateWorkHistoryFormValues) => {
      if (!communityUserId) {
        return
      }

      setIsLoading(true)

      try {
        const organization = values[CreateWorkHistoryFormField.Organization]
        const organizationWebsiteUrl =
          values[CreateWorkHistoryFormField.OrganizationWebsiteUrl]
        const organizationLinkedInUrl =
          values[CreateWorkHistoryFormField.OrganizationLinkedInUrl]
        const organizationTwitterUrl =
          values[CreateWorkHistoryFormField.OrganizationTwitterUrl]
        const organizationFacebookUrl =
          values[CreateWorkHistoryFormField.OrganizationFacebookUrl]
        const jobTitle = values[CreateWorkHistoryFormField.JobTitle]
        const isPrimary = values[CreateWorkHistoryFormField.IsPrimary]
        const startDate = parse(
          values[CreateWorkHistoryFormField.StartDate],
          'MM/yyyy',
          new Date(),
        )
        const endDate =
          !isPrimary && values[CreateWorkHistoryFormField.EndDate]
            ? parse(
                values[CreateWorkHistoryFormField.EndDate],
                'MM/yyyy',
                new Date(),
              )
            : null
        let organizationId = !organization.isNew
          ? organization?.value
          : undefined
        let jobTitleId = !jobTitle.isNew ? jobTitle?.value : undefined

        const isEndDateBeforeStartDate = endDate && isBefore(endDate, startDate)

        if (isEndDateBeforeStartDate) {
          throw new Error(t('form.startDate.validation.beforeEndDate'))
        }

        if (isFuture(startDate)) {
          throw new Error(t('form.startDate.validation.future'))
        }

        if (organization.isNew) {
          if (
            !organizationWebsiteUrl &&
            !organizationLinkedInUrl &&
            !organizationTwitterUrl &&
            !organizationFacebookUrl
          ) {
            throw new Error(t('form.organization.validation.socialUrlRequired'))
          }
        }

        if (organization && organization.isNew) {
          const organizationResult = await createOrganization({
            variables: {
              input: {
                name: organization.value,
                websiteUrl: organizationWebsiteUrl,
                linkedInUrl: organizationLinkedInUrl,
                twitterUrl: organizationTwitterUrl,
                facebookUrl: organizationFacebookUrl,
              },
            },
          })

          if (organizationResult.data?.createOrganization.id) {
            organizationId = organizationResult.data.createOrganization.id
          }
        }

        if (jobTitle && jobTitle.isNew) {
          const jobTitleResult = await createJobTitle({
            variables: {
              input: {
                name: jobTitle.value,
              },
            },
          })

          if (jobTitleResult.data?.createJobTitle.id) {
            jobTitleId = jobTitleResult.data.createJobTitle.id
          }
        }

        if (!organizationId) {
          throw new Error('organizationId is required')
        }

        if (!jobTitleId) {
          throw new Error('jobTitleId is required')
        }

        await createCommunityUserWorkHistory({
          variables: {
            input: {
              communityUserId,
              organizationId,
              jobTitleId,
              startDate: format(startDate, 'yyyy-MM-dd'),
              endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
              isPrimary,
            },
          },
        })

        EventBus.trigger(EventBus.actions.profile.reload)

        // TODO: update graph

        toast.success({
          title: t('toast.title'),
          text: t('toast.success'),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      onClose,
      t,
      createOrganization,
      createJobTitle,
      createCommunityUserWorkHistory,
      communityUserId,
    ],
  )

  const renderForm = useCallback(
    ({
      handleSubmit,
      valid,
      values,
    }: FormRenderProps<CreateWorkHistoryFormValues>) => {
      const isCreatingOrganization =
        values[CreateWorkHistoryFormField.Organization]?.isNew

      return (
        <Modal
          cancelText={t('form.cancel')}
          confirmDisabled={isLoading || !valid}
          confirmText={t('form.submit')}
          isOpen
          title={t('title')}
          width="456px"
          onClose={() => onClose?.(false)}
          onConfirm={handleSubmit}
        >
          <Column gap={3}>
            <OrganizationField
              isRequired
              label={t('form.organization.label')}
              name={CreateWorkHistoryFormField.Organization}
              placeholder={t('form.organization.placeholder')}
            />

            {isCreatingOrganization && (
              <>
                <InputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.organizationWebsiteUrl.label')}
                  name={CreateWorkHistoryFormField.OrganizationWebsiteUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationLinkedInUrl.label')}
                      <Icon.LinkedIn size={10} />
                    </Row>
                  }
                  name={CreateWorkHistoryFormField.OrganizationLinkedInUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationTwitterUrl.label')}
                      <Icon.Twitter size={10} />
                    </Row>
                  }
                  name={CreateWorkHistoryFormField.OrganizationTwitterUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationFacebookUrl.label')}
                      <Icon.Facebook size={10} />
                    </Row>
                  }
                  name={CreateWorkHistoryFormField.OrganizationFacebookUrl}
                />
              </>
            )}

            <JobTitleField
              isRequired
              label={t('form.jobTitle.label')}
              name={CreateWorkHistoryFormField.JobTitle}
              placeholder={t('form.jobTitle.placeholder')}
            />

            <Row gap={6} spaceBetween>
              <Column flex={1} gap={2}>
                <DateInputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.startDate.label')}
                  name={CreateWorkHistoryFormField.StartDate}
                  required
                />
              </Column>

              <Column flex={1} gap={2}>
                <DateInputField
                  checkErrorIfDirty
                  clearable
                  disabled={values[CreateWorkHistoryFormField.IsPrimary]}
                  label={t('form.endDate.label')}
                  name={CreateWorkHistoryFormField.EndDate}
                />

                <CheckboxField
                  label={t('form.current.label')}
                  name={CreateWorkHistoryFormField.IsPrimary}
                />
              </Column>
            </Row>
          </Column>
        </Modal>
      )
    },
    [isLoading, onClose, t],
  )

  return (
    <Form<CreateWorkHistoryFormValues>
      render={renderForm}
      validate={values => validate(values, createWorkHistoryFormConstraints)}
      onSubmit={submit}
    />
  )
}

export default CreateWorkHistoryModal
