import styled from 'styled-components'

import { LinkButton } from 'Components/UI'

export const Content = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 5px;
`

export const Text = styled.span`
  color: #545454;
  width: 100%;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  padding: 0;
`

export const Link = styled(LinkButton)`
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`
