import React from 'react'

import { Column, Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import colors from 'Theme/_v2/colors'

import AddButton from '../../Buttons/AddButton'

export interface BlockTitleProps {
  icon: React.ReactNode
  title: string
  showPlusButton?: boolean
  onPlusClicked?: () => Promise<void> | undefined
}

export default function SectionTitle({
  icon,
  title,
  showPlusButton = false,
  onPlusClicked,
}: BlockTitleProps) {
  return (
    <Row alignItems={'center'} minWidth={'120px'}>
      <Column>{icon}</Column>
      <Column ml={2}>
        <Text
          color={colors.text.secondary}
          fontSize={'14px'}
          fontWeight={400}
          lineHeight={'16px'}
        >
          {title}
        </Text>
      </Column>
      {onPlusClicked && showPlusButton && (
        <Column>
          <AddButton onClick={onPlusClicked} />
        </Column>
      )}
    </Row>
  )
}
