import React from 'react'

import { IconCheck, IconPlus } from '@tabler/icons-react'
import Utils from 'Utils'

import { Avatar, Column, Text } from 'Components/UI'

import * as Styled from '../styles'

interface MergeProfileItemProps {
  communityUser: MainSchema.CommunityUser
  selectedToMerge: MainSchema.CommunityUser[]
  handleAddToMerge: (communityUser: MainSchema.CommunityUser) => void
}

const MergeProfileItem = ({
  communityUser,
  selectedToMerge,
  handleAddToMerge,
}: MergeProfileItemProps) => {
  const {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    linkedInUrl,
    twitterUrl,
    facebookUrl,
    photoUrl,
  } = communityUser
  const name = Utils.User.getFullName({ firstName, lastName })

  return (
    <Styled.ProfileItem key={id}>
      <Avatar small src={photoUrl} />
      <Column>
        <Text bold>{name}</Text>
        <Text bodyMedium color="text.body">
          {email || phoneNumber || linkedInUrl || facebookUrl || twitterUrl}
        </Text>
      </Column>

      <Styled.AddButton
        primary={selectedToMerge.some(cu => cu.id === id)}
        secondary={!selectedToMerge.some(cu => cu.id === id)}
        onClick={() => handleAddToMerge(communityUser)}
      >
        {selectedToMerge.some(cu => cu.id === id) ? (
          <IconCheck size={16} />
        ) : (
          <IconPlus size={16} />
        )}
      </Styled.AddButton>
    </Styled.ProfileItem>
  )
}

export default MergeProfileItem
