import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

import smartlookClient from 'smartlook-client'

import { TELEMETRY } from 'Config'

function useTelemetry(
  communityUser?: MainSchema.CommunityUser,
  userId?: string,
) {
  const location = useLocation()
  const [smartlookInitialized, setSmartlookInitialized] =
    useState<boolean>(false)
  const [googleInitialized, setGoogleInitialized] = useState<boolean>(false)

  useEffect(() => {
    // Only if user is logged in
    if (userId) {
      // Moving this here, users who are not logged in create garbage data and spam, if we wait until
      // user is authenticated, we get cleaner data. Moved from global scope back into useTelemetry function
      if (TELEMETRY.SMARTLOOK_KEY && !smartlookInitialized) {
        smartlookClient.init(TELEMETRY.SMARTLOOK_KEY)

        smartlookClient.identify(userId, {
          name: `${communityUser?.firstName} ${communityUser?.lastName}`,
          email: communityUser?.email || 'N/A',
        })

        setSmartlookInitialized(true)
      }

      if (TELEMETRY.GOOGLE_ANALYTICS_KEY && !googleInitialized) {
        ReactGA.initialize(TELEMETRY.GOOGLE_ANALYTICS_KEY)

        ReactGA.set({
          userId,
        })
        setGoogleInitialized(true)
      }
    }
  }, [communityUser, smartlookInitialized, googleInitialized, userId])

  useEffect(() => {
    // Transmit only if initialized and location changes
    if (googleInitialized) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname })
    }
  }, [location, googleInitialized])

  return { googleInitialized, smartlookInitialized }
}

export default useTelemetry
