import { ApolloCache, MutationUpdaterFunction } from '@apollo/client'
import communityUserConnectionsQuery from 'Features/ProfilePanel/Queries/communityUserConnections.graphql'

import forEach from 'lodash/forEach'

type IDeleteCommunityUserConnections = MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'deleteCommunityUserConnections'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
>

export function DeleteCommunityUserUpdater({
  communityIds,
  communityUserConnectionIds,
  fromCommunityUserId,
  toCommunityUserId,
  refetchConnections,
}: {
  communityIds: string[]
  communityUserConnectionIds?: string[]
  fromCommunityUserId?: string
  toCommunityUserId?: string
  refetchConnections: () => void
}): IDeleteCommunityUserConnections {
  return (cache, { data }) => {
    if (!data?.deleteCommunityUserConnections.ok) return

    const variables: any = {
      communityIds,
    }
    if (communityUserConnectionIds) {
      variables.communityUserConnectionIds = communityUserConnectionIds
    }
    if (fromCommunityUserId) {
      variables.fromCommunityUserId = fromCommunityUserId
    }
    if (toCommunityUserId) {
      variables.toCommunityUserId = toCommunityUserId
    }

    const queryCommunityUserConnections = {
      query: communityUserConnectionsQuery,
      variables,
    }

    const communityUserConnectionsCacheData = cache.readQuery<
      Pick<MainSchema.Query, 'communityUserConnections'>,
      MainSchema.QueryCommunityUserConnectionsArgs
    >(queryCommunityUserConnections)

    if (!communityUserConnectionsCacheData) {
      refetchConnections?.()
      return
    }

    const updatedRowCache: MainSchema.CommunityUserConnection[] = []

    let updatedCount =
      communityUserConnectionsCacheData?.communityUserConnections?.count || 0

    forEach(
      communityUserConnectionsCacheData?.communityUserConnections?.rows,
      row => {
        if (communityUserConnectionIds?.includes(row.fromCommunityUserId)) {
          updatedCount -= 1
        } else {
          updatedRowCache.push(row)
        }
      },
    )

    cache.writeQuery<
      Pick<MainSchema.Query, 'communityUserConnections'>,
      MainSchema.QueryCommunityUserConnectionsArgs
    >({
      ...queryCommunityUserConnections,
      data: {
        ...communityUserConnectionsCacheData,
        communityUserConnections: {
          ...communityUserConnectionsCacheData?.communityUserConnections,
          rows: updatedRowCache,
          count: updatedCount,
          pages:
            communityUserConnectionsCacheData?.communityUserConnections
              ?.pages || 0,
        },
      },
    })
  }
}
