import React from 'react'

import { Tag, Text } from 'Components/UI'
import { ITagMouseEvent } from 'Components/UI/Tag/styles'

import * as Styled from './TagList.styles'

export interface ITag {
  id: string
  name: string
  colorKind: string
  renderAfter?: React.ReactNode
  onClick?: (e: ITagMouseEvent) => void
}

export interface ITagListProps {
  label: React.ReactNode
  items: ITag[]
}

const TagList = (props: ITagListProps) => {
  const uniqueItems = React.useMemo(
    () =>
      [...new Set(props.items.map(item => item.id))].map(
        id => props.items.find(item => item.id === id)!,
      ),
    [props.items],
  )

  return (
    <>
      <Text header header4 mb={1}>
        {props.label}
      </Text>

      <Styled.TagsContainer mb={4}>
        {uniqueItems.map(item => (
          <Tag
            colorKind={item.colorKind}
            key={item.id}
            renderAfter={item.renderAfter}
            small
            text={item.name}
            onClick={event => item.onClick?.(event)}
          />
        ))}
      </Styled.TagsContainer>
    </>
  )
}

export default TagList
