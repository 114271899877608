import { ApolloCache, MutationUpdaterFunction } from '@apollo/client'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'

import forEach from 'lodash/forEach'

interface ICommunityUser {
  communityUserId: string
  communityId: string
}

type IGetCommunityUserSkillsUpdater = MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'connectUsersToSkills'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
>

export function getCommunityUserSkillsUpdater({
  communityUsers,
  communityIds,
  skills,
}: {
  communityUsers: ICommunityUser[]
  communityIds: string[]
  skills: any[]
}): IGetCommunityUserSkillsUpdater {
  return (cache, { data }) => {
    if (!data?.connectUsersToSkills) return

    forEach(communityUsers, async communityUser => {
      const { communityUserId } = communityUser

      const queryGetCommunityUser = {
        query: getCommunityUserQuery,
        variables: {
          communityUserId,
          communityIds,
        },
      }

      const getCommunityUserData = cache.readQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >(queryGetCommunityUser)

      if (getCommunityUserData?.getCommunityUser) {
        const combinedSkills: MainSchema.CommunityUserSkill[] = [
          ...(getCommunityUserData.getCommunityUser.communityUserSkills || []),
          ...skills,
        ]

        const skillsMap = new Map<string, MainSchema.CommunityUserSkill>()

        for (const skill of combinedSkills) {
          if (skill.skillId && !skillsMap.has(skill.skillId)) {
            skillsMap.set(skill.skillId, skill)
          }
        }

        const mergedSkills: MainSchema.CommunityUserSkill[] = Array.from(
          skillsMap.values(),
        )

        cache.writeQuery<
          Pick<MainSchema.Query, 'getCommunityUser'>,
          MainSchema.QueryGetCommunityUserArgs
        >({
          ...queryGetCommunityUser,
          data: {
            ...getCommunityUserData,
            getCommunityUser: {
              ...getCommunityUserData.getCommunityUser,
              communityUserSkills: mergedSkills,
            },
          },
        })
      }
    })
  }
}

export function getCommunityUserSkillRemoveUpdater({
  communityUserId,
  communityIds,
  skillIds,
}: {
  communityUserId: string
  communityIds: string[]
  skillIds: any[]
}): MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'disconnectUsersFromSkills'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
> {
  return (cache, { data }) => {
    if (!data?.disconnectUsersFromSkills) return

    const queryGetCommunityUser = {
      query: getCommunityUserQuery,
      variables: {
        communityUserId,
        communityIds,
      },
    }

    const getCommunityUserData = cache.readQuery<
      Pick<MainSchema.Query, 'getCommunityUser'>,
      MainSchema.QueryGetCommunityUserArgs
    >(queryGetCommunityUser)

    const filteredSkills =
      getCommunityUserData?.getCommunityUser?.communityUserSkills?.filter(
        communityUserSkill => !skillIds.includes(communityUserSkill.skillId),
      )

    if (
      getCommunityUserData?.getCommunityUser &&
      communityUserId &&
      communityIds.length
    ) {
      cache.writeQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        ...queryGetCommunityUser,
        data: {
          ...getCommunityUserData,
          getCommunityUser: {
            ...getCommunityUserData.getCommunityUser,
            communityUserSkills: filteredSkills,
          },
        },
      })
    }
  }
}
