import React, { useCallback, useMemo, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import { format, isBefore, isFuture, parse, parseISO } from 'date-fns'
import arrayMutators from 'final-form-arrays'
import updateCommunityUserEducationHistoryMutation from 'GraphQL/Mutations/CommunityUserEducationHistory/updateCommunityUserEducationHistory.graphql'
import createOrganizationMutation from 'GraphQL/Mutations/Organization/createOrganization.graphql'
import validate from 'validate.js'

import OrganizationField, {
  OrganizationOption,
} from 'Components/Blocks/Forms/Fields/OrganizationField/OrganizationField'
import { Column, Icon, InputField, Modal, Row } from 'Components/UI'
import DateInputField from 'Components/UI/Form/DateInput/DateInputField'
import InputArrayField, {
  EMPTY_VALUE,
  InputArrayFieldItem,
} from 'Components/UI/Form/InputArray/InputArrayField'

import {
  FACEBOOK_REGEX,
  LINKEDIN_COMPANY_REGEX,
  TWITTER_REGEX,
  URL_REGEX,
} from 'Constants/regex'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

enum UpdateEducationHistoryFormField {
  Organization = 'organization',
  OrganizationWebsiteUrl = 'organizationWebsiteUrl',
  OrganizationLinkedInUrl = 'organizationLinkedInUrl',
  OrganizationTwitterUrl = 'organizationTwitterUrl',
  OrganizationFacebookUrl = 'organizationFacebookUrl',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Degrees = 'degrees',
  Majors = 'majors',
  Minors = 'minors',
}

interface UpdateEducationHistoryFormValues {
  [UpdateEducationHistoryFormField.Organization]: OrganizationOption
  [UpdateEducationHistoryFormField.OrganizationWebsiteUrl]: string
  [UpdateEducationHistoryFormField.OrganizationLinkedInUrl]: string
  [UpdateEducationHistoryFormField.OrganizationTwitterUrl]: string
  [UpdateEducationHistoryFormField.OrganizationFacebookUrl]: string
  [UpdateEducationHistoryFormField.StartDate]: string
  [UpdateEducationHistoryFormField.EndDate]: string
  [UpdateEducationHistoryFormField.Degrees]: InputArrayFieldItem[]
  [UpdateEducationHistoryFormField.Majors]: InputArrayFieldItem[]
  [UpdateEducationHistoryFormField.Minors]: InputArrayFieldItem[]
}

export interface EditEducationHistoryModalProps {
  communityUserEducationHistory: MainSchema.CommunityUserEducationHistory
  onClose?: (success: boolean) => void
}

validate.validators.date = (value: string) => {
  const MONTH_DATE_REGEX = /^(0[1-9]|1[0-2])\/[0-9]{4}$/

  if (value && !MONTH_DATE_REGEX.test(value)) {
    return '^Date is invalid'
  }

  return null
}

export default function UpdateEducationHistoryModal({
  communityUserEducationHistory,
  onClose,
}: EditEducationHistoryModalProps) {
  const t = useScopedI18n('components.blocks.modals.updateEducationHistory')
  const [isLoading, setIsLoading] = useState(false)
  const [createOrganization] = useMutation<
    Pick<MainSchema.Mutation, 'createOrganization'>,
    MainSchema.MutationCreateOrganizationArgs
  >(createOrganizationMutation)
  const [updateCommunityUserEducationHistory] = useMutation<
    Pick<MainSchema.Mutation, 'updateCommunityUserEducationHistory'>,
    MainSchema.MutationUpdateCommunityUserEducationHistoryArgs
  >(updateCommunityUserEducationHistoryMutation)

  const updateEducationHistoryFormConstraints = useMemo(() => {
    return {
      [UpdateEducationHistoryFormField.Organization]: {
        type: 'object',
        presence: {
          allowEmpty: false,
          message: `^${t('form.organization.validation.required')}`,
        },
      },
      [UpdateEducationHistoryFormField.OrganizationWebsiteUrl]: {
        type: 'string',
        format: {
          pattern: URL_REGEX,
          message: `^${t('form.organizationWebsiteUrl.validation.invalid')}`,
        },
      },
      [UpdateEducationHistoryFormField.OrganizationLinkedInUrl]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_COMPANY_REGEX,
          message: `^${t('form.organizationLinkedInUrl.validation.invalid')}`,
        },
      },
      [UpdateEducationHistoryFormField.OrganizationTwitterUrl]: {
        type: 'string',
        format: {
          pattern: TWITTER_REGEX,
          message: `^${t('form.organizationTwitterUrl.validation.invalid')}`,
        },
      },
      [UpdateEducationHistoryFormField.OrganizationFacebookUrl]: {
        type: 'string',
        format: {
          pattern: FACEBOOK_REGEX,
          message: `^${t('form.organizationFacebookUrl.validation.invalid')}`,
        },
      },
      [UpdateEducationHistoryFormField.StartDate]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${t('form.startDate.validation.required')}`,
        },
        date: {
          message: `^${t('form.startDate.validation.invalid')}`,
        },
      },
    }
  }, [t])

  const initialValues = useMemo(() => {
    return {
      [UpdateEducationHistoryFormField.Organization]:
        communityUserEducationHistory.educationHistory.organization
          ? ({
              label:
                communityUserEducationHistory.educationHistory.organization
                  .name,
              value:
                communityUserEducationHistory.educationHistory.organization.id,
              isNew: false,
            } as OrganizationOption)
          : undefined,
      [UpdateEducationHistoryFormField.StartDate]: communityUserEducationHistory
        .educationHistory.startDate
        ? format(
            parseISO(communityUserEducationHistory.educationHistory.startDate),
            'MM/yyyy',
          )
        : undefined,
      [UpdateEducationHistoryFormField.EndDate]: communityUserEducationHistory
        .educationHistory.endDate
        ? format(
            parseISO(communityUserEducationHistory.educationHistory.endDate),
            'MM/yyyy',
          )
        : undefined,
      [UpdateEducationHistoryFormField.Degrees]:
        communityUserEducationHistory.educationHistory.degrees &&
        communityUserEducationHistory.educationHistory.degrees.length > 0
          ? communityUserEducationHistory.educationHistory.degrees.map(
              degree => ({
                name: degree,
              }),
            )
          : [EMPTY_VALUE],
      [UpdateEducationHistoryFormField.Majors]:
        communityUserEducationHistory.educationHistory.majors &&
        communityUserEducationHistory.educationHistory.majors.length > 0
          ? communityUserEducationHistory.educationHistory.majors.map(
              major => ({
                name: major,
              }),
            )
          : [EMPTY_VALUE],
      [UpdateEducationHistoryFormField.Minors]:
        communityUserEducationHistory.educationHistory.minors &&
        communityUserEducationHistory.educationHistory.minors.length > 0
          ? communityUserEducationHistory.educationHistory.minors.map(
              minor => ({
                name: minor,
              }),
            )
          : [EMPTY_VALUE],
    }
  }, [communityUserEducationHistory])

  const submit = useCallback(
    async (values: UpdateEducationHistoryFormValues) => {
      setIsLoading(true)

      try {
        const organization =
          values[UpdateEducationHistoryFormField.Organization]
        const startDate = parse(
          values[UpdateEducationHistoryFormField.StartDate],
          'MM/yyyy',
          new Date(),
        )
        const endDate = values[UpdateEducationHistoryFormField.EndDate]
          ? parse(
              values[UpdateEducationHistoryFormField.EndDate],
              'MM/yyyy',
              new Date(),
            )
          : null
        let organizationId = !organization.isNew
          ? organization?.value
          : undefined

        const isEndDateBeforeStartDate = endDate && isBefore(endDate, startDate)

        if (isEndDateBeforeStartDate) {
          throw new Error(t('form.startDate.validation.beforeEndDate'))
        }

        if (isFuture(startDate)) {
          throw new Error(t('form.startDate.validation.future'))
        }

        if (organization && organization.isNew) {
          const organizationResult = await createOrganization({
            variables: {
              input: {
                name: organization.value,
                websiteUrl:
                  values[
                    UpdateEducationHistoryFormField.OrganizationWebsiteUrl
                  ],
                linkedInUrl:
                  values[
                    UpdateEducationHistoryFormField.OrganizationLinkedInUrl
                  ],
                twitterUrl:
                  values[
                    UpdateEducationHistoryFormField.OrganizationTwitterUrl
                  ],
                facebookUrl:
                  values[
                    UpdateEducationHistoryFormField.OrganizationFacebookUrl
                  ],
              },
            },
          })

          if (organizationResult.data?.createOrganization.id) {
            organizationId = organizationResult.data.createOrganization.id
          }
        }

        await updateCommunityUserEducationHistory({
          variables: {
            input: {
              communityUserId: communityUserEducationHistory.communityUserId,
              id: communityUserEducationHistory.educationHistory.id,
              organizationId,
              startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
              endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
              degrees: values[UpdateEducationHistoryFormField.Degrees]
                ? values[UpdateEducationHistoryFormField.Degrees]
                    .map(degree => degree.name)
                    .filter(Boolean)
                : null,
              majors: values[UpdateEducationHistoryFormField.Majors]
                ? values[UpdateEducationHistoryFormField.Majors]
                    .map(major => major.name)
                    .filter(Boolean)
                : null,
              minors: values[UpdateEducationHistoryFormField.Minors]
                ? values[UpdateEducationHistoryFormField.Minors]
                    .map(minor => minor.name)
                    .filter(Boolean)
                : null,
            },
          },
        })

        EventBus.trigger(EventBus.actions.profile.reload)

        // TODO: update graph

        toast.success({
          title: t('toast.title'),
          text: t('toast.success'),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      onClose,
      t,
      createOrganization,
      updateCommunityUserEducationHistory,
      communityUserEducationHistory,
    ],
  )

  const renderForm = useCallback(
    ({
      handleSubmit,
      valid,
      values,
    }: FormRenderProps<UpdateEducationHistoryFormValues>) => {
      const isCreatingOrganization =
        values[UpdateEducationHistoryFormField.Organization]?.isNew

      return (
        <Modal
          cancelText={t('form.cancel')}
          confirmDisabled={isLoading || !valid}
          confirmText={t('form.submit')}
          isOpen
          title={t('title')}
          width="456px"
          onClose={() => onClose?.(false)}
          onConfirm={handleSubmit}
        >
          <Column gap={3}>
            <OrganizationField
              isRequired
              label={t('form.organization.label')}
              name={UpdateEducationHistoryFormField.Organization}
              placeholder={t('form.organization.placeholder')}
            />

            {isCreatingOrganization && (
              <>
                <InputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.organizationWebsiteUrl.label')}
                  name={UpdateEducationHistoryFormField.OrganizationWebsiteUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationLinkedInUrl.label')}
                      <Icon.LinkedIn size={10} />
                    </Row>
                  }
                  name={UpdateEducationHistoryFormField.OrganizationLinkedInUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationTwitterUrl.label')}
                      <Icon.Twitter size={10} />
                    </Row>
                  }
                  name={UpdateEducationHistoryFormField.OrganizationTwitterUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationFacebookUrl.label')}
                      <Icon.Facebook size={10} />
                    </Row>
                  }
                  name={UpdateEducationHistoryFormField.OrganizationFacebookUrl}
                />
              </>
            )}

            <InputArrayField
              addLabel={t('form.degrees.add.label')}
              label={t('form.degrees.label')}
              name={UpdateEducationHistoryFormField.Degrees}
              removeLabel={t('form.degrees.remove.label')}
            />

            <InputArrayField
              addLabel={t('form.majors.add.label')}
              label={t('form.majors.label')}
              name={UpdateEducationHistoryFormField.Majors}
              removeLabel={t('form.majors.remove.label')}
            />

            <InputArrayField
              addLabel={t('form.minors.add.label')}
              label={t('form.minors.label')}
              name={UpdateEducationHistoryFormField.Minors}
              removeLabel={t('form.minors.remove.label')}
            />

            <Row gap={6} spaceBetween>
              <Column flex={1} gap={2}>
                <DateInputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.startDate.label')}
                  name={UpdateEducationHistoryFormField.StartDate}
                  required
                />
              </Column>

              <Column flex={1} gap={2}>
                <DateInputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.endDate.label')}
                  name={UpdateEducationHistoryFormField.EndDate}
                />
              </Column>
            </Row>
          </Column>
        </Modal>
      )
    },
    [isLoading, onClose, t],
  )

  return (
    <Form<UpdateEducationHistoryFormValues>
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={renderForm}
      validate={values =>
        validate(values, updateEducationHistoryFormConstraints)
      }
      onSubmit={submit}
    />
  )
}
