import React, { HTMLAttributes } from 'react'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'

import styled, { css } from 'styled-components'
import { margin, MarginProps, position, PositionProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { TAG_COLOR_KIND } from 'Constants/tags'

import { customScrollbar } from 'Theme/styles'

export interface IContainerProps extends MarginProps {}

export const Container = styled.div<IContainerProps>`
  min-height: 120px;
  position: relative;

  [contenteditable='true']:empty:before {
    content: attr(placeholder);
    display: block;
    color: ${themeGet('colors.primaryNeutral100')};
  }

  ${margin}
`

export interface IDisabledContentEditableTextAreaCssProps {
  disabled?: boolean
}

export const disabledContentEditableTextAreaCss = ({
  disabled,
}: IDisabledContentEditableTextAreaCssProps) =>
  disabled &&
  css`
    cursor: default;
    color: ${themeGet('colors.text.disabled')};
    border-color: ${themeGet('colors.divider.disabled')};
  `

export type AppContentEditableChangeHandler = (
  event: ContentEditableEvent,
) => void

export interface IAppContentEditableProps
  extends Exclude<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  html: string
  disabled?: boolean
  tagName?: string
  className?: string
  style?: Object
  innerRef?: React.RefObject<HTMLElement> | Function
  onChange: AppContentEditableChangeHandler
  el?: React.RefObject<HTMLTextAreaElement>
  placeholder?: string
}

export const ContentEditableTextArea = styled(
  ContentEditable,
)<IAppContentEditableProps>`
  position: relative;
  width: 100%;
  overflow: visible;
  box-sizing: border-box;
  outline: 0;
  height: 100%;
  min-height: 120px;
  z-index: 1;
  background: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.primaryNeutral20')};
  border-radius: 4px;
  padding: ${themeGet('space.3')}px;
  line-height: 20px;
  word-break: break-word;
  white-space: pre-wrap;

  ${disabledContentEditableTextAreaCss}

  .mention {
    position: relative;
    display: inline-block;
    padding: 0 ${themeGet('space.3')}px;
    gap: ${themeGet('space.1')}px;
    line-height: ${themeGet('space.6')}px;
    margin-bottom: 2px;

    &.addCommunityUser {
      color: ${themeGet(`colors.tag.${TAG_COLOR_KIND.USER}.color`)};
      background: ${themeGet(`colors.tag.${TAG_COLOR_KIND.USER}.bg`)};
    }

    &.meetCommunityUser {
      color: ${themeGet(`colors.tag.${TAG_COLOR_KIND.CONNECT}.color`)};
      background: ${themeGet(`colors.tag.${TAG_COLOR_KIND.CONNECT}.bg`)};
    }

    &.skill {
      color: ${themeGet(`colors.tag.${TAG_COLOR_KIND.SKILL}.color`)};
      background: ${themeGet(`colors.tag.${TAG_COLOR_KIND.SKILL}.bg`)};
    }

    &.event {
      color: ${themeGet(`colors.tag.${TAG_COLOR_KIND.EVENT}.color`)};
      background: ${themeGet(`colors.tag.${TAG_COLOR_KIND.EVENT}.bg`)};
    }
    &.project {
      color: ${themeGet(`colors.tag.${TAG_COLOR_KIND.PROJECT}.color`)};
      background: ${themeGet(`colors.tag.${TAG_COLOR_KIND.PROJECT}.bg`)};
    }
    &.group {
      color: ${themeGet(`colors.tag.${TAG_COLOR_KIND.GROUP}.color`)};
      background: ${themeGet(`colors.tag.${TAG_COLOR_KIND.GROUP}.bg`)};
    }
    &.role {
      color: ${themeGet(`colors.tag.${TAG_COLOR_KIND.ROLE}.color`)};
      background: ${themeGet(`colors.tag.${TAG_COLOR_KIND.ROLE}.bg`)};
    }
    &.custom {
      color: ${themeGet(`colors.tag.${TAG_COLOR_KIND.CUSTOM}.color`)};
      background: ${themeGet(`colors.tag.${TAG_COLOR_KIND.CUSTOM}.bg`)};
    }

    &::after {
      content: attr(data-tooltip);
      display: none;
      position: absolute;
      transform: translateX(-50%);
      white-space: nowrap;
      padding: 0 ${themeGet('space.3')}px;
      color: ${themeGet('colors.white')};
      z-index: 10;
      background: ${themeGet('colors.primaryNeutral90')};
      border-radius: 4px;
    }

    &:hover::after {
      display: block;
      width: max-content;
      top: -30px;
      left: 50%;
    }

    &:hover::before {
      display: block;
    }

    &::before {
      content: '';
      display: none;
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      border: 6px solid transparent;
      border-top-color: #495055;
    }
  }
`

export interface IVisibilityCommandDropdownContainerCssProps {
  visible?: boolean
}

const visibilityCommandDropdownContainerCss = ({
  visible,
}: IVisibilityCommandDropdownContainerCssProps) =>
  visible &&
  css`
    visibility: visible;
  `

export interface IScrollableCommandDropdownContainerCssProps {
  scrollable?: boolean
}

const scrollableCommandDropdownContainerCss = ({
  scrollable,
}: IScrollableCommandDropdownContainerCssProps) =>
  scrollable &&
  css`
    ${customScrollbar};

    overflow: auto;
    max-height: 250px;
  `

export interface ICommandDropdownContainerProps
  extends IVisibilityCommandDropdownContainerCssProps,
    IScrollableCommandDropdownContainerCssProps,
    PositionProps {}

export const CommandDropdownContainer = styled.div<ICommandDropdownContainerProps>`
  position: absolute;
  z-index: 1001;
  background: ${themeGet('colors.white')};
  box-shadow: 0 12px 16px -4px rgba(154, 161, 167, 0.2);
  border-radius: 4px;
  min-width: 250px;
  visibility: hidden;

  ${visibilityCommandDropdownContainerCss};
  ${scrollableCommandDropdownContainerCss};
  ${position};
`
