import React, { useMemo } from 'react'
import { MultiValue } from 'react-select'

import { useApolloClient, useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import useRegraphLoaders from 'Features/Graph/useRegraphLoaders'
import {
  IGraphCommunityNode,
  IGraphSkillNode,
} from 'Features/GraphNodes/NodeTypes'
import ShowMore from 'Features/ProfilePanel/Blocks//Buttons/ShowMore'
import { IconSkillTag } from 'Features/ProfilePanel/Blocks//icons.styles'
import Card from 'Features/ProfilePanel/Blocks/Card'
import SectionTitle from 'Features/ProfilePanel/Blocks/Overview/SectionTitle/SectionTitle'
import connectUsersToSkillsMutation from 'Features/ProfilePanel/Mutations/connectUsersToSkills.graphql'
import createSkillsMutation from 'Features/ProfilePanel/Mutations/createSkills.graphql'
import listSkillsQuery from 'Features/ProfilePanel/Queries/listSkills.graphql'
import { getCommunityUserSkillsUpdater } from 'Features/ProfilePanel/Updaters/GetCommunityUserSkillsUpdater'
import { setMinSearchLength } from 'Utils/Form'
import { ISkillOption, ISkillOptionInput, tagsToOptions } from 'Utils/Options'

import { Column, Loader, Row, Tag, Tooltip } from 'Components/UI'
import { ITagMouseEvent } from 'Components/UI/Tag/styles'

import {
  DEFAULT_MIN_SEARCH_SIZE,
  DEFAULT_SEARCH_DEBOUNCE,
  SkillKind,
} from 'Constants/ids'
import { TAG_COLOR_KIND } from 'Constants/tags'

import { useAppContext, useCommunity } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import InlineDropdownCreate from '../InlineDropdownCreate/InlineDropdownCreate'

export interface ISkillProps extends MainSchema.CommunityUserSkill {
  community?: MainSchema.Community
}

export interface ISkillBlock extends React.PropsWithChildren {
  communityUserSkills?: ISkillProps[]
  communityUser: MainSchema.CommunityUser
  onRemove?: (skillId: string, communityUserId: string) => void
}

function Skills({
  children,
  communityUserSkills = [],
  communityUser,
  onRemove,
}: ISkillBlock) {
  const s = useScopedI18n('features.profilePanel.overview.skills')

  const { community } = useCommunity()
  const { me } = useAppContext()

  const { isFeatureEnabled } = useFeatureFlag(['regraph'])
  const { handleSpawnCommunities, handleSpawnSkills } = useRegraphLoaders()
  const { focusNodes } = useRegraphHandlers()

  const client = useApolloClient()

  const [skillsToCreate, setSkillsToCreate] = React.useState<
    MainSchema.CreateSkillInput[]
  >([])

  const [existingSkills, setExistingSkills] = React.useState<
    MainSchema.Skill[]
  >([])

  const [isAdding, setIsAdding] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)

  // Use the single/currently active community to load the proper communityUserId
  // IMPORTANT: When UI allows selecting more than one community, things will behave badly in the current UI implementation
  const communityUserId = useMemo(
    () =>
      communityUser?.communityUsers?.find(e => e.communityId === community?.id)
        ?.communityUserId || null,
    [communityUser, community],
  )

  const { icon, tagKind } = React.useMemo(
    () => ({
      icon: <IconSkillTag color={colors.icon.profile} height={12} width={12} />,
      title: 'Skills',
      tagKind: TAG_COLOR_KIND.SKILL,
    }),
    [],
  )

  const handleItemClick = React.useCallback(
    (e: ITagMouseEvent) => {
      if (isFeatureEnabled('regraph')) {
        if (e.entity.community.id !== community?.id) {
          handleSpawnCommunities([e.entity.community as IGraphCommunityNode])
        }
        handleSpawnSkills([e.entity.skill as IGraphSkillNode])
        focusNodes([e.entity?.skill?.id])
      } else {
        EventBus.trigger(EventBus.actions.search.community, {
          id: e.entity?.skill?.id,
          type: tagKind,
          label: e.entity?.skill?.name,
          value: e.entity?.skill?.name,
          communities: [e.entity?.community],
        })
        if (e.entity.community.id !== community?.id) {
          EventBus.trigger(EventBus.actions.graph.addCommunityEdge, {
            fromId: e.entity.community.id,
            toId: e.entity?.skill?.id,
            tagKind,
          })
          EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
            fromId: communityUser.communityUserId,
            toId: e.entity?.skill?.id,
            tagKind,
          })
        }
        EventBus.trigger(EventBus.actions.graph.focusNode, [
          e.entity.community?.id,
        ])
        EventBus.trigger(EventBus.actions.graph.focusNode, e.entity?.skill?.id)
      }
    },
    [
      community,
      communityUser,
      focusNodes,
      handleSpawnCommunities,
      handleSpawnSkills,
      isFeatureEnabled,
      tagKind,
    ],
  )

  const handleAddClick = React.useCallback(async () => {
    setIsAdding(true)
  }, [])

  const [connectUsersToSkills] = useMutation(connectUsersToSkillsMutation)
  const [createSkills] = useMutation(createSkillsMutation)

  const loadSkillOptions = React.useCallback(
    async (
      inputValue: string,
      callback: (options: ISkillOptionInput[]) => void,
    ) => {
      try {
        const result = await client.query({
          query: listSkillsQuery,
          variables: {
            communityIds: [community?.id],
            search: inputValue,
            limit: 25,
          },
        })

        const options = result?.data?.listSkills?.rows.map(
          (skill: ISkillOption) => ({
            ...skill,
            kind: SkillKind.Skill,
          }),
        )

        callback(tagsToOptions(options))
      } catch (error: any) {
        toast.error({
          title: s('errorTitle'),
          text: error.message,
        })
      }
    },
    [client, community?.id, s],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = React.useCallback(
    setMinSearchLength(
      debounce(loadSkillOptions, DEFAULT_SEARCH_DEBOUNCE),
      DEFAULT_MIN_SEARCH_SIZE,
    ),
    [loadSkillOptions],
  )

  const handleSave = React.useCallback(async () => {
    setLoading(true)
    setIsAdding(false)

    let createdSkills: MainSchema.Skill[] = []

    try {
      // If we need to create skills, do so
      if (skillsToCreate.length > 0) {
        const createSkillsResponse = await createSkills({
          variables: {
            communityId: community?.id,
            skills: skillsToCreate,
          },
        })
        createdSkills = createSkillsResponse?.data?.createSkills || []
      }

      // Combine the existing skills with the newly created skills and connect them to the user
      const combinedSkills = [...existingSkills, ...createdSkills]

      // Map to our graphql input type
      const usersToSkills = combinedSkills.map(skill => ({
        skillId: skill.id!,
        communityUserId,
      })) as MainSchema.ConnectUsersToSkillsInputType[]

      // Prepare the community user data for the updater
      const communityUsers = [
        {
          communityUserId: communityUser?.communityUserId!,
          communityId: community?.id!,
        },
      ]

      const communityIds = me?.communities?.map(e => e.id) || []

      // Connect the skills to the user, and update the cache
      await connectUsersToSkills({
        variables: {
          communityId: community?.id,
          usersToSkills,
        },
        update: getCommunityUserSkillsUpdater({
          communityUsers,
          communityIds,
          skills: combinedSkills,
        }),
      })

      // Add the skills to the graph and create the edges
      combinedSkills.forEach(skill => {
        // Add skill to graph
        EventBus.trigger(EventBus.actions.graph.addSkillTags, {
          id: skill.id,
          name: skill.name,
          communityUserId: communityUser?.communityUserId,
          kind: SkillKind.Skill,
        })

        // Connect skill to user on graph
        EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
          fromId: communityUser?.communityUserId,
          toId: skill.id,
        })
      })

      toast.success({
        title: s('createTitle'),
        text: s('createSuccess'),
      })
    } catch (error: any) {
      toast.error({
        title: s('errorTitle'),
        text: error?.message,
      })
    } finally {
      setExistingSkills([])
      setSkillsToCreate([])
      setLoading(false)
    }
  }, [
    skillsToCreate,
    existingSkills,
    communityUser?.communityUserId,
    community?.id,
    connectUsersToSkills,
    me?.communities,
    s,
    createSkills,
    communityUserId,
  ])

  const renderMultiValue = React.useCallback(
    (selectProps: any) => (
      <Tag
        colorKind={SkillKind.Skill}
        removable
        small
        text={selectProps?.children?.props?.text ?? selectProps?.children}
        onRemove={() => selectProps?.removeProps?.onClick()}
      />
    ),
    [],
  )

  const handleIsValidNewOption = React.useCallback((inputValue: string) => {
    return inputValue?.length >= 3
  }, [])

  const handleOnChange = React.useCallback(
    (newValue: MultiValue<ISkillOptionInput>) => {
      const selectedSkills = newValue.map(skill => ({ ...skill }))
      // Filter newly added skills that need to be created
      const skillsToCreate = selectedSkills
        .filter(options => !options.id)
        .map(option => ({
          name: option?.value?.trim(),
        })) as MainSchema.CreateSkillInput[]
      setSkillsToCreate(skillsToCreate)

      // Filter the existing skills in the NOS system that just need to be appended to the user
      const existingSkills = selectedSkills
        .filter(options => options.id)
        .map(option => ({
          id: option?.id,
          name: option?.name,
        })) as MainSchema.Skill[]
      setExistingSkills(existingSkills)
    },
    [],
  )

  const handleCancel = React.useCallback(async () => {
    setIsAdding(false)
  }, [])

  return (
    <Card>
      <Row>
        <Column>
          <SectionTitle
            icon={icon}
            showPlusButton={!isAdding}
            title={s('name')}
            onPlusClicked={handleAddClick}
          />
        </Column>
        <Column fullWidth pl={5}>
          {isAdding && (
            <Row fullWidth>
              <InlineDropdownCreate
                debouncedLoadOptions={debouncedLoadOptions}
                isCreatable
                isValidNewOption={handleIsValidNewOption}
                placeholder={s('placeholder')}
                renderMultiValue={renderMultiValue}
                onCancel={handleCancel}
                onChange={handleOnChange}
                onSave={handleSave}
              />
            </Row>
          )}
          {isLoading && (
            <Row>
              <Loader />
            </Row>
          )}
          {communityUserSkills.length > 0 && (
            <Row wrapped>
              <ShowMore flexDirection={'row'} gap={2} initialShown={6} wrapped>
                {communityUserSkills.map(communityUserSkill => (
                  <Tooltip
                    content={s('tooltip', {
                      name: communityUserSkill.skill?.name,
                      communityName: communityUserSkill.community?.name,
                    })}
                    key={communityUserSkill?.skillId}
                    offset={[0, 16]}
                    placement="left"
                  >
                    <div>
                      <Tag
                        colorKind={TAG_COLOR_KIND.TRANSPARENT}
                        entity={communityUserSkill}
                        key={communityUserSkill?.skillId}
                        removable={!!onRemove && !!communityUserId}
                        small
                        text={communityUserSkill?.skill?.name || 'N/A'}
                        onClick={handleItemClick}
                        onRemove={() =>
                          communityUserId &&
                          onRemove &&
                          communityUserSkill.skillId &&
                          communityUserSkill.communityUserId &&
                          onRemove(
                            communityUserSkill.skillId,
                            communityUserSkill.communityUserId,
                          )
                        }
                      />
                    </div>
                  </Tooltip>
                ))}
              </ShowMore>
            </Row>
          )}

          {children}
        </Column>
      </Row>
    </Card>
  )
}

export default Skills
