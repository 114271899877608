import React, { useCallback, useMemo } from 'react'

import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { IMentionedItem } from 'Features/Notes/utils'
import Utils from 'Utils'

import isEmpty from 'lodash/isEmpty'

import { Column, Divider, Row, Tooltip } from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'

import { HandshakeIcon } from './CommunityUserTags.styles'
import TagList from './TagList'

export interface IUserTagsProps {
  appendCommunityUsers: IMentionedItem[]
  connectedCommunityUsers: IGraphPersonNode[]
  meetCommunityUserIds: string[]
  meetCommunityUsers: IMentionedItem[]
  mentionedCommunityUserIds: string[]
  targetCommunityUser?: MainSchema.CommunityUser | IGraphPersonNode
}

function CommunityUserTags({
  targetCommunityUser,
  connectedCommunityUsers = [],
  appendCommunityUsers = [],
  mentionedCommunityUserIds = [],
  meetCommunityUserIds = [],
  meetCommunityUsers = [],
}: IUserTagsProps) {
  const t = useScopedI18n(
    'features.notes.components.noteTags.communityUserTags',
  )

  const connectedCommunityUsersWithMentions = useMemo(() => {
    const initialArray = [...connectedCommunityUsers]
    initialArray.forEach((communityUser, index) => {
      if (
        communityUser.communityUserId &&
        (meetCommunityUserIds.includes(communityUser.communityUserId) ||
          mentionedCommunityUserIds.includes(communityUser.communityUserId))
      ) {
        initialArray.splice(index, 1)
        initialArray.unshift(communityUser)
      }
    })
    return initialArray
  }, [connectedCommunityUsers, meetCommunityUserIds, mentionedCommunityUserIds])

  const canHandshake = useCallback(
    (communityUser: IMentionedItem) =>
      targetCommunityUser?.communityUserId !== communityUser?.id &&
      communityUser?.email,
    [targetCommunityUser?.communityUserId],
  )

  const handleHandshakeClick = useCallback(
    (communityUser: IMentionedItem) => {
      if (!canHandshake(communityUser)) {
        return
      }

      EventBus.trigger(EventBus.actions.dashboard.introduceTo, [
        targetCommunityUser,
        communityUser,
      ])
    },
    [canHandshake, targetCommunityUser],
  )

  const handleClick = (item: IMentionedItem | IGraphPersonNode) => {
    EventBus.trigger(EventBus.actions.graph.addCommunityUserById, {
      communityUserId:
        (item as IMentionedItem)?.id ||
        (item as IGraphPersonNode)?.communityUserId,
      shouldFocus: true,
    })
  }

  if (
    isEmpty(connectedCommunityUsers) &&
    isEmpty(appendCommunityUsers) &&
    isEmpty(meetCommunityUsers)
  )
    return null

  return (
    <Column>
      {!isEmpty(connectedCommunityUsers) && (
        <>
          <TagList
            items={connectedCommunityUsersWithMentions.map(communityUser => ({
              id: communityUser.communityUserId!,
              name: Utils.User.getFullName(communityUser),
              colorKind: TAG_COLOR_KIND.USER,
              onClick: () => handleClick(communityUser),
            }))}
            label={t('alreadyConnectedCommunityUsers')}
          />
        </>
      )}

      {!isEmpty(appendCommunityUsers) && (
        <>
          <Divider mb={3} />

          <TagList
            items={appendCommunityUsers.map(communityUser => ({
              id: communityUser.id,
              name: Utils.User.getFullName(communityUser),
              colorKind: TAG_COLOR_KIND.USER,
              onClick: () => handleClick(communityUser),
            }))}
            label={t('mentionedCommunityUsers')}
          />
        </>
      )}

      {!isEmpty(meetCommunityUsers) && (
        <>
          <Divider mb={3} />

          <TagList
            items={meetCommunityUsers.map(communityUser => ({
              id: communityUser.id,
              name: Utils.User.getFullName(communityUser),
              colorKind: TAG_COLOR_KIND.USER,
              onClick: () => handleClick(communityUser),
              renderAfter: canHandshake(communityUser) ? (
                <Tooltip
                  content={`${_('tips.introduceTo')} ${Utils.User.getFullName(
                    communityUser,
                  )}`}
                >
                  <Row onClick={() => handleHandshakeClick(communityUser)}>
                    <HandshakeIcon />
                  </Row>
                </Tooltip>
              ) : undefined,
            }))}
            label={t('shouldMeetCommunityUsers')}
          />
        </>
      )}
    </Column>
  )
}

export default CommunityUserTags
