import styled from 'styled-components'

import {
  addToCommunityGlyph,
  deleteGlyph,
  emptyGlyph,
  filterGlyph,
  removeFromCommunityGlyph,
  sortGlyph,
} from 'Assets/Svg/icons'

import { resetButton } from 'Components/Styles'

export const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative;
`
export const EmptyWrapper = styled.div`
  width: 236px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  height: 100vh;
`
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  padding: 20px;
  margin-bottom: 30px;
`
export const TableHeader = styled.th`
  font-weight: 500;
  text-align: left;
  padding: 6px;
  white-space: nowrap;
`
export const TableHeaderRow = styled.tr`
  background-color: #cccccc;
`
export const TableRow = styled.tr`
  cursor: pointer;

  &:hover {
    background-color: #f3f4f5;
  }
  &:not(:first-child) {
    border-top: 1px solid #cccccc;
  }
`
export const TableData = styled.td`
  padding: 12px 6px;
`
export const Sort = styled(sortGlyph)`
  cursor: pointer;
  width: 12px;
  margin-left: 4px;
`
export const Input = styled.input`
  max-width: 210px;
  padding: 5px;
  width: 100%;
`
export const Empty = styled(emptyGlyph)`
  width: 100px;
`
export const Heading = styled.h2`
  font-weight: 400;
`
export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  min-height: 44px;
`
export const FilterIcon = styled(filterGlyph)`
  cursor: pointer;
  width: 10px;
  margin-right: 4px;
`
export const FilterWrapper = styled.div`
  position: relative;
`
export const FilterButton = styled.button`
  ${resetButton}

  border-radius: 4px;
  padding: 4px;
  border: 1px solid #dde1e4;
  cursor: pointer;
  font-weight: 500;

  & + & {
    margin-left: 12px;
  }

  &:hover {
    background-color: #2d64bc;
    color: #ffffff;
    path {
      stroke: #ffffff;
    }
  }
`
export const ActionsButton = styled(FilterButton)`
  background-color: #2d64bc;
  color: #ffffff;

  &:hover {
    background-color: #2d64bc;
  }
`
export const FilterDropdown = styled.div`
  margin-top: 10px;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
  z-index: 1;
  width: max-content;
  right: 0;
  top: 28px;
  display: flex;
  flex-direction: column;

  label {
    display: flex;
  }

  input {
    margin-right: 4px;
  }

  > * + * {
    margin-top: 4px;
  }
`
export const SelectedRowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border-radius: 100px;
  position: relative;
  width: 290px;
`
export const ActionButton = styled.button`
  ${resetButton}

  display: flex;
  align-items: center;

  text-align: left;
  cursor: pointer;
  font-size: 10px;

  &:not(:first-child) {
    margin-top: 8px;
  }

  span {
    padding-left: 4px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`
export const Add = styled(addToCommunityGlyph)`
  width: 14px;
  fill: #757575;
`
export const Remove = styled(removeFromCommunityGlyph)`
  width: 14px;
  fill: #757575;
`
export const Delete = styled(deleteGlyph)`
  width: 14px;
  stroke: #757575;
`
export const Kebab = styled.span`
  display: block;
  width: 10px;

  &::after {
    content: '⋮';
  }
`
export const ProfilePanelWrapper = styled.div`
  background-color: #ffffff;
  top: 70px;
  right: 0;
  z-index: 1;
  position: fixed;
`
