import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { logoNetworkGlyph } from 'Assets/Svg'
import { organizationGlyph, personGlyph, searchFilter } from 'Assets/Svg/icons'
import {
  customTagGlyph,
  eventTagGlyph,
  groupTagGlyph,
  projectTagGlyph,
  skillTagGlyph,
} from 'Assets/Svg/icons/tags'

import { Text } from 'Components/UI'

export const SEARCH_INPUT_HEIGHT = 45
export const SEARCH_INPUT_BORDER_WIDTH = 1

export const Container = styled.form`
  position: relative;
  width: 100%;
`

export const Placeholder = styled.div`
  width: 100%;
  height: ${SEARCH_INPUT_HEIGHT + SEARCH_INPUT_BORDER_WIDTH}px;
`

export interface IInputContainer {
  isActive: boolean
}

export const InputContainer = styled.div<IInputContainer>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background-color: #ffffff;
  border: ${SEARCH_INPUT_BORDER_WIDTH}px solid #eeeeee;
  border-radius: 4px;
  border-bottom-left-radius: ${props => (props.isActive ? '0px' : '4px')};
  border-bottom-right-radius: ${props => (props.isActive ? '0px' : '4px')};
  padding: 0 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.06);
`

export const Input = styled.input`
  width: 100%;
  height: ${SEARCH_INPUT_HEIGHT}px;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  appearance: none;
  background: transparent;
  color: #2d2d2d;
  font-weight: 400;
  font-size: 14px;

  ::placeholder {
    color: #757575;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`

export const Action = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;

  &:hover,
  &:focus {
    color: #2d2d2d;
  }
`

export const ActionDivider = styled.div`
  width: 1px;
  height: 26px;
  background-color: #dde1e4;
`

export const ResultsContainer = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.06);
  z-index: 999998;
`

export const Results = styled.div`
  padding: 6px 0;
  max-height: 250px;
  word-break: break-all;
  overflow-y: auto;
`

export const ResultStatus = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2d2d2d;
  padding: 6px 12px;
`

export const LogoIcon = styled(logoNetworkGlyph)`
  width: 12px;
  height: 12px;
`

export const Dropdown = styled.div`
  position: relative;
  margin-right: 6px;

  &:hover,
  &:focus,
  &:active,
  &[aria-expanded='true'] {
    > button > svg {
      color: ${themeGet('colors.main')};
    }
  }
`

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: -4px;
  margin-top: 12px;
  padding: 12px 16px 16px;
  background-color: ${themeGet('colors.bg.primary')};
  width: auto;
  border-radius: 2px;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 999999;
`

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 300px;
  overflow-y: auto;
  padding: 12px 0;
  margin: 0;
`

export const CommunityCount = styled(Text)`
  background-color: ${themeGet('colors.text.placeholder')};
  border-radius: 20px;
  padding: 0 6px;
`

export const IconCommunityCount = styled(Text)`
  position: absolute;
  right: -12px;
  top: -6px;
  background-color: ${themeGet('colors.text.placeholder')};
  border-radius: 20px;
  padding: 0 6px;
  color: ${themeGet('colors.border.primary')};
  border: 1px solid ${themeGet('colors.border.primary')};
`

export const IconCustomTag = styled(customTagGlyph)``
export const IconEventTag = styled(eventTagGlyph)``
export const IconGroupTag = styled(groupTagGlyph)``
export const IconProjectTag = styled(projectTagGlyph)``
export const IconSkillTag = styled(skillTagGlyph)``
export const IconOrganization = styled(organizationGlyph)``
export const IconPerson = styled(personGlyph)``
export const IconSearchFilter = styled(searchFilter)``
