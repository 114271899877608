import React from 'react'

import { Interweave } from 'interweave'

import isString from 'lodash/isString'

import Popover, { IPopover } from 'Components/UI/Popover'

import { ContentWrapper } from './styles'

const DEFAULT_DELAY: [number, number] = [500, 0]

export interface ITooltip extends Omit<IPopover, 'content' | 'delay'> {
  content?: React.ReactNode
}

function Tooltip({ children, content, ...rest }: ITooltip) {
  return (
    <Popover
      content={
        content ? (
          <ContentWrapper>
            {isString(content) ? <Interweave content={content} /> : content}
          </ContentWrapper>
        ) : null
      }
      delay={DEFAULT_DELAY}
      withArrow={content !== undefined && content !== null}
      {...rest}
    >
      {children}
    </Popover>
  )
}

export default Tooltip
